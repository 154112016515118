import React, { useState } from 'react';
import './index.scss';
import PrimaryButton, { SecondryButton } from '../CustomButtons';

interface CreatePollProps {
    pollTitle?: string;
    totalOptions?: number;
    pollOptions?: any;
    setPollOptions: React.Dispatch<React.SetStateAction<any>>
    createPollOptions?: any;
    handleClose: () => void
    setPollTitle: React.Dispatch<React.SetStateAction<any>>
}

const CreatePoll: React.FC<CreatePollProps> = ({ pollTitle, totalOptions: total = 2, pollOptions = [],setPollOptions,handleClose,setPollTitle }) => {

    const [title, setTitle] = useState<any>(pollTitle);
    const [options, setOptions] = useState(pollOptions);
    const [totalOptions, setTotalOptions] = useState(total);

    const removeOption = (index: number) => {
        const updatedOptions = options?.filter((_:any, i:number) => i !== index);
        setOptions(updatedOptions);
        setTotalOptions(totalOptions - 1);
      };

      const onDone=()=>{
        setPollOptions(options)
        setPollTitle(title)
        handleClose()
      }

    return (
        <div className='create_poll_wrapper'>
            <div className='create_poll_title'>Question</div>
            <textarea autoFocus value={title} onChange={(e)=>setTitle(e.target.value)} placeholder='Ask a question' className='poll-text-area' />
            <div className='create_poll_title'>Options</div>
            {Array.from({ length: totalOptions }).map((_, index) => {
                return (
                    <div className='input_container'>
                        <input
                            value={options?.[index]?.value}
                            type="text"
                            onChange={(e) => {
                                const updatedOptions = [...options];
                                updatedOptions[index] = { value: e.target.value };
                                setOptions(updatedOptions);
                            }}
                            maxLength={30}
                            placeholder={`Option ${index + 1}`}
                            style={{ width: '100%', paddingRight: '30px' }}
                        />
                        {index>1 &&
                        <button onClick={() => removeOption(index)} className='close-button'>
                            <i className="fi fi-sr-circle-xmark"></i>
                        </button>}
                    </div>
                );
            })}
            <div className='modal_footer'>
                {totalOptions < 5 &&
                    <SecondryButton
                        fullWidth={false}
                        onClick={() => setTotalOptions(totalOptions + 1)}
                        text='+ Add options' />}
                <div style={{ width: totalOptions == 5 ? '100%' : '50%' }} className='button_wrapper'>
                    <PrimaryButton
                        fullWidth={false}
                        disabled={
                            !title?.trim() ||
                            options?.length < totalOptions ||
                            !options?.every((option: any) => option?.value?.trim()?.length)
                          }
                        onClick={onDone}
                    >Done</PrimaryButton>
                </div>
            </div>
        </div>
    );
};

export default CreatePoll;