import {createSlice} from '@reduxjs/toolkit';
import { loadUserFromLocalStorage } from 'utils/commonUtils';


const user = loadUserFromLocalStorage();

const userSlice = createSlice({
  name: 'userDetails',
  initialState: {
    user:null,
    loginForm:{},
  },
  reducers: {
    setUserDetails: (state, action) => {
      state.user = action?.payload;
      localStorage.setItem('sessiontoken', action?.payload?.accessToken);
      localStorage.setItem('user',JSON.stringify(action?.payload));
      localStorage.setItem('refreshToken',action?.payload?.refreshToken);
    },
    clearUserDetails: (state) => {
      state.user = null;
      localStorage.clear()
    },
    setLoginForm: (state, action) => {
      state.loginForm = action?.payload;
    },
    updateUserDetails: (state, action) => {
      const refreshToken = localStorage.getItem('refreshToken');
      const sessionToken = localStorage.getItem('sessiontoken');
      
      let temp_user = {
        ...user,
        ...action?.payload,
        accessToken: sessionToken ? sessionToken : null,
        refreshToken: refreshToken ? refreshToken : null,
      };
      temp_user.classRooms=action?.payload?.classRooms
      if (temp_user) {
        localStorage.setItem('user', JSON.stringify(temp_user));
      }
    },
    updateUserClassRoom: (state, action) => {
      let temp_user = {
        ...(state.user ?? {}), 
      // @ts-ignore
        classRooms: action.payload
      };
      localStorage.setItem('user',JSON.stringify(temp_user));
    },
  },
});

export const {
  setUserDetails,
  clearUserDetails,
  setLoginForm,
  updateUserDetails,
  updateUserClassRoom,
} = userSlice.actions;
export default userSlice.reducer;
