// Core
import React, { useEffect, useRef, useState } from 'react';

// Third-Party Libraries
import _ from 'lodash';

// Components
import GridContainer from 'components/molecules/GridContainer';
import ClassmatesListWrapper from 'components/atoms/ClassmatesListWrapper';

// Styles and Constants
import './index.scss';

// Utilities
import {
    ClassroomDataKeys,
    ClassroomSettingsConstData,
} from 'utils/constants';
import { fetchData } from 'utils/fetch';
import { loadActiveClassroomLocalStorage } from 'utils/commonUtils';

// Assets
import class_logo from '../../assets/images/class_logo.png'
import class_cover from '../../assets/images/class_cover.png'



const ClassRoomSettings: React.FC = () => {

    const activeClassroom = loadActiveClassroomLocalStorage()
    const [isExpanded, setIsExpanded] = useState(false);
    const [showReadMore, setShowReadMore] = useState(false);
    const textRef = useRef<HTMLDivElement | null>(null);

    const toggleReadMore = () => setIsExpanded(!isExpanded);

    const data = [
        {
            key: ClassroomDataKeys.Name,
            value: activeClassroom?.name
        },
        {
            key: ClassroomDataKeys.About,
            value: activeClassroom?.about, placeholder: ClassroomDataKeys.Placeholder
        }
    ];


    useEffect(() => {
        if (textRef.current) {
            const element = textRef.current;
            const lineHeight = parseFloat(window.getComputedStyle(element).lineHeight);
            const lines = element.scrollHeight / lineHeight;
            if (lines > 3) {
                setShowReadMore(true);
            }
            else {
                setShowReadMore(false);
            }
        }
    }, [activeClassroom?.about]);

    return (
        <GridContainer
            pageTitle={'Classroom Settings'}
            backButton
            leftGridPartition={3 / 4}
        >
            <div className='classroom_settings_container'>
                <div style={{
                    backgroundImage: `linear-gradient(to bottom,rgba(0,0,0,0),rgba(0,0,0,0.0) ,rgba(0,0,0,0.2),rgba(0,0,0,.4),rgba(0,0,0,.7)),
                        url(${activeClassroom?.cover || class_cover})`,
                }}
                    className="class-room-detail-cover detail-cover">
                    <img
                        src={activeClassroom?.logo?.length > 0 ? activeClassroom?.logo : class_logo}
                        alt="logo"
                        className='class_logo' />
                    <div className='detail-cover-wrapper'>
                        <span className='detail-cover-text'>{activeClassroom?.name}</span>
                    </div>
                </div>
                <div className='_classroom_settings'>
                    {data?.map((item, index) => (
                        <div style={{ borderBottom: index === data.length - 1 ? 'none' : '' }} key={index} className='wrapper'>
                            <div>
                                <div className='key'>{item?.key}</div>
                                {index === 2 ? <div>
                                    <div
                                        className={`value ${isExpanded ? 'expanded' : 'collapsed'}`}
                                        ref={textRef}
                                        style={{ whiteSpace: 'pre-wrap' }}
                                    >
                                        {(index !== 2 || item?.value?.length > 0) ? item?.value : ClassroomSettingsConstData.NoDescription}
                                    </div>
                                    {showReadMore && (
                                        <span className="read-more" onClick={toggleReadMore}>
                                            {isExpanded ? ClassroomSettingsConstData.ReadLess : ClassroomSettingsConstData.ReadMore}
                                        </span>
                                    )}
                                </div> :
                                    <div className='value'>
                                        {(index !== 2 || item?.value?.length > 0) ? item?.value : ClassroomSettingsConstData.NoDescription}
                                    </div>}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className='_classroom_members'>
                <ClassmatesListWrapper fromSettings />
            </div>
        </GridContainer>
    );
};

export default ClassRoomSettings;
