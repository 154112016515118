// Core
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Third-Party Libraries
import { Avatar, CircularProgress } from '@mui/material';

// Components
import CommonDeleteModal from 'components/atoms/CommonDeleteModal';
import JoinRequestsApproval from 'components/molecules/JoinRequestsApproval';
import ClassRoomDetailModal from 'components/atoms/ClassRoomDetailModal';

// Styles and Constants
import './index.scss';
import { ActivityList, SingleActivity } from '../RecentActivities/styles';
import { ProfileCard } from 'assets/styles/commonStyle';

// Utilities
import { restAPIs } from 'utils/restAPIs';
import { getTime, loadActiveClassroomLocalStorage } from 'utils/commonUtils';
import { MODAL_STYTLE, NOTIFICATION_MODULES, UserObjectKeys } from 'utils/constants';
import { useCustomSWR, useSwichClass } from 'utils/customHooks';
import { fetchData } from 'utils/fetch';

const AllNotifications: React.FC = () => {
    const activeClassroom = loadActiveClassroomLocalStorage()
    const [activities, setActivities] = useState<any[]>([]);
    const navigate = useNavigate();
    const swichClass = useSwichClass()
    const [page, setPage] = useState(1);
    const [isLastPage, setIsLastPage] = useState(false);
    const [showLeave, setShowLeave] = useState<any>(false)
    const [selectedItem, setItem] = useState<any>(null);
    const scrollRef = useRef<HTMLDivElement>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true)

    const loadMore = async (initialPage?: boolean) => {
        if (!isLastPage || initialPage) {
            let params = {
                page: page,
                limit: 10
            };
            setIsLoading(true)
            const res = await fetchData(restAPIs.getNotificationList(params), navigate)
            setIsLoading(false)
            if (res?.statusCode === 200 && res?.data) {
                const temp = res.data?.notifications??[]
                setActivities(initialPage ? temp : activities.concat(temp))
                setIsLastPage(res.data.isLastPage)
                setPage(initialPage ? 2 : page + 1)
            }
        }
    }

    useEffect(() => {
        loadMore(true);
    }, []);


    useEffect(() => {
        const handleScroll = () => {

            if (scrollRef.current) {
                const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
                if (scrollTop + clientHeight >= scrollHeight - 5 && !isLoading && !isLastPage) {
                    loadMore();
                }
            }
        };

        const currentScrollRef = scrollRef.current;
        if (currentScrollRef) {
            currentScrollRef.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (currentScrollRef) {
                currentScrollRef.removeEventListener('scroll', handleScroll);
            }
        };
    }, [isLoading, isLastPage]);

    const onSwitchClass = () => {
        swichClass(showLeave?.id, true)
        setShowLeave(false)
    }

    const onClick = (type: string, id: string, item: any) => {
        let refId = item && item.refId;        

        switch (type) {
            case NOTIFICATION_MODULES.document:
                navigate(`/documents/ref/${refId}`);
                break;

            case NOTIFICATION_MODULES.post:
                navigate(`/feed/ref/${refId}`);
                break;

            case NOTIFICATION_MODULES.note:
                navigate(`/notes/ref/${refId}`);
                break;

            case NOTIFICATION_MODULES.event:
                if (id === activeClassroom?._id) {
                    navigate('/events', { state: { item } });
                } else {
                    setShowLeave({ type, id });
                }
                break;

            case NOTIFICATION_MODULES.curriculum:
                if (id === activeClassroom?._id) {
                    navigate('/curriculum');
                } else {
                    setShowLeave({ type, id });
                }
                break;

            case NOTIFICATION_MODULES.timetable:
                if (id === activeClassroom?._id) {
                    navigate('/timetable');
                } else {
                    setShowLeave({ type, id });
                }
                break;

            case NOTIFICATION_MODULES.subject:
                if (id === activeClassroom?._id) {
                    navigate('/subjects');
                } else {
                    setShowLeave({ type, id });
                }
                break;
            case NOTIFICATION_MODULES.user:
                navigate(`/user-profile/${item?.refId}`)
                break;

            case NOTIFICATION_MODULES.userAdmin:
                if (id !== activeClassroom?._id) {
                    setItem({
                        ...item, id: item.classRoomId,
                        membershipStatus: UserObjectKeys.Active,
                    })
                } 
                break;
            case NOTIFICATION_MODULES.userApproval:
                setItem({
                    ...item, id: item.classRoomId,
                    membershipStatus: UserObjectKeys.Active,
                });
                break;
            default:
                break;
        }
    };

    const handleDetailClose = () => {
        setItem(null)
    };

    return (
        <div className='home text-left __recent' ref={scrollRef}>
            {/* <JoinRequestsApproval /> */}
            <ActivityList>
                {activities?.map((item: any, index: any) => {
                    return (
                        <div className='__list' key={index} onClick={() => onClick(item.module, item.classRoomId, item)}>
                            <SingleActivity key={index}>
                                <ProfileCard style={{ alignItems: 'center' }}>
                                    <Avatar
                                        sx={{ bgcolor: '#222', width: 34, height: 34, fontSize: '14px' }}
                                        aria-label="recipe"
                                        src={item?.triggeredByDetails?.profilePic}>
                                        {item?.triggeredByDetails?.firstName?.slice(0, 1)}{item?.triggeredByDetails?.lastName?.slice(0, 1)}
                                    </Avatar>
                                    <div className='___label'>
                                        {item?.module === 'POST' ?
                                            <h1 style={{ fontSize: '13px', }}>{item?.title}</h1> :
                                            <h1 style={{ fontSize: '13px' }}>{item?.triggeredByDetails?.firstName} {item?.triggeredByDetails?.lastName}
                                            </h1>}
                                        {item?.module === 'POST' && <p>{getTime(item?.createdAt)}</p>}
                                    </div>
                                </ProfileCard>
                                <h3 className='__title'>{item?.title}</h3>
                                <span className='__title'>{item?.module !== 'POST' && item?.description}</span>
                                <p>{item?.module !== 'POST' && <p>{getTime(item?.createdAt)}</p>}</p>
                            </SingleActivity>
                        </div>
                    );
                })}
                <div className='seprator'/>
                {isLoading && <div style={{ textAlign: 'center' }}>
                    <CircularProgress size={'20px'} color="primary" />
                </div>}
                {!isLoading && !activities?.length && <div className='__no_notification'>No Notifications</div>}
            </ActivityList>
            <CommonDeleteModal
                open={showLeave?.id}
                handleSubmit={() => onSwitchClass()}
                buttonLabel={'Switch Classroom'}
                description={`You need to switch to this classroom to see the notifications`}
                title={'Switch Classroom'}
                modalStyle={MODAL_STYTLE}
                handleClose={() => setShowLeave(false)}
            />
            <ClassRoomDetailModal item={selectedItem} id={selectedItem?.id} onClose={handleDetailClose} />
        </div>
    );
};

export default AllNotifications;
