// Core
import React, { useState } from "react";
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";

// Third-Party Libraries
import { TextField, Typography } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Alert from '@mui/material/Alert';

// Utilities
import { fetchData } from "utils/fetch";
import { restAPIs } from "utils/restAPIs";
import { 
  LoginProps, 
  ResetPasswordFormErrorState, 
  ResetPasswordFormState, 
  ResetPasswordInitialError, 
  ResetPasswordInitialForm 
} from "utils/types";
import { 
  LoginData, 
  SnackBarMessages, 
  LoginFormKeys, 
  StatusType, 
  InputLabels, 
  InputNames, 
  InputVariants, 
  InputMargins, 
  InputTypes 
} from "utils/constants";

// Components
import PrimaryButton, { SecondryButton } from "components/atoms/CustomButtons";
import { useSnackbar } from "components/atoms/SnackbarProvider";

//Assets
import logo from '../../../assets/images/classteamlogo.png'

// Styles and Constants
import { colors } from "assets/styles/colors";
import '../index.scss';

const ResetPassword: React.FC<LoginProps> = ({ setType }) => {

  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [formData, setFormData] = useState<ResetPasswordFormState>(ResetPasswordInitialForm);
  const [errors, setErrors] = useState<ResetPasswordFormErrorState>(ResetPasswordInitialError);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setConfirmShowPassword] = useState<boolean>(false);
  const { loginForm } = useSelector((state: any) => state.user) || {};
  const { token = '' } = loginForm || {}

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
    setErrors(ResetPasswordInitialError)
  };

  function validate(text: string) {
    let isValid = false;
    let pass =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{6,}$/;
    if (pass.test(text)) {
      isValid = true;
    } else {
      isValid = false;
    }
    return isValid;
  }

  const validateForm = (): boolean => {
    let newErrors = { ...ResetPasswordInitialError };

    if (!validate(formData.password)) {
      newErrors.password = LoginData.PasswordErrorValied;
    }
    if (formData.confirmPassword !== formData.password) {
      newErrors.confirmPassword = LoginData.PasswordsDoNotMatch;
    }
    setErrors(newErrors);
    return Object.values(newErrors).every((error) => error === '');
  };

  const handleSignup = async () => {
    let params = {
      token: token,
      password: formData.password?.trim(),
    };
    setIsLoading(true)
    const res = await fetchData(restAPIs.getResetPasswordOtp(params), navigate);
    setIsLoading(false)
    const response = res.data
    if (res.statusCode === 200) {
      showSnackbar(SnackBarMessages.PasswordChangedSuccessfully, StatusType.success)
      setType(LoginFormKeys.SignInForm)
    }
    else {
      setErrors({ message: response?.message, ...response?.fields })
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      handleSignup()
    }
  };

  return (
    <div className="signup-form-wrapper">
      {errors?.message && !errors?.password &&
        < Alert className="login-alert" severity="error">{errors?.message}</Alert>}
      <form onSubmit={handleSubmit} style={{ marginBottom: '20px' }} className='login-form'>
        <img src={logo} className='login-classteam-logo' alt="" />
        <Typography variant="h1">{LoginData.ResetPassword}</Typography>
        <Typography fontWeight={400} variant="h6">{LoginData.PleaseEnterNewPassword}</Typography>
        <TextField
          label={InputLabels.NewPassword}
          type={showPassword ? InputTypes.Text : InputTypes.Password}
          name={InputNames.Password}
          fullWidth
          error={!!errors.password}
          helperText={errors.password}
          variant={InputVariants.Outlined}
          onChange={handleChange}
          margin={InputMargins.Normal}
          className="input-field"
          InputLabelProps={{
            style: { fontSize: '13px', fontWeight: 400, color: colors.inputLabelColor },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <div className="hide-eye-icon" onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ?
                    <VisibilityOff sx={{ color: colors.inputLabelColor }} /> :
                    <Visibility sx={{ color: colors.inputLabelColor }} />
                  }
                </div>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          label={InputLabels.ConfirmPassword}
          type={showConfirmPassword ? InputTypes.Text : InputTypes.Password}
          name={InputNames.ConfirmPassword}
          fullWidth
          error={!!errors.confirmPassword}
          helperText={errors.confirmPassword}
          variant={InputVariants.Outlined}
          onChange={handleChange}
          margin={InputMargins.Normal}
          className="input-field"
          InputLabelProps={{
            style: { fontSize: '13px', fontWeight: 400, color: colors.inputLabelColor },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <div className="hide-eye-icon" onClick={() => setConfirmShowPassword(!showConfirmPassword)}>
                  {showConfirmPassword ?
                    <VisibilityOff sx={{ color: colors.inputLabelColor }} /> :
                    <Visibility sx={{ color: colors.inputLabelColor }} />
                  }
                </div>
              </InputAdornment>
            ),
          }}
        />
        <div className="reset_password_button">
          <PrimaryButton
            fullWidth={false}
            text={LoginData.SignUp}
            isLoading={isLoading}>
            {LoginData.Continue}</PrimaryButton>
        </div>
        <SecondryButton
          fullWidth={false}
          onClick={() => setType(LoginFormKeys.SignInForm)}>
          <div className="reset_password_secondry_button">
            {LoginData.BackToLogin}
          </div></SecondryButton>
      </form>
    </div>
  );
}

export default ResetPassword;
