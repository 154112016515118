// Core
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// Third-Party Libraries
import CircularProgress from '@mui/material/CircularProgress';
import { FaCircleCheck } from "react-icons/fa6";
import { FiInfo } from "react-icons/fi";
import { InputAdornment, TextField } from '@mui/material';

// Components
import GridContainer from 'components/molecules/GridContainer';
import PrimaryButton from 'components/atoms/CustomButtons';
import { useSnackbar } from 'components/atoms/SnackbarProvider';

// Styles and Constants
import './index.scss';
import { colors } from 'assets/styles/colors';

// Utilities
import { CLASSID, CREATE_CLASS_ID_FOOTER_TEXT } from 'utils/constants';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';

const ClassId: React.FC = () => {

    const { name } = useParams()

    const navigate = useNavigate();
    const { showSnackbar } = useSnackbar();
    const [error, setError] = useState<string | null>(null);
    const [id, setId] = useState<string>('');
    const [searchLoading, setSearchLoading] = useState(false);
    const [verified, setVerified] = useState(false);

    const validateId = (text: string) => {
        const spaceTest = /\s/g;
        const characterTest = /[~`!@#$%^&*()_+={}\[\]:;"'<>,.?/\\|\\]/g;
        const emojiTest = /[^\u0000-\u1F9FF\u2000-\u2FFF]/g; // This regex matches all characters except emojis
        if (spaceTest.test(text?.trim())) {
            setError("Id can't contain spaces");
            return false;
        } else if (characterTest.test(text)) {
            setError('Id does not support special characters');
            return false;
        } else if (emojiTest.test(text)) {
            setError('Id cannot contain emojis');
            return false;
        } else if (text?.length > CLASSID) {
            setError(`Id too long. Limit to ${CLASSID} characters or less`);
            return false;
        } else {
            setError(null);
            return true;
        }
    };

    const verifyClass = async (text: string) => {
        if (text?.trim()?.length > 0) {
            const isValidId = validateId(text);
            if (isValidId) {
                setSearchLoading(true);
                const response = await fetchData(restAPIs.getVerifyClassRoom({ classRoomId: text?.trim() }),navigate)
                if (response.status === 200) {
                    setVerified(!response.data.taken);
                    if (response.data.taken) {
                        setError('Id already taken');
                    } else setError(null);
                    setSearchLoading(false);
                } else {
                    setVerified(false);
                    setSearchLoading(false);
                }
            } else {
                setSearchLoading(false);
            }
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        if (value?.length <= CLASSID) {
            setId(value);
            setError(null)
        }
        else {
            showSnackbar(`Maximum length allowed is ${CLASSID} characters`, 'error');
        }
    };

    useEffect(() => {
        let searchWaiting = setTimeout(() => verifyClass(id), 1000);
        setId(id);
        const isValidId = validateId(id);
        if (isValidId) {
            setSearchLoading(true);
        } else {
            setSearchLoading(false);
        }
        return () => {
            clearTimeout(searchWaiting);
        };
    }, [id]);

    const onSubmit = () => {
        navigate(`/template/${name?.trim()}/${id?.trim()}`)
    }

    return (
        <GridContainer
            pageTitle={name}
            backButton
            leftGridPartition={3 / 4}>
            <form onSubmit={(e) => onSubmit}>
                <div className='_create_class_name'>
                    <h1 className='title'>Enter your classroom</h1>
                    <h1 className='__sub_title'>ID</h1>
                    <div className="input_container">
                        <TextField
                            label="ID"
                            fullWidth
                            variant="outlined"
                            error={!!error}
                            helperText={error}
                            onChange={handleChange}
                            margin="normal"
                            name="firstName"
                            InputLabelProps={{
                                style: { fontSize: '13px', fontWeight: 400, color: "#B4B4B4" },
                            }}
                            className="input-field"
                            value={id}
                            style={{ marginBottom: '10px' }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {searchLoading && id?.trim()?.length > 0 &&
                                            <CircularProgress size={15} sx={{ color: colors?.primary }} />}
                                        {!searchLoading && verified && <FaCircleCheck color={colors.primary} size={20} />}
                                        {!searchLoading && !verified && <FiInfo size={20} color={colors.secondary} />}
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                    <div className="__footer_text">{CREATE_CLASS_ID_FOOTER_TEXT}</div>
                    <div className="button_container">
                        <PrimaryButton
                            onClick={onSubmit}
                            fullWidth={false}
                            disabled={error ? true : searchLoading ? true : false}
                            isLoading={false}>
                            Next
                        </PrimaryButton>
                    </div>
                </div>
            </form>
        </GridContainer>
    );
};

export default ClassId;
