// Core
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Third-Party Libraries
import {
    SettingsOutlined,
    Logout,
    DeleteOutline,
    CameraAltOutlined
} from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';

// Components
import GridContainer from 'components/molecules/GridContainer';
import ListWrapper from 'components/molecules/ListWrapper';
import CreateUser from '../Login/components/CreateUser';
import ModalView from 'components/atoms/ModalView';
import LogOutModal from 'components/atoms/LogOutModal';
import DeleteModal from 'components/atoms/DeleteModal';

// Utilities
import { formatName, loadUserFromLocalStorage } from '../../utils/commonUtils';
import { useLogout, useUpdateUser } from 'utils/customHooks';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';

// Styles and Constants
import './index.scss';
import { colors } from '../../assets/styles/colors';
import { ACCOUNT_SETTINGS, MODAL_STYTLE } from 'utils/constants';

// Assets
import userSingle from '../../assets/images/userSingle.png';

const settings = ACCOUNT_SETTINGS;

// Icons for settings
const getIcon = (item: string): JSX.Element => {
    switch (item) {
        case 'Change display name':
            return <SettingsOutlined className='Drawer-icon' sx={{ fontSize: 20 }} />;
        case 'Change Password':
            return <Logout className='Drawer-icon' sx={{ fontSize: 20 }} />;
        default:
            return <div />;
    }
};

const Account = () => {
    const logout = useLogout();
    const updateUser = useUpdateUser();
    const userData = loadUserFromLocalStorage();
    const user = userData?.userDetails;
    const navigate = useNavigate();

    const [open, setOpen] = useState<string | boolean>(false);
    const [selectedImage, setSelectedImage] = useState<string | null>(null);
    const [openDeleteAccount, setOpenDeleteAccount] = useState<boolean>(false);
    const [isLoading, setLoading] = useState(false);

    const handleCloseModal = () => setOpen(false);

    const handleSettingClick = (setting: string) => {
        switch (setting) {
            case 'Change display name':
                // navigate('/change-name');
                break;
            case 'Change Password':
                navigate('/change-password');
                break;
            case 'Logout':
                setOpen(setting);
                break;
            default:
                break;
        }
    };

    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                if (reader.result) {
                    setSelectedImage(reader.result as string);
                    onUpdateImage(file);
                }
            };
            reader.readAsDataURL(file);
        }
    };

    const onUpdateImage = async (file: File) => {
        setLoading(true);
        const formData = new FormData();
        formData.append('file', file);

        const response = await fetchData(restAPIs.getUserPicUpload(user?._id, file.name, formData), navigate);
        if (response.status === 200) {
            updateProfilePic(`user/${user?._id}/${file.name}`);
        }
        setLoading(false);
    };

    const updateProfilePic = async (imageUrl: string) => {
        const params = { profilePic: imageUrl };
        const updateResponse = await fetchData(restAPIs.getUserUpdate('put', params), navigate);

        if (updateResponse.status === 200) {
            await updateUser();
            if (user?.profilePic) {
                await fetchData(restAPIs.getS3Delete(user.profilePic), navigate);
            }
        }
    };

    return (
        <GridContainer leftGridPartition={3 / 4} pageTitle="Account Settings" backButton>
            <div style={{ marginTop: '5px' }}>
                <div className="profile-card">
                    {/* <IconButton
                        size="small"
                        className="img-edit-button"
                        color="inherit"
                        onClick={() => document.getElementById('imageInput')?.click()}
                    >
                        <CameraAltOutlined className="camera" />
                    </IconButton> */}

                    {isLoading ? (
                        <div className="profile-pic-container">
                            <CircularProgress size={20} sx={{ color: colors.primary }} />
                        </div>
                    ) : (
                        <img
                            src={user?.profilePic || selectedImage || userSingle}
                            className="user-single"
                            alt="User"
                        />
                    )}

                    <span className="name">{formatName(user?.firstName, user?.lastName)}</span>
                    <span className="phone">
                        {user?.email || `${user?.countryCode || ''}${user?.phone || ''}`}
                    </span>

                    <input
                        id="imageInput"
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                        hidden
                    />
                </div>

                <ListWrapper data={[{ settings }]} getIcon={getIcon} onClick={handleSettingClick} />

                <LogOutModal
                    open={open === 'Logout'}
                    modalStyle={{ padding: '0px' }}
                    handleClose={handleCloseModal}
                    handleOpen={logout}
                />

                {open === 'Change display name' && (
                    <ModalView
                        open={open === 'Change display name'}
                        handleClose={handleCloseModal}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <CreateUser fromProfile closeModal={handleCloseModal} />
                    </ModalView>
                )}

                {/* <div className="__delete-account" onClick={() => setOpenDeleteAccount(true)}>
                    <DeleteOutline />
                    <p className="text">Delete Account</p>
                </div> */}

                <DeleteModal
                    open={openDeleteAccount}
                    modalStyle={MODAL_STYTLE}
                    handleClose={() => setOpenDeleteAccount(false)}
                />
            </div>
        </GridContainer>
    );
};

export default Account;
