import PrimaryButton from 'components/atoms/CustomButtons';
import * as React from 'react';
import { getStatusText } from 'utils/commonUtils';
import { CLASSROOM_STATUS } from 'utils/constants';
import { useSwichClass } from 'utils/customHooks';
import './index.scss'

const ClassSwitchInfoFooter: React.FC<any> = ({ data, status }) => {
    const swichClass = useSwichClass()
    return (
        <div className='switch_footer'>
            <div className="switch_footer_wrapper">
                <div className='name'>{data?.classRoomName}</div>
                <div className='status'>{getStatusText(status)}</div>
            </div>
            <PrimaryButton
                disabled={false}
                onClick={() => swichClass(data?.classRoomId,true)}>
                Go to Classroom
            </PrimaryButton>
        </div>
    );
};

export default ClassSwitchInfoFooter;