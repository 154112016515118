import React from "react"

const quickAccessBreakPoints = {
    0:{
        slidesPerView: 2.2
    },
    431:{
        slidesPerView: 3
    },
    650:{
        slidesPerView:3 
    },
    694:{
        slidesPerView:4
    },
    720:{
        slidesPerView:4
    },
    830:{
        slidesPerView:4
    },
    899: {
        slidesPerView: 5,
    },
    1068: {
        slidesPerView: 5,
    },
    1300:{
        slidesPerView: 6,
    },
    2468: {
        slidesPerView: 6,
    },
}

const todaysClassesBreakPoints = {
    0: {
        slidesPerView: 1.2
    },
    353:{
        slidesPerView: 1.8
    },
    431: {
        slidesPerView: 2
    },
    576: {
        slidesPerView: 2.3,
    },
    790: {
        slidesPerView: 4
    },
    899: {
        slidesPerView: 2.2,
    },
    1158: {
        slidesPerView: 2.5,
    },
    1290: {
        slidesPerView: 3.5,
    },
    2468: {
        slidesPerView: 3.5,
    },
}

export {quickAccessBreakPoints,todaysClassesBreakPoints}