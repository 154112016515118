// Core
import React from 'react';

// Components
import GridContainer from 'components/molecules/GridContainer';
import ClassmatesListWrapper from 'components/atoms/ClassmatesListWrapper';

// Styles and Constants
import './index.scss';

const Classmates: React.FC = () => {

    return (
        <GridContainer
        pageTitle={'Classmates'}
        backButton
        leftGridPartition={3/4}
        >
            <div >
                <div style={{padding: '0px 20px'}}>
                    <ClassmatesListWrapper />
                </div>

            </div>

        </GridContainer>
    );
};

export default Classmates;
