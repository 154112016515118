import React, { useState, useRef, useEffect } from 'react';
import {
    Badge
} from '@mui/material';
import './index.scss'
import { useSnackbar } from '../SnackbarProvider';
import { PhotoPickerProps } from 'utils/types';

const PhotoPicker: React.FC<PhotoPickerProps> = ({ setImageFile, imageFile, files,setPreviewFiles }) => {

    const { showSnackbar } = useSnackbar();
    const [imageSrc, setImageSrc] = useState<string | null>(null);
    const [selected, setSelected] = useState(0);
    const fileInputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (imageFile?.[selected]) {
            if (!imageFile?.[selected].url) {
                const reader = new FileReader();
                reader.readAsDataURL(imageFile?.[selected]);
                reader.onload = () => {
                    setImageSrc(reader.result as string);
                };
            }
        }
        else
            setImageSrc(null)
    }, [imageFile, selected]);

    const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files;
        if (file) {
            const randomString = () => Math.random().toString(36).substring(2, 6)
            let temp = [...imageFile];
            Array.from(file).forEach((f) => {
                if (temp.length + files?.length < 10) {
                    const nameParts = f.name?.split('.');
                    const extension = nameParts.pop();
                    const newName = `${nameParts.join('.')}_${randomString()}.${extension}`;
                    const newFile = new File([f], newName, { type: f.type });
                    temp.push(newFile);
                } else {
                    showSnackbar('Max limit of 10 files exceeded.', 'warning')
                }
            });
            setImageFile(temp);
        }
        event.target.value = ''; 
    };

    return (
        <div style={{ marginTop: '-2px' }}>
            <Badge
                sx={{
                    '& .MuiBadge-badge': {
                        color: '#fff',
                        fontWeight: 400,
                    },
                }}
                onClick={() => fileInputRef.current?.click()}
                badgeContent={imageFile?.length ? imageFile?.length > 9 ? '9+' : imageFile?.length : 0}
                color="primary">
                <i style={{ cursor: 'pointer' }} className="fi fi-rr-picture"></i>
                <input
                    type="file"
                    hidden
                    multiple
                    ref={fileInputRef}
                    accept="image/*"
                    onChange={onFileChange}
                    max={10}
                />
            </Badge>
        </div>
    );
};

export default PhotoPicker;
