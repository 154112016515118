// Core
import { useRef } from 'react';

// Third-Party Libraries
import { QRCode } from 'react-qrcode-logo';
import html2canvas from 'html2canvas';

// Styles and Constants
import './index.scss'

// Assets
import Images from 'assets/image';
import class_logo from '../../assets/images/class_logo.png'
import logo from '../../assets/images/classteamlogo.png'

// Components
import GridContainer from 'components/molecules/GridContainer';

// Utilities
import { CLASS_JOIN } from 'utils/restAPIs';
import { loadActiveClassroomLocalStorage } from 'utils/commonUtils';
import { ClassroomSettingsIcons, QrCodeData } from 'utils/constants';

function QrCode() {

    const activeClassroom = loadActiveClassroomLocalStorage()
    const link = CLASS_JOIN + activeClassroom?._id;
    const qrCodeRef = useRef<HTMLDivElement>(null);

    const handleShare = async () => {
        if (!qrCodeRef.current) return;

        try {
            const canvas = await html2canvas(qrCodeRef.current);
            const blob = await new Promise<Blob>((resolve) => {
                canvas.toBlob((blob) => {
                    if (blob) resolve(blob);
                }, 'image/png');
            });
            if (navigator.share) {
                await navigator.share({
                    files: [new File([blob], 'qr-code.png', { type: 'image/png' })],
                    title: QrCodeData.Classteam,
                    text: QrCodeData.ScanQrCode,
                });
            } else {
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = 'qr-code.png';
                a.click();
                URL.revokeObjectURL(url);
            }
        } catch (error) {
            console.error(QrCodeData.ErrorQRCode, error);
        }
    };

    return (
        <GridContainer
            pageTitle={QrCodeData.QRCode}
            backButton
            leftGridPartition={window?.innerWidth<600?1: 3 / 4}
        >
            <div className='qrcode__wrapper'>
                <div ref={qrCodeRef} className='qrcode'>
                    <img src={class_logo} className='class_logo' />
                    <div className='qrcode__container'>
                        <div className='qrcode__title'>{activeClassroom?.name}</div>
                        <div className='qrcode__id'>#{activeClassroom?.classRoomId}</div>
                        <QRCode value={link} logoImage={Images.BlackLogo} size={250} />
                    </div>
                    <img src={logo} className='classteam_logo' />
                    <div className='qrcode__footer'>
                        {QrCodeData.Class}
                        <div>{QrCodeData.Team}</div>
                    </div>
                </div>
                <div onClick={handleShare} className='share__container'>
                    <i className={ClassroomSettingsIcons.Upload}></i>
                    <div className='share'>{QrCodeData.ShareClassroomCode}</div>
                </div>
            </div>
        </GridContainer>
    );
}

export default QrCode;
