import React from 'react';
import ModalView from '../ModalView';
import { IoIosClose } from "react-icons/io";
import './index.scss'
import PrimaryButton from '../CustomButtons';
import { MODAL_STYTLE } from 'utils/constants';

interface Props {
    handleClose?: () => void;
    open: any;
    modalStyle?: any;
    title: string;
    description: any;
    handleSubmit?: () => void;
    isLoading?: boolean
    buttonLabel?: string
    primaryButton?: boolean
}

const CommonDeleteModal: React.FC<Props> = ({ open, handleClose, title, description, handleSubmit, isLoading, buttonLabel,primaryButton=true }) => {

    const onClose = () => {
        handleClose?.()
    }

    const RenderModal = () => (
        <div className="create-logout-modal">
            <div className='__header'>
                <h4>{title}</h4>
                <button onClick={handleClose}><IoIosClose /></button>
            </div>
            <div className="__logout-content">
                <p>{description}</p>
            </div>
            <div className={`modal-footer-button ${primaryButton ? '__primary' : '___btn'}`}>
                <PrimaryButton
                    fullWidth={false}
                    onClick={() => handleSubmit?handleSubmit():handleClose?.()}
                    isLoading={isLoading}>
                    {buttonLabel ?? 'Yes, Delete it!'}
                </PrimaryButton>
            </div>
        </div>
    )

    return (
        <ModalView open={open} handleClose={onClose} modalStyle={MODAL_STYTLE}>
            {RenderModal()}
        </ModalView>
    )
};

export default CommonDeleteModal;
