import { Badge, IconButton, BadgeProps, Modal, Fade } from '@mui/material';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import { colors } from 'assets/styles/colors';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import Notifications from 'pages/Notifications';
import { useMetadataContext } from 'contextApi/MetadataContext';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import { useNavigate } from 'react-router-dom';
import { loadActiveClassroomLocalStorage } from 'utils/commonUtils';

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: 3,
        top: 3,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
        color: colors.white,
        backgroundColor: colors.primary
    },
}));

const NotificationModal: React.FC = () => {
    const navigate = useNavigate();
    const activeClassroom = loadActiveClassroomLocalStorage()
    const { setNotificationCount,notificationCount } = useMetadataContext();
    const [openModalClass, setOpenModalClass] = React.useState(false);

    const handleCloseClass = () => setOpenModalClass(false);

    const onClick =async()=>{
        setNotificationCount(0)
        setOpenModalClass(true)
        const res = await fetchData(restAPIs.getNotificationRead(), navigate)
    }

    const notificationRender = () => {
        return (
            <Modal
                open={openModalClass}
                onClose={handleCloseClass}
                closeAfterTransition
                BackdropProps={{ invisible: true }}
            >
                <Fade in={openModalClass}>
                    <div
                        className='modal_container_notification'
                    >
                        <div className='__notification_drwr_list'>
                            <Notifications />
                        </div>
                    </div>
                </Fade>
            </Modal>
        )
    }

    if(!activeClassroom){
        return null
    }

    return (
        <div>
        <IconButton onClick={onClick} aria-label="cart">
            <StyledBadge invisible={notificationCount === 0} badgeContent={notificationCount} color="secondary">
                <NotificationsNoneIcon className='icon' />
            </StyledBadge>
        </IconButton>
        {notificationRender()}
        </div>
    )
};

export default NotificationModal;