// Core
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// Third-Party Libraries
import { Typography, TextField } from '@mui/material';
import { FaChevronLeft } from "react-icons/fa6";

// Components
import PrimaryButton from "../../../components/atoms/CustomButtons";

// Utilities
import { fetchData } from "utils/fetch";
import { restAPIs } from "utils/restAPIs";
import { LoginProps } from 'utils/types';
import { InputLabels, InputMargins, InputNames, InputVariants, KeyEventType, LoginData, LoginFormKeys } from 'utils/constants';

// Redux Actions and 
import { setLoginForm } from '../../../redux/slices/setUser/userSlice'

// Styles and Constant
import 'react-phone-input-2/lib/style.css'
import { colors } from 'assets/styles/colors';

const ForgetPassword: React.FC<LoginProps> = ({ setType }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [error, setError] = useState('')
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState<string>('')

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value.trim());
        setError('')
    };

    const validateForm = () => {
        if (email?.trim()?.length == 0) {
            setError(LoginData.EmailRequired);
            return false
        } else if (!/^\S+@\S+\.\S+$/.test(email)) {
            setError(LoginData.EmailNotValid);
            return false
        }
        else return true

    }

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (validateForm()) {
            handleSignup()
        }
    };

    const handleSignup = async () => {
        setIsLoading(true)
        const formData = {
            email: email.toLowerCase(),
        }
        const res = await fetchData(restAPIs.getForgotPassword(formData), navigate);
        setIsLoading(false)
        if (res?.statusCode === 200) {
            setType(LoginFormKeys.OtpScreen)
            dispatch(setLoginForm({ ...formData,token:res?.data?.token, from: LoginData.ForgotPassword }))
        }
        else {
            setError(res?.data?.message)
        }
    }

    const goBack = () => {
        setType(LoginFormKeys.SignInForm)
    }

    const handleKeyDown = (event: any) => {
        if (event.key === KeyEventType.Enter && !isLoading) {
            handleSubmit(event);
        }
    };

    return (
        <div className='phone-number-form-container'>
            <FaChevronLeft onClick={goBack} className='phone-form-back-button' />
            <div className='phone-number-form'>
                <Typography
                    align='left'
                    fontWeight={400}
                    variant="h6">
                    {LoginData.ForgotYour}
                    <Typography variant='h1'>
                        {LoginData.PasswordQn}
                    </Typography>
                </Typography>
                <form onSubmit={(e) => handleSubmit(e)} className='login-form'>
                    <TextField
                        label={InputLabels.Email}
                        fullWidth
                        variant={InputVariants.Outlined}
                        error={!!error}
                        helperText={error}
                        onChange={handleChange}
                        margin={InputMargins.Normal}
                        name={InputNames.Email}
                        className="input-field"
                        InputLabelProps={{
                            style: { fontSize: '13px', fontWeight: 400, color: colors.inputLabelColor },
                        }}
                        onKeyDown={handleKeyDown}
                    />
                    <div className='forget-send-otp'>
                        <PrimaryButton
                            isLoading={isLoading}
                            fullWidth={false} >
                            {LoginData.SendOTP}
                        </PrimaryButton>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ForgetPassword;
