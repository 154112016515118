// Core
import React, { useState } from 'react';
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';

// Styles and Constants
import './index.scss';

// Components
import GridContainer from 'components/molecules/GridContainer';
import { useSnackbar } from 'components/atoms/SnackbarProvider';
import PrimaryButton from 'components/atoms/CustomButtons';

// Third-Party Libraries
import { Typography } from '@mui/material';
import { MdAdd, MdDelete } from 'react-icons/md';

// Utilities
import { fetchData } from 'utils/fetch';
import { ISSUE_PATH, S3UPLOAD, restAPIs } from 'utils/restAPIs';
import { getFileType, loadActiveClassroomLocalStorage, loadUserFromLocalStorage } from 'utils/commonUtils';
import { ReportAProblemFormErrorState, ReportAProblemInitialerrors, SubjectChange } from 'utils/types';
import { ReportAProblemData, SnackBarMessages, StatusType } from 'utils/constants';
import { readFileAsBinary, uploadFile } from 'utils/filesupload';
import CommonDeleteModal from 'components/atoms/CommonDeleteModal';

const ReportAProblem: React.FC = () => {
    const user = loadUserFromLocalStorage();
    const navigate = useNavigate();
    const [isLoding, setLoading] = useState(false);
    const { showSnackbar } = useSnackbar();
    const [errors, setErrors] = useState<ReportAProblemFormErrorState>(ReportAProblemInitialerrors);
    const [description, setDescription] = useState('');
    const [files, setFiles] = useState<any>([]);
    const [percentage, setPercentage] = useState(0);
    const [isVisble, setIsVisible] = useState(false);
    const activeClassroom = loadActiveClassroomLocalStorage()

    const path = ISSUE_PATH.replace(/{orgId}/g, user?.organizationId).replace(
        /{classRoomId}/g,
        activeClassroom?._id,
    );

    const handleSubmit = async () => {
        const uploadedData = [...files]
        let tempFiles = [];
        setLoading(true)
        if (uploadedData?.length > 0) {
            const time = new Date();
            const filesArray = Array.from(uploadedData);
            for (let index = 0; index < filesArray.length; index++) {
                const f = filesArray[index].file ?? filesArray[index];
                setPercentage(index / filesArray.length * 100)
                if (f.url) {
                    const postImageItem = {
                        url: f.url,
                        uniqueFileName: f.uniqueFileName,
                        type: f.type,
                        size: f.size,
                        name: f.name,
                    };
                    tempFiles.push(postImageItem);
                }
                else {
                    
                    const nameParts = f.name?.split('.');
                    const extension = nameParts ? `.${nameParts[nameParts.length - 1]}` : '';
                    const uniqueFilename = `${Math.random().toString(36).substring(7)}${time.getTime().toString()}${extension}`.replace(/[%\s]/g, '');
                    const fileType = f.type;
                    const filePath = `${path}${uniqueFilename}`;
                    const binaryBlob = await readFileAsBinary(f);
                    const downloadUrl = await uploadFile({ filePath, fileType, file: binaryBlob });
                    const postImageItem = {
                        url: downloadUrl,
                        uniqueFileName: uniqueFilename,
                        type: fileType,
                        size: f.size,
                        name: f.name,
                    };
                    tempFiles.push(postImageItem);
                }
            };
        }
        let params = {
            title: description,
            files: tempFiles ?? [],
        };
        const res = await fetchData(restAPIs.reportProblem(params), navigate)
        setLoading(false)
        if (res.statusCode === 200) {
            setIsVisible(true)
            setFiles([])
            setDescription('')
            showSnackbar(res?.message, StatusType.success)
        }
        else {
            showSnackbar(SnackBarMessages.SomethingWentWrong, StatusType.error)
        }
    }

    const handleDescriptionChange = (event: SubjectChange) => {
        setDescription(event.target.value)
    };


    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const newFiles = Array.from(event.target.files);
            const fileItems: any = newFiles.map(file => {
                const fileType = getFileType(file);
                return { file, type: fileType };
            });
            setFiles((prevFiles: any) => [...prevFiles, ...fileItems]);
        }
    };

    const handleDeleteFile = (index: number) => {
        const updatedFiles = [...files];
        updatedFiles.splice(index, 1);
        setFiles(updatedFiles);
    };



    return (
        <GridContainer pageTitle={ReportAProblemData.ReportProblem} backButton leftGridPartition={3 / 4}>
            <div>
                <div className='__report_block'>
                    <div className="file-upload-container">
                        <div className="input-group">
                            <label>{ReportAProblemData.Description}</label>
                            <textarea value={description} onChange={handleDescriptionChange} placeholder={'What went wrong ?'} required />
                            <Typography sx={{ color: 'red' }} variant='subtitle2'>{errors?.description}</Typography>
                        </div>
                        <div>
                            <p style={{ marginBottom: '10px' }}>{ReportAProblemData.Images} {files.length > 0 && <span>({files.length} {files.length===1 ? ReportAProblemData.File : ReportAProblemData.Files})</span>}</p>
                            <div className="file-list-grid">
                                {files.map((fileItem: any, index: any) => {
                                    return (
                                        <div className="file_object" key={index}>
                                            <img src={URL.createObjectURL(fileItem.file)} alt="Preview" />
                                            <span><MdDelete style={{ cursor: 'pointer' }} color={'#fff'} size={15} onClick={() => handleDeleteFile(index)} /></span>
                                        </div>
                                    )
                                }
                                )}

                                <label className="file_object">
                                    <MdAdd size={32} color={'#888'} />
                                    <input type="file" multiple onChange={handleFileChange} accept='image/*' />
                                </label>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>

                            </div>
                        </div>
                        <div className='___footer-button'>
                            <PrimaryButton
                                disabled={description.length === 0}
                                onClick={handleSubmit}
                                fullWidth={false}
                                progress={percentage}
                                isLoading={isLoding}
                            >
                                {ReportAProblemData.Report}
                            </PrimaryButton>
                        </div>
                        <CommonDeleteModal
                            open={isVisble}
                            buttonLabel={'Ok'}
                            description={`Your issue has been successfully reported. We will get back to you as soon as possible.`}
                            title={'Issue Created'}
                            handleClose={() => setIsVisible(false)}
                            handleSubmit={() => setIsVisible(false)}
                        />
                    </div>
                </div>

            </div>

        </GridContainer>
    );
};

export default ReportAProblem;
