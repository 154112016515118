import './index.scss'
import class_cover from '../../../assets/images/class_cover.png'
import class_logo from '../../../assets/images/class_logo.png'
import TabView from '../../molecules/TabView'
import ModalView from '../ModalView';
import { useDispatch } from 'react-redux';
import { getStatus, loadUserFromLocalStorage } from 'utils/commonUtils';
import { setSelectedClassRoom } from '../../../redux/slices/setClassroom/classRoomSlice';
import { useClassMetadata, useJoinRoom, useSwichClass } from 'utils/customHooks';
import { useSnackbar } from '../SnackbarProvider';
import { useEffect, useState } from 'react';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';

interface ChildProps {
    item?: any;
    onClose?: () => void
    id?: any
}

const ClassRoomDetailModal: React.FC<ChildProps> = ({ item, onClose, id }) => {

    const user = loadUserFromLocalStorage();
    const getClassMetadata = useClassMetadata();
    const classRooms = user?.classRooms
    const dispatch = useDispatch();
    const swichClass = useSwichClass()
    const joinRoom = useJoinRoom();
    const { showSnackbar } = useSnackbar();
    const [data, setData] = useState<any>(item);
    const [isLoading, setIsLoading] = useState(false);

    const getClassDetailes = async () => {
        {
            setIsLoading(true);
            const res = await fetchData(restAPIs.getActiveClassDetails(id));
            setIsLoading(false);
            if (res.status == 200 && res.data) {
                setData(res.data);
            }
        }
    }

    useEffect(() => {
        if (id) {
            getClassDetailes();
            getClassMetadata(id)
        }
    }, [id])

    useEffect(() => {
        setData(item)
    }, [item])

    const onSubmitClick = () => {
        swichClass(item?._id, true)
        // if (getStatus(item, classRooms) !== 'Pending') {
        //     dispatch(setSelectedClassRoom(item))
        //     if (getStatus(item, classRooms) === 'Joined') {
        //         swichClass(item?._id, true)
        //     }
        //     else {
        //         joinRoom(item?._id, item?.public)
        //     }
        // }
        // else {
        //     showSnackbar('Waiting for Classroom Admin Approval', 'info')
        // }
    }

    return (
        <ModalView
            open={data !== null}
            modalStyle={{}}
            handleClose={onClose}>
            <div className='classroom-detail-modal'>
                <div style={{
                    backgroundImage: `linear-gradient(to bottom,rgba(0,0,0,0),rgba(0,0,0,0.0) ,rgba(0,0,0,0.2),rgba(0,0,0,.4),rgba(0,0,0,.7)),
                        url(${data?.cover || class_cover})`,
                }}
                    className="class-room-detail-cover">
                    <img src={data?.logos?.small?.length > 0 ? data?.logos?.small : class_logo} className='class_logo' />
                    <div className='detail-cover-wrapper'>
                        <span className='detail-cover-text'>{data?.name}</span>
                    </div>
                </div>
                <div>
                    <TabView onClick={onSubmitClick} item={data} />
                </div>
            </div>
        </ModalView>
    );
};

export default ClassRoomDetailModal;

