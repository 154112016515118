// Core
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Third-Party Libraries
import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import { FaChevronLeft } from "react-icons/fa6";

// Utilities
import { fetchData } from "utils/fetch";
import { restAPIs } from "utils/restAPIs";
import { LoginProps } from 'utils/types';

// Components
import Otp from '../../../components/atoms/Otp';
import PrimaryButton from "../../../components/atoms/CustomButtons";

// Redux Actions and State
import { setLoginForm, setUserDetails } from '../../../redux/slices/setUser/userSlice'
import { KeyEventType, LoginData, LoginFormKeys } from 'utils/constants';

// Styles and Constant
import '../index.scss';


const OtpInput: React.FC<LoginProps> = ({ setType }) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loginForm } = useSelector((state: any) => state.user) || {};
  const { countryCode = '', phone = '', email = '', from = '',token='' } = loginForm || {}
  const [otp, setOtp] = useState('');
  const [secondsLeft, setSecondsLeft] = useState(30);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = React.useState('')
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let intervalId: NodeJS.Timeout | undefined;
    if (secondsLeft > 0) {
      intervalId = setInterval(() => {

        setSecondsLeft((prev) => Math.max(0, prev - 1));
      }, 1000);
    }
    return () => clearInterval(intervalId);
  }, [secondsLeft]);

  const handleSubmit = async () => {
    if (otp.length !== 6) {
      setError(LoginData.OtpRequired)
      return;
    }
    setIsSubmitting(true);
    try {
      await onSubmit(otp);
    } catch (error) {
      console.error(LoginData.ErrorSubmittingOTP, error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const onSubmit = async (otp: any) => {
    setError('')
    setIsLoading(true)
    let params = {
      phone,
      otp: otp.toString(),
    }
    let forgotPasswordParm = {
      token,
      otp: otp.toString(),
    }
    const res = from == LoginData.ForgotPassword ? await fetchData(restAPIs.getForgotPasswordOtp(forgotPasswordParm), navigate) :
      phone.length > 0 ? await fetchData(restAPIs.getLoginOtp(params), navigate) :
        await fetchData(restAPIs.getOtpVerify(otp), navigate)
    setIsLoading(false)
    if (from == LoginData.ForgotPassword) {
      if (res?.statusCode === 200) {
        setType(LoginFormKeys.ResetPassword)

        dispatch(setLoginForm({token:res?.data?.updatePasswordToken}))
      }
      else if (res?.statusCode === 400) {
        setError(res?.data?.message)
      }
    }
    else {
      if (res?.statusCode === 200) {
        dispatch(setUserDetails(res?.data))
        navigate('/home', { replace: true })
      }
      else {
        setError(res?.data?.message)
      }
    }
  }

  const handleKeyDown = (event: any) => {
    if (event.key === KeyEventType.Enter && !isLoading) {
      handleSubmit();
    }
  };


  const handleResendOtp = async () => {
    const res = from == LoginData.ForgotPassword ? await fetchData(restAPIs.getForgotPassword({
      email: email,
    }), navigate) :
      phone.length > 0 ? await fetchData(restAPIs.getPhoneOtp(loginForm), navigate) :
        await fetchData(restAPIs.getEmailRetryOtp(loginForm), navigate);
    if (res?.statusCode === 200) {
      dispatch(setLoginForm({ email:email,token:res?.data?.token, from: LoginData.ForgotPassword }))
      setSecondsLeft(30)
    }
    else {
      setError(res?.data?.fields?.phone)
    }
  };

  const onBack = () => {
    setOtp('')
    setType(from == LoginData.ForgotPassword ?
      LoginFormKeys.ForgetPassword :
      phone.length > 0 ?
        LoginFormKeys.PhoneNumberLogin :
        LoginFormKeys.SignUpForm)
  }

  return (
    <div className="phone-number-form-container">
      <FaChevronLeft onClick={onBack} className='phone-form-back-button' />
      <Typography align='left' fontWeight={400} variant="h6">
        {LoginData.EnterYourCode}
        <Typography variant='h1'>
          {phone.length > 0 ? `${countryCode} ${phone}` : email}
        </Typography>
      </Typography>
      <Box
        onKeyDown={handleKeyDown}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          marginTop: 5,
        }}
      >
        <Otp
          separator={<span>{' '}</span>}
          value={otp}
          setError={setError}
          onChange={setOtp}
          length={6} />
      </Box>
      <div className='otp-error-msg'>{error}</div>
      <div className="otp-resend-text">
        {secondsLeft > 0 ?
          <Typography align='left' fontWeight={400} fontSize={'13px'} variant="h6">{LoginData.ResendOTPIn} {secondsLeft} {LoginData.Seconds}</Typography> :
          <div style={{ marginTop: 0 }} className='otp-resend-text'>
            <Typography align='left' fontWeight={400} fontSize={'13px'} variant="h6">{LoginData.DidntReceiveCode} </Typography>
            <Typography className="signup-text" variant="h4" onClick={handleResendOtp} >
              {LoginData.ResendOTP}
            </Typography></div>}
      </div>
      <div className='otp_input_button'>
        <PrimaryButton
          isLoading={isLoading}
          fullWidth={false}
          onClick={handleSubmit} >
          {LoginData.VerifyOTP}
        </PrimaryButton>
      </div>
    </div>
  );
};

export default OtpInput;
