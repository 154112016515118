// Core
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// Styles and Constants
import './index.scss'

// Components
import SignUpForm from './components/SignUpForm'
import SignInForm from './components/SignInForm'
import PhoneNumberLogin from './components/PhoneNumberLogin';
import CreateUser from './components/CreateUser';
import OtpScreen from './components/OtpScreen';
import ForgetPassword from './components/ForgetPassword';
import ResetPassword from './components/ResetPassword';

// Utilities
import { loadOrganizations, loadUserFromLocalStorage } from 'utils/commonUtils';

// Third-Party 
import { Typography } from '@mui/material';

// Assets
import loginImg from '../../assets/images/login-img.png'

// Redux Actions and State
import { clearUserDetails } from '../../redux/slices/setUser/userSlice';
import { clearActiveClassroom } from '../../redux/slices/setClassroom/classRoomSlice';
import { LoginData, LoginFormKeys } from 'utils/constants';


const Login: React.FC = () => {

  const { typeParams, reset } = useParams()
  const navigate = useNavigate();
  const organizations = loadOrganizations();
  const dispatch = useDispatch()
  const [type, setType] = useState<string | null>(LoginFormKeys.SignInForm);
  const user = loadUserFromLocalStorage();
  const location = useLocation();
  const university = location.state?.item;

  useEffect(() => {
    setType(typeParams ?? LoginFormKeys.SignInForm)
  }, [typeParams]);

  useEffect(() => {
    if (reset == 'reset') {
      dispatch(clearUserDetails())
      dispatch(clearActiveClassroom())
    }
    if (user) {
      navigate('/feed', { replace: true })
    }
  }, []);

  useEffect(() => {
    if (!organizations) {
      navigate('/select-university');
    }
  }, [organizations]);

  if(!organizations) {
    return null;
  }

  const getForm = (item: string | null): JSX.Element => {
    switch (item) {
      case LoginFormKeys.SignInForm:
        return <SignInForm setType={setType} university={university} />;
      case LoginFormKeys.PhoneNumberLogin:
        return <PhoneNumberLogin setType={setType} />;
      case LoginFormKeys.CreateUser:
        return <CreateUser setType={setType} />;
      case LoginFormKeys.SignUpForm:
        return <SignUpForm setType={setType} />;
      case LoginFormKeys.OtpScreen:
        return <OtpScreen setType={setType} />;
      case LoginFormKeys.ForgetPassword:
        return <ForgetPassword setType={setType} />;
      case LoginFormKeys.ResetPassword:
        return <ResetPassword setType={setType} />;
      default:
        return <div />;
    }
  }

  return (
    <div className="sign-in-sign-up">
      <div className="flip-card-front">
        <div className='login-container'>
          {getForm(type)}
        </div>
      </div>
      <div className={'image-container'}>
        <div className='login-sub-wrapper'>
          <Typography className='text' variant="h1">{LoginData.CollaborationEasy}</Typography>
          <img src={loginImg} className='login-img' alt="Example" />
        </div>
      </div>

    </div>
  );
};

export default Login;
