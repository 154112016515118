// Core
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// Components
import GridContainer from 'components/molecules/GridContainer';
import UserCard from 'components/atoms/UserCard';
import NoteFiles from 'components/atoms/NoteFiles';
import NoNotes from 'components/atoms/NoNotes';
import CreateNoteModal from 'components/atoms/CreateNoteModal';
import { useSnackbar } from 'components/atoms/SnackbarProvider';
import CommonDeleteModal from 'components/atoms/CommonDeleteModal';
import ClassSwitchInfoFooter from 'components/molecules/ClassSwitchInfoFooter';

// Third-Party Libraries
import { Typography } from '@mui/material';
import _ from 'lodash';
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';

// Utilities
import { useFetchClassRoomItems } from 'utils/customHooks';
import { fetchData } from "utils/fetch";
import { restAPIs } from "utils/restAPIs"
import { determineClassroomStatus, loadActiveClassroomLocalStorage, loadUserFromLocalStorage } from 'utils/commonUtils';
import { CLASSROOM_STATUS, CreateTypes, DocumentDetailsData, DocumentDetailsDeleteModal, MODAL_STYTLE, NoteDetailsData, SnackBarMessages, StatusType } from 'utils/constants';

// Styles and Constants
import './index.scss'
import { colors } from 'assets/styles/colors';


const Notes: React.FC = () => {

    const { showSnackbar } = useSnackbar();
    const { refId = '' } = useParams()
    const navigate = useNavigate();
    const user = loadUserFromLocalStorage();
    const [selected, setSelected] = useState<number>(0)
    const [notes, setNotes] = useState<any>([]);
    const activeClassroom = loadActiveClassroomLocalStorage()
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [createType, setCreateType] = useState<any>('');
    const [editItem, setEditItem] = useState<any>(null);
    const [deleteModal, setDeleteModal] = useState<boolean>(false)
    const { fetchNotes, fetchMetaData } = useFetchClassRoomItems()
    const isOwned = user?._id == notes?.[0]?.userId?._id || user?._id == notes?.[0]?.createdBy?._id

    const loadMore = async () => {
        setIsLoading(true)
        const res = await fetchData(restAPIs.getNoteItem(refId), navigate)
        setIsLoading(false)
        if (res?.statusCode === 200 && res?.data) {
            if (refId) {
                setNotes([res.data])
            }
        }
    }

    const onEdit = () => {
        setCreateType(CreateTypes.Note)
    }

    const onDeleteItem = () => {
        setDeleteModal(true)
    }

    useEffect(() => {
        loadMore()
    }, [])

    useEffect(() => {
        setEditItem(notes[selected])
    }, [notes])

    const onDelete = async () => {
        setDeleteModal(false)
        setIsLoading(true)
        const res = await fetchData(restAPIs.getNotesDelete(notes[selected]?._id), navigate)
        setIsLoading(false)
        if (res.status == 200) {
            let temp = [...notes]
            temp.splice(selected, 1)
            setNotes(temp)
            showSnackbar(SnackBarMessages.NoteDeletedSuccessfully, StatusType.success)
            fetchNotes()
            fetchMetaData()
            navigate(-1)
        }
        else {
            showSnackbar(SnackBarMessages.SomethingWentWrong, StatusType.error)
        }
    }

    return (
        <GridContainer
            pageTitle={_.capitalize(notes?.[0]?.title)}
            data={notes}
            onEdit={onEdit}
            onDelete={onDeleteItem}
            optionsVisible={isOwned}
            backButton={true}
            leftGridPartition={1}>
            {activeClassroom?._id != notes?.[0]?.classRoomId &&!isLoading&&
                <ClassSwitchInfoFooter data={notes?.[0]} status={CLASSROOM_STATUS.INACTIVE} />}
            {isLoading ?
                <div className='loader-container notes_progress'>
                    <CircularProgress size={15} sx={{ color: colors?.primary }} />
                </div> :
                <div className='notes_details'>
                    {notes?.map((item: any, index: number) => {
                        const fileCount = item?.files?.length
                        return (
                            <div onClick={() => setSelected(index)} className={`notes-container fullscreen`}>
                                <Typography
                                    variant='h6'
                                    fontWeight={400}
                                    fontSize={'12px'}
                                    style={{ wordWrap: 'break-word' }}
                                    key={index}>
                                    {item.description}
                                </Typography>
                                {item?.subjectId?.name?.length > 0 && <div>{NoteDetailsData.Subject}</div>}
                                <div className='notes-sub-container'>
                                    {item?.subjectId?.name?.length > 0 &&
                                        <Tooltip title={item?.subjectId?.name}>
                                            <div className='notes-sub-name'>
                                                {item?.subjectId?.name}
                                            </div></Tooltip>}
                                </div>
                                <div>{NoteDetailsData.CreatedBy}</div>
                                <div className="notes-user-card-wrapper">
                                    <UserCard
                                        height={32}
                                        width={32}
                                        item={item}
                                        h1FontSize={'11px'}
                                        pFontSize={'10px'} />
                                </div>
                                <div className='notes-count-name-text'>
                                    {fileCount} {fileCount == 1 ? DocumentDetailsData.File : DocumentDetailsData.Files}
                                </div>
                                <div >
                                    <NoteFiles
                                        detailRefresh={loadMore}
                                        setNotes={setNotes}
                                        selected={selected}
                                        fullScreen={true}
                                        notes={notes[selected]}
                                        notesArray={notes} />
                                </div>
                            </div>
                        );
                    })}
                    <CreateNoteModal
                        open={createType == CreateTypes.Note}
                        editItem={editItem}
                        createType={createType}
                        modalStyle={MODAL_STYTLE}
                        detailRefresh={loadMore}
                        handleClose={() => setCreateType('')} />
                    <CommonDeleteModal
                        open={deleteModal}
                        buttonLabel={DocumentDetailsDeleteModal.YesDelete}
                        description={DocumentDetailsDeleteModal.Desc}
                        title={DocumentDetailsDeleteModal.DeleteNote}
                        handleClose={() => setDeleteModal(false)}
                        handleSubmit={() => onDelete()}
                    />
                    {notes?.length == 0 && !isLoading &&
                        <NoNotes type={NoteDetailsData.NoteUnavailable} />}
                </div>}
        </GridContainer>
    )
};

export default Notes
