import React from 'react';
import { Typography } from '@mui/material';
import { SecondryButton } from '../CustomButtons';
import './index.scss'
import { useCheckRole } from 'utils/customHooks';

interface NoNotesProps {
    type?: string;
    onClick?: () => void;
    isOwned?: boolean;
    subjectList?:boolean
}

const data = [
    {
        iconName: 'fi fi-rr-memo-pad',
        title: 'Notes',
    },
    {
        iconName: 'fi fi-rr-duplicate',
        title: 'Documents',
    },
    {
        iconName: 'fi fi-rr-book-copy',
        title: 'Subjects',
    },
    {
        iconName: 'fi fi-rr-book-alt',
        title: 'Curriculum',
    },
    {
        iconName: 'fi fi-rr-party-horn',
        title: 'Events',
    },
    {
        iconName: 'fi fi-rr-memo-pad',
        title: 'Note unavailable',
    },
    {
        iconName: 'fi fi-rr-duplicate',
        title: 'Documents unavailable',
    },
    {
        iconName: 'fi fi-rr-memo-pad',
        title: 'Post unavailable',
    },
];


const NoNotes: React.FC<NoNotesProps> = ({ type, onClick, isOwned,subjectList }) => {

    const getItemByTitle = (type?: string) => {
        return data.find(item => item.title === type);
    };

    const result = getItemByTitle(type);

    return (
        <div className='no-note-container'>
            <i className={result?.iconName} style={{ fontSize: '36px' }}></i>
            <Typography variant='h4'>{(type!=='Note unavailable'&&type!=='Documents unavailable'&&type!=='Post unavailable')?'No':''} {(type == 'Documents' || type == 'Notes') ? 'Files' : type}</Typography>
            {type=='Note unavailable'?
            <Typography variant='h6'>Creator may have removed the note.</Typography>:type=='Documents unavailable'?
            <Typography variant='h6'>Creator may have removed the documents.</Typography>:type=='Post unavailable'?
            <Typography variant='h6'>Creator may have removed the Post.</Typography>:
            <Typography variant='h6'></Typography>}
            {isOwned &&
                <SecondryButton
                    fullWidth={false}
                    onClick={onClick}
                    text={type == 'Curriculum' ? subjectList?'Add Subject':'Add sessions' : `Add files`} />}
        </div>
    );
};

export default NoNotes;
