import { PaletteMode } from '@mui/material';
import {colors} from '../styles/colors'
import { error } from 'console';

const getDesignTokens = (mode: any) => ({
  palette: {
    mode,
    ...(mode === 'light'
      ? {
        primary: {
          main: colors?.primary,
        },
        secondary: {
          main: colors?.secondary,
        },
        ternary: {
          main: colors?.white,
        },
      }
      : {
        primary: {
          main: '#00B2F7',
        },
        secondary: {
          main: '#494D5C',
        },
        ternary: {
          main: colors?.white,
        },
      }),
  },
  typography: {
    mode,
    ...(mode === 'light'
      ? {
        h1: {
          fontSize: '20px', 
          color: colors?.secondary,
          fontWeight: 900,
          fontFamily: "Manrope",
          letterSpacing:'.3px'
        },
        h3: {
          fontSize: '18px', 
          color: colors?.secondary,
          fontWeight: '600',
          fontFamily: "Manrope",
        },
        h4: {
          fontSize: '14px', 
          color: colors?.secondary,
          fontWeight: 600,
          fontFamily: "Manrope",
        },
        h5: {
          fontSize: '16px', 
          color: colors?.secondary,
          fontWeight: '600', 
          fontFamily: "Manrope",
        },
        h6: {
          fontSize: '15px', 
          color: colors?.secondary,
          fontWeight: '400', 
          fontFamily: "Manrope",
        },
        subtitle2:{
          fontSize: '10px', 
          color: colors?.secondary,
          fontWeight: '100',
          fontFamily: "Manrope",
        },
        error: {
          fontSize: '10px', 
          fontWeight:400, 
          color: 'red', 
          fontFamily: "Manrope",
        },
      }
      : {
        h1: {
          fontSize: '35px', 
          color: colors?.secondary,
          fontWeight: '800', 
        },
        h3: {
          fontSize: '18px', 
          color: colors?.secondary,
          fontWeight: '600',
        },
        h4: {
          fontSize: '14px', 
          color: colors?.secondary,
          fontWeight: 600
        },
        h5: {
          fontSize: '16px', 
          color: colors?.secondary, 
          fontWeight: '600', 
        },
        h6: {
          fontSize: '16px', 
          color: colors?.secondary,
          fontWeight: '200', 
        },
        subtitle2:{
          fontSize: '10px', 
          color: colors?.secondary,
          fontWeight: '100',
        },
        customHeading: {
          fontSize: '1.75rem', 
          fontWeight: 'bold', 
          color: 'red', 
        },
      }),
  },
});

export default getDesignTokens;
