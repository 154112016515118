import React from 'react';
import './index.scss'
import QuickAccess from '../../atoms/QuickAccess';
import TodaysClasses from '../../atoms/TodaysClasses';

function MyClassRoom() {

    return (
        <div>
            <QuickAccess/>
            <TodaysClasses/>
        </div>
    );
}

export default MyClassRoom;
