import React, { useEffect, useState } from 'react';
import './index.scss';
import { useDispatch, useSelector } from 'react-redux';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import { ProfileCard } from 'assets/styles/commonStyle';
import { Avatar, CircularProgress, IconButton, Menu, MenuItem, Popover, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CancelIcon from '@mui/icons-material/Cancel';
import { setActiveClassRoom } from '../../../redux/slices/setClassroom/classRoomSlice';
import { useCustomSWR, useFetchClassRoomItems, useUpdateUser } from 'utils/customHooks';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { loadActiveClassroomLocalStorage, loadUserFromLocalStorage } from 'utils/commonUtils';


const ClassmatesListWrapper: React.FC<any> = ({ fromSettings }) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const {
        fetchClassroom,
    } = useFetchClassRoomItems()
    const userObject = loadUserFromLocalStorage();
    const user = userObject?.userDetails
    const { classroomData } = useSelector((state: any) => state.classRoom) || {};
    const activeClassroom = loadActiveClassroomLocalStorage()
    const { isAdmin } = classroomData || {}
    const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
    const [isActionLoding, setActionLoading] = useState(false);
    const [list, setList] = useState<any>([])
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [selected, setSelected] = useState<any>(null)
    const adimArray =
        activeClassroom?.owners?.length > 0 ? activeClassroom.owners : [];


    let array: any = activeClassroom?.members?.length > 0 && activeClassroom?.members;
    // let temp_array: any = [];
    // if (Array.isArray(array)) {
    //     array.map((item: any) => {
    //         if (item.status === 'ACTIVE') {
    //             temp_array.push(item.userId);
    //         }
    //     });
    // }
    // let params = temp_array;
    const { data: response, isLoading, isError, mutate } = useCustomSWR(restAPIs.getUserList(activeClassroom?._id),[activeClassroom?._id]);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>, item: any, index: number) => {
        setSelectedIndex(index)
        setSelected(item)
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    useEffect(() => {
        fetchUsers();
    }, [response]);

    useEffect(() => {
        mutate();
    }, [localStorage.getItem('activeClassroom')]);



    const fetchUsers = async () => {
        if (response?.data) {
            let userList = response?.data?.classRoomUsers ? response.data?.classRoomUsers : [];
            let sortedUserList = Array.isArray(userList)
                ? userList.sort((a: any, b: any) =>
                    (a.firstName + a.lastName).localeCompare(b.firstName + b.lastName)
                )
                : [];
            let filteredPeople = fromSettings ? sortedUserList : sortedUserList.filter(
                (item: any) => item._id !== user?._id,
            );
            setList(filteredPeople);
        }
    }

    const makeAdmin = async (type?: string) => {
        let classRoomId = activeClassroom?._id;
        handleClose()
        setActionLoading(true)
        const response = await fetchData(restAPIs.getAddAdmin(classRoomId, selected?._id, type ?? ''), navigate);
        setActionLoading(false)
        if (response?.status === 200) {
            fetchClassroom(classRoomId)
        }
    };

    const onRemove = async () => {
        let classRoomId = activeClassroom?._id;
        handleClose()
        setActionLoading(true)
        let params = {
            studentId: selected?._id,
        };
        const res = await fetchData(restAPIs.getLeaveRoom(activeClassroom?._id, params, false), navigate)
        setActionLoading(false)
        if (res?.status === 200) {
            fetchClassroom(id)
            fetchClassroom(classRoomId)
        }
    };

    return (
        <div className="__full_list">
            {isLoading && <CircularProgress size={'20px'} color="primary" />}
            <div className='__header_list'>
                <div className='class-count'>{list?.length} {list?.length === 1 ? 'Member' : 'Members'}</div>
                {isAdmin &&
                    <IconButton style={{ width: '40px', height: '40px' }} onClick={() => navigate('/add-classmates')}>
                        <PersonAddAltIcon />
                    </IconButton>
                }
            </div>
            {list?.map((item: any, index: number) => {
                return (
                    <div className='__card_d'>
                        <ProfileCard style={{ alignItems: 'center', gridTemplateColumns: '45px 1fr auto' }}>
                            <Avatar
                                src={item?.profilePic}
                                sx={{ bgcolor: '#575757', width: 34, height: 34, fontSize: '14px' }}
                                aria-label="recipe"
                            >
                                {item?.firstName?.slice(0, 1)}
                                {item?.lastName?.slice(0, 1)}
                            </Avatar>
                            <div className="___label">
                                <div className="name">
                                    {item?._id == user?._id ?
                                        <h1>You</h1> :
                                        <h1 onClick={() => navigate(`/user-profile/${item?._id}`)}>{item?.firstName} {item?.lastName}</h1>}
                                </div>
                            </div>
                            <div>
                                <div className='__role'>{item.role}</div>
                                {adimArray.some((data: any) => data.userId === item._id) && <span className='__admin'>Admin</span>}
                                {item._id !== user?._id && isAdmin &&
                                    <IconButton onClick={(e) => handleClick(e, item, index)}>
                                        {selectedIndex === index && isActionLoding ? <CircularProgress size={'20px'} color="primary" /> :
                                            <MoreVertIcon />}
                                    </IconButton>}
                            </div>
                        </ProfileCard>
                    </div>
                )
            })}


            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}

                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                className='___menu'
                PaperProps={{
                    sx: {
                        borderRadius: '10px',
                        boxShadow: 'none',
                        border: '1px solid #eee'
                    },
                }}
            >
                <MenuItem className='item-container' onClick={() => navigate(`/user-profile/${selected?._id}`)}>
                    View Profile
                </MenuItem>

                <MenuItem className='item-container' onClick={() => adimArray.some((data: any) => data.userId === selected?._id) ? makeAdmin('&type=remove') : makeAdmin()}>
                    {adimArray.some((data: any) => data.userId === selected?._id) ? 'Dismiss as Admin' : 'Make Classroom Admin'}
                </MenuItem>

                <MenuItem className='item-container' onClick={onRemove}>
                    Remove from Classroom
                </MenuItem>
            </Menu>
        </div>
    );
};

export default ClassmatesListWrapper;
