import React from 'react';
import './index.scss';

interface Props {
    pollTitle?: string;
    options?: any
    setCreatePoll?: React.Dispatch<React.SetStateAction<boolean>>;
    onDelete?: () => void
}
const PollPreview: React.FC<Props> = ({ pollTitle, options,setCreatePoll,onDelete }) => {

    return (
        <div className='poll_preview_card'>
            <div className='poll_Preview_header'>
                <div className='poll_title'>{pollTitle}</div>
                {onDelete&&setCreatePoll &&
                <div  className='icon_container'>
                    <i onClick={()=>setCreatePoll(true)} style={{ cursor: 'pointer' }} className="fi fi-rr-pencil"/>
                    <i onClick={onDelete} style={{ cursor: 'pointer' }} className="fi fi-rr-trash"/>
                </div>}
            </div>
            {options.map((item:any, index:number) => (
                <div className='poll_preview_option_card'>
                <div key={index}>{item.value}</div>
                </div>
      ))}
        </div>
    );
};

export default PollPreview;