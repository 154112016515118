// Core
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Components
import './index.scss';
import GridContainer from 'components/molecules/GridContainer';
import UrlContentTextWrapper from 'components/atoms/UrlContentTextWrapper';

// Utilities
import { getTime, loadActiveClassroomLocalStorage, loadUserFromLocalStorage } from 'utils/commonUtils';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';

// Third-Party Libraries
import { CircularProgress } from '@mui/material';
import { MyActivitiesData } from 'utils/constants';

const MyActivities: React.FC = () => {

    const activeClassroom = loadActiveClassroomLocalStorage()
    const userObject = loadUserFromLocalStorage();
    const user = userObject?.userDetails
    const [activities, setActivities] = useState<any>([])
    const navigate = useNavigate();
    const [page, setPage] = useState(1);
    const [isLastPage, setIsLastPage] = useState(false);
    const [isLoding, setLoading] = useState(false);

    useEffect(() => {
        setPage(1)
        fetchActivities();
    }, []);

    const loadMore = () => {
        if (!isLastPage) {
            fetchActivities()
        }
    }

    const fetchActivities = async () => {
        setLoading(true)
        let params = {
            page: page,
            limit: 10,
            classRoomId:activeClassroom?._id,
            module: "CLASSROOM",
            userId:user?._id
        };
        const res = await fetchData(restAPIs.getMyActivities(params), navigate)
        setLoading(false)
        if (res.statusCode === 200 && res.data) {
            setActivities(activities.concat(res.data.activities))
            setIsLastPage(res.data.isLastPage);
            setPage(page + 1)
        }
    }

    return (
        <GridContainer
            loading={isLoding}
            loadMore={loadMore}
            page={page}
            pageTitle={MyActivitiesData.MyActivities}
            backButton
            leftGridPartition={3 / 4}
        >
            <div>
                <div className='__activities_lists'>
                    {activities?.map((item: any, index: any) => {
                        return (
                            <div className='__single_activity'>
                                <UrlContentTextWrapper content={item?.description ? item?.description : item?.title} />
                                <p className='__created_at'>{getTime(item?.createdAt)}</p>
                            </div>
                        )
                    }
                    )}
                    {isLoding && <CircularProgress size={'20px'} color="primary" />}
                    {!isLastPage&&page===2&&
                    <div onClick={loadMore} className='my_activities__view_more'>View More</div>}
                </div>

            </div>

        </GridContainer>
    );
};

export default MyActivities;
