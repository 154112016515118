import React from 'react';
import './index.scss'

interface PostImagesProps {
    item?: { id: number; url: string }[];
}

const PostUrls: React.FC<PostImagesProps> = ({ item }) => {

    if (!item || item.length === 0) {
        return null;
    }



    return (
        <div className='post-url-wrapper'>
            {item?.map((e) => (
                <div onClick={()=>window.open(e.url, '_blank')} key={e.id} className='url-preview'>
                    <a href={e.url} className='url' target="_blank" rel="noopener noreferrer">
                        {e.url}
                    </a>
                </div>
            ))}
        </div>
    );
};

export default PostUrls;
