import React, { useState, useEffect, useRef } from 'react';
import './index.scss';
import { useNavigate } from 'react-router-dom';
import { colors } from 'assets/styles/colors';

const urlRegex = /(https?:\/\/[^\s]+)/g;

interface ClickableContentProps {
  content?: string;
  tagged?: TaggedItem[];
  noOfLines?:number,
  fromPostDetails?:boolean
}

interface TaggedItem {
  _id: string;
  firstName: string;
  lastName: string;
}

const UrlContentTextWrapper: React.FC<ClickableContentProps> = ({ content, tagged,noOfLines=4,fromPostDetails = false}) => {
  const navigate = useNavigate();
  const [isExpanded, setIsExpanded] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);
  const contentRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    if (contentRef.current) {
      const computedStyle = window.getComputedStyle(contentRef.current);
      const lineHeight = parseFloat(computedStyle.lineHeight) || 20;
      const maxHeight = lineHeight * 4;
      setIsTruncated(contentRef.current.scrollHeight > maxHeight);
    }
  }, [content]);

  if (!content) {
    return <p style={{WebkitLineClamp: noOfLines}} className="content-wrapper"></p>;
  }

  const handleNamePress = (id: string) => {
    console.debug(`Clicked on user with ID: ${id}`);
    navigate(`/user-profile/${id}`);
  };

  const processText = (text: string) => {
    const tagRanges: { start: number; end: number; id: string; userId: string; name: string }[] = [];
    const processedText: (string | JSX.Element)[] = [];
    let lastIndex = 0;

    // Handle tagged mentions
    tagged?.forEach(({ _id, firstName, lastName }, index) => {
      const fullName = `${firstName} ${lastName}`;
      const regex = new RegExp(`\\b${fullName}\\b`, 'gi');
      let match;
      while ((match = regex.exec(text)) !== null) {
        tagRanges.push({
          start: match.index,
          end: regex.lastIndex,
          id: `${_id}-${index}-${match.index}`,
          userId: _id,
          name: match[0],
        });
      }
    });

    tagRanges.sort((a, b) => a.start - b.start);

    tagRanges.forEach((tag) => {
      if (tag.start > lastIndex) {
        // Add text before the mention
        processedText.push(text.substring(lastIndex, tag.start));
      }

      // Add the clickable mention
      processedText.push(
        <span key={tag.id} style={{ color: colors.primary, cursor: 'pointer' }} onClick={() => handleNamePress(tag.userId)}>
          {tag.name}
        </span>
      );

      lastIndex = tag.end;
    });

    // Add remaining text after the last mention
    if (lastIndex < text.length) {
      processedText.push(text.substring(lastIndex));
    }

    return processedText;
  };

  const processContent = (content: string) => {
    const parts = content.split(urlRegex);
    return parts.map((part, index) => {
      if (urlRegex.test(part)) {
        // Render URLs as clickable links
        return (
          <a key={index} className="link" href={part} target="_blank" rel="noopener noreferrer">
            {part}
          </a>
        );
      }
      // Process mentions and normal text
      return <React.Fragment key={index}>{processText(part)}</React.Fragment>;
    });
  };

  const handleToggle = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation()
    setIsExpanded(!isExpanded);
  };

  return (
    <div style={{marginLeft:fromPostDetails?'42px':0}} className="url_content_text_wrapper">
      <p  ref={contentRef} style={{WebkitLineClamp:isExpanded ? '' : noOfLines}} className={`content-wrapper ${isExpanded ? 'expanded' : ''}`}>
        {processContent(content)}
      </p>
      {isTruncated && noOfLines==4 &&(
        <span className="read-more" onClick={handleToggle}>
          {isExpanded ? 'Read Less' : 'Read More'}
        </span>
      )}
    </div>
  );
};

export default UrlContentTextWrapper;
