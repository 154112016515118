import React, { useState, useMemo } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ColorModeContext, { ColorMode } from './contextApi/ColorModeContext';
import logo from './logo.svg';
import './App.scss';
import { Provider } from 'react-redux';
import AppRouter from './routes';
import store from './redux/store/store';
import { loadUserFromLocalStorage, loadClassroomDataLocalStorage, loadActiveClassroomLocalStorage } from 'utils/commonUtils';
import { setUserDetails } from "./redux/slices/setUser/userSlice";
import { setActiveClassRoom, setClassroomData } from "./redux/slices/setClassroom/classRoomSlice";
import getDesignTokens from './assets/styles/getDesignTokens';
import { PaletteMode } from '@mui/material';
import ContextProviders from 'contextApi/ContextProviders';

function App() {
  const classRoom=store.getState().classRoom
  const loadedUser = loadUserFromLocalStorage();
  const loadedClassroomData = loadClassroomDataLocalStorage()
  if(store.getState().user.user===null){
  store.dispatch(setUserDetails(loadedUser));
  }
  
  store.dispatch(setClassroomData(loadedClassroomData));
  const [mode, setMode] = React.useState<PaletteMode>('light');

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        localStorage.setItem('mode', localStorage.getItem('mode') === 'light' ? 'dark' : 'light')
        setMode((prevMode: PaletteMode) =>
          prevMode === 'light' ? 'dark' : 'light',
        );
      },
    }),
    [],
  );


  const theme = React.useMemo(() => createTheme(getDesignTokens(mode)), [mode]);
  return (
    <Provider store={store}>
      <div className="App scroll-container">
        <ColorModeContext.Provider value={colorMode}>
          <ThemeProvider theme={theme}>
          <ContextProviders>
              <AppRouter />
            </ContextProviders>
          </ThemeProvider>
        </ColorModeContext.Provider>
      </div>
    </Provider>
  );
}

export default App;
