import React, { ReactNode } from 'react';
import { SnackbarProvider } from '../components/atoms/SnackbarProvider';
import { MetadataProvider } from './MetadataContext';

interface ContextProvidersProps {
  children: ReactNode;
}

const ContextProviders = ({ children }: ContextProvidersProps) => {
  return (
    <SnackbarProvider>
      <MetadataProvider>
        {children}
      </MetadataProvider>
    </SnackbarProvider>
  );
};

export default ContextProviders;
