import React, { useEffect, useRef, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './index.scss'

interface PostImagesProps {
    item?: any;
}

const PostImages: React.FC<PostImagesProps> = ({ item }) => {

    const [images, setImages] = useState<any>([]);
    const carouselRef = useRef<Carousel>(null);

    useEffect(() => {
        let tempImage: any[] = [];
        if (item?.length > 0) {
            item.forEach((e: any) => {
                if (e.type?.startsWith('image/')) {
                    tempImage.push(e);
                }
            });
            setImages(tempImage)
        }
        else {
            setImages([])
        }
        if (carouselRef.current) {
            carouselRef.current.moveTo(0);
        }
    }, [item]);

    if (images?.length == 0) {
        return null
    }

    return (
        <div className='post-image-wrapper' style={{ borderRadius: '10px', overflow: 'hidden' }}>
            <Carousel 
            ref={carouselRef} 
            showArrows 
            useKeyboardArrows 
            showIndicators={images?.length > 1} 
            emulateTouch 
            dynamicHeight 
            showThumbs={false}>
                {images.map((item: any) => (
                    <div >
                        <img className='post-image-img' src={item.url} />
                    </div>
                ))}
            </Carousel>
        </div>
    );
};

export default PostImages;
