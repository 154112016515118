
import styled from "styled-components";

export const ActivityList = styled.div`
    width:auto;
    text-align:left;
`

export const SingleActivity = styled.div`
    margin-bottom:10px;
    padding:10px;
    transition:0.4s;
    // cursor:pointer;
    border-radius: 10px;
    // &:hover {
    //     background-color: #0000000a !important;
    //   }
    h3{
   font-size: 13px;
    font-weight: 700;
    margin: 4px 0;
    }
    span{
    font-size:13px;
    }
    p{
    margin:0;
    margin-top:5px;
    font-size:12px;
    color:#777;
    }
`