import React, { useState } from 'react';
import ModalView from '../ModalView';
import { IoIosClose } from "react-icons/io";
import './index.scss'
import PrimaryButton from '../CustomButtons';
import { useLogout } from 'utils/customHooks';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import { useNavigate } from 'react-router-dom';

interface Props {
    handleClose?: () => void;
    open: any;
    modalStyle?: any;
    data?: any
    onSubmit?: () => void
    primaryButton?: boolean
}

const tempdata = {title:'Delete Account',desc:'Are you sure want to delete account?',button:"Yes, I'am Sure!"}

const DeleteModal: React.FC<Props> = ({ open, handleClose, modalStyle,data=tempdata,onSubmit,primaryButton=true }) => {

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const logout = useLogout()
    const navigate = useNavigate();

    const onClose = () => {
        handleClose?.()
    }

    const handleSubmit = async () => {
        setIsLoading(true)
        let params = {};
        const res = await fetchData(restAPIs.deactivateAccount(params),navigate)
        setIsLoading(false)
        if (res.status === 200 && res.data) {
            logout();
        }
    }

    const RenderModal = () => (
        <div className="create-logout-modal">
            <div className='__header'>
                <h4>{data.title}</h4>
                <button onClick={handleClose}><IoIosClose /></button>
            </div>
            <div className="__logout-content">
                <p>{data.desc}</p>
            </div>
            <div className={`modal-footer-button ${primaryButton ? '__primary' : '___btn'}`}>
                <PrimaryButton
                    fullWidth={false}
                    onClick={() =>onSubmit?onSubmit(): handleSubmit()}
                    isLoading={isLoading}>
                    {data.button}
                </PrimaryButton>
            </div>
        </div>
    )
    return (
        <ModalView open={open} handleClose={onClose} modalStyle={modalStyle}>
            {RenderModal()}
        </ModalView>
    )
};

export default DeleteModal;
