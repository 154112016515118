import React, { useRef }  from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import '../../assets/styles/globalStyles.scss'
import { styled } from '@mui/system';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { colors } from 'assets/styles/colors';

interface Props {
  isLoading?: boolean;
  children?: any;
  text?: any;
  onClick?: any;
  type?: string;
  fullWidth?: boolean;
  href?: string;
  disabled?: boolean;
  onMouseDown?: any;
  progress?: number|null;
  bgColor?:any;
  color?:any;
  className?:string
  disableStyle?:boolean
}

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number },
) {
  return (
    <Box sx={{ position: 'relative', display: 'flex', justifyContent:'center', alignItems:'center'}}>
      <CircularProgress size={32} sx={{color:colors.white}} variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          fontSize={'7px'}
          color={colors.white}
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

const StyledCircularProgress = styled(CircularProgress)(({ theme }) => ({
  position: 'absolute',
  left: '50%',
  top: '50%',
  color: '#fff',
  marginTop: -10,
  marginLeft: -12
}));

const PrimaryButton: React.FC<Props> = ({ isLoading, text, fullWidth = true, children, onClick, type, progress,disableStyle, ...rest }) => {
  
  const buttonRef = useRef(null);

  const handleKeyDown = (event:any) => {
    if (event.key === 'Enter' && !isLoading) {
      event.preventDefault();
      onClick?.();
    }
  };

  return (
    <Button
      variant={type === 'outlined' ? 'outlined' : 'contained'}
      disabled={isLoading}
      ref={buttonRef}
      tabIndex={0}
      style={{ textTransform: 'none',position:'relative', width:'200px' }}
      color="primary"
      autoFocus={false}
      fullWidth={fullWidth}
      onKeyDown={handleKeyDown}
      type="submit"
      className={type ? `${type}-button` : `submit-button ${disableStyle?'disable_style':''}` }
      onClick={onClick}
      
      {...rest as ButtonProps}
    >
      {
        isLoading && progress ? <CircularProgressWithLabel size={25} value={progress} /> :
          isLoading && <StyledCircularProgress size={20} />}
      {/* <span className='__cust ' style={{ visibility: isLoading ? 'hidden' : 'visible', fontWeight: 500 }}>{children ?? text}</span> */}
      { !isLoading && children}
    </Button>
  );
};

export default PrimaryButton;

const SecondryButton: React.FC<Props> = ({ isLoading, text, children, onClick, fullWidth = true,className, onMouseDown, disabled, ...rest }) => {

  return (
    <Button
      variant={'outlined'}
      disabled={disabled ?? isLoading}
      color="primary"
      fullWidth={fullWidth}
      type="submit"
      className={className??'secondry-button'}
      onClick={onClick}
      onMouseDown={onMouseDown}
      {...rest as ButtonProps}
    >
      {isLoading &&
        <StyledCircularProgress size={20} />}
      <span style={{ visibility: isLoading ? 'hidden' : 'visible' }}>{children ?? text}</span>
    </Button>
  );
};

const CreateButton: React.FC<Props> = ({ isLoading, text, children,bgColor=colors.secondary,color=colors.white, onClick, fullWidth = false, onMouseDown, disabled, ...rest }) => {

  return (
    <Button
      variant={'contained'}
      disabled={disabled ?? isLoading}
      color="primary"
      fullWidth={fullWidth}
      style={{ textTransform: 'none'}}
      type="submit"
      className='create-button'
      onClick={onClick}
      sx={{
        boxShadow:'none',
        border:'transparent',
        backgroundColor:bgColor,
        '&:hover': {
          boxShadow:'none',
          backgroundColor: bgColor
        },
      }}
      onMouseDown={onMouseDown}
      {...rest as ButtonProps}
    >
      {isLoading &&
        <StyledCircularProgress size={20} />}
      <span className='text' style={{ visibility: isLoading ? 'hidden' : 'visible',color:color }}>{children ?? text}</span>
    </Button>
  );
};

export { SecondryButton,CreateButton };





