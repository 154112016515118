// Core
import React, { useState } from "react";
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";

// Third-Party Libraries
import { TextField, Typography } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Alert from '@mui/material/Alert';
import {
  isMobile,
  isTablet,
  browserName,
  engineName,
  osName,
  osVersion,
} from 'react-device-detect';

// Utilities
import { fetchData } from "utils/fetch";
import { restAPIs } from "utils/restAPIs";
import {
  CursorType,
  DeviceType,
  FIRST_NAME_LENGTH,
  InputLabels,
  InputMargins,
  InputNames,
  InputTypes,
  InputVariants,
  LAST_NAME_LENGTH,
  LoginData,
  LoginFormKeys,
  VisibilityTypes
} from 'utils/constants';
import {
  LoginProps,
  SignUpFormErrorState,
  SignUpFormState,
  SignUpInitialError,
  SignUpInitialForm
} from "utils/types";

// Components
import PrimaryButton from "../../../components/atoms/CustomButtons";

// Redux Actions and State
import { setLoginForm } from '../../../redux/slices/setUser/userSlice'

// Styles and Constant
import { colors } from "assets/styles/colors";
import '../index.scss';

// Assets
import logo from '../../../assets/images/classteamlogo.png'

const SignUpForm: React.FC<LoginProps> = ({ setType }) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState<SignUpFormState>(SignUpInitialForm);
  const [errors, setErrors] = useState<SignUpFormErrorState>(SignUpInitialError);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setConfirmShowPassword] = useState<boolean>(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const trimmedValue = name.includes('assword') ? value.trim() : value;
    setFormData({ ...formData, [name]: trimmedValue });
    if ((name === LoginData.FirstName || name === LoginData.LastName) && value.length > FIRST_NAME_LENGTH) {
      setErrors({ ...errors, [name]: `${LoginData.MaximumLengthIs} ${FIRST_NAME_LENGTH} ${LoginData.Characters}` });
    } else {
      setErrors({ ...errors, [name]: '' });
    }
  };


  const validateForm = (): boolean => {
    let newErrors = { ...SignUpInitialError };
    if (!formData.firstName) {
      newErrors.firstName = LoginData.FirstNameRequired;
    }
    if (formData.firstName?.length > FIRST_NAME_LENGTH) {
      newErrors.firstName = `${LoginData.MaximumLengthIs} ${FIRST_NAME_LENGTH} ${LoginData.Characters}`;
    }
    if (!formData.lastName) {
      newErrors.lastName = LoginData.LastNameRequired;
    }
    if (formData.lastName?.length > LAST_NAME_LENGTH) {
      newErrors.lastName = `${LoginData.MaximumLengthIs} ${LAST_NAME_LENGTH} ${LoginData.Characters}`;
    }
    if (!formData.email.trim()) {
      newErrors.email = LoginData.EmailRequired;
    } else if (!/^\S+@\S+\.\S+$/.test(formData.email.trim())) {
      newErrors.email = LoginData.EmailNotValid;
    }
    if (!formData.password) {
      newErrors.password = LoginData.PasswordRequired;
    }
    if (formData.confirmPassword !== formData.password) {
      newErrors.confirmPassword = LoginData.PasswordsDoNotMatch;
    }

    setErrors(newErrors);
    return Object.values(newErrors).every((error) => error === '');
  };

  const handleSignup = async () => {
    const deviceInfo = {
      platform: osName,
      brand: browserName,
      deviceType: isMobile ? DeviceType.Mobile : isTablet ? DeviceType.Tablet : DeviceType.Desktop,
      systemVersion: osVersion,
      manufacturer: engineName,
    };
    const userForm = {
      email: formData?.email.trim(),
      password: formData?.password,
      userDetails: {
        firstName: formData?.firstName.trim(),
        lastName: formData?.lastName.trim()
      },
      deviceInfo: JSON.stringify(deviceInfo)
    }
    setIsLoading(true)
    const res = await fetchData(restAPIs.getSignUp(userForm), navigate);
    setIsLoading(false)
    const response = res.data
    if (res.status === 200) {
      dispatch(setLoginForm(userForm))
      setType(LoginFormKeys.OtpScreen)
    }
    else {
      setErrors({ message: response?.message, ...response?.fields })
    }
  };
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      handleSignup()
    }
  };

  return (
    <div className="signup-form-wrapper">
      {errors?.message && !errors?.password && !errors?.email &&
        < Alert className="login-alert" severity="error">{errors?.message}</Alert>}
      <form onSubmit={handleSubmit} style={{ marginBottom: '20px' }} className='login-form'>
        <img src={logo} className='login-classteam-logo' alt="" />
        <Typography variant="h1">{LoginData.CreateAccount}</Typography>
        <Typography fontWeight={400} variant="h6" style={{ marginBottom: '20px' }}>{LoginData.PleaseEnterDetails}</Typography>
        <TextField
          label={InputLabels.FirstName}
          fullWidth
          variant={InputVariants.Outlined}
          value={formData.firstName}
          error={!!errors.firstName}
          helperText={errors.firstName}
          onChange={handleChange}
          margin={InputMargins.Normal}
          name={InputNames.FirstName}
          className="input-field"
          InputLabelProps={{
            style: { fontSize: '13px', fontWeight: 400, color: colors.inputLabelColor },
          }}
        />
        <TextField
          label={InputLabels.LastName}
          name={InputNames.LastName}
          fullWidth
          error={!!errors.lastName}
          value={formData.lastName}
          helperText={errors.lastName}
          variant={InputVariants.Outlined}
          onChange={handleChange}
          margin={InputMargins.Normal}
          className="input-field"
          InputLabelProps={{
            style: { fontSize: '13px', fontWeight: 400, color: colors.inputLabelColor },
          }}
        />
        <TextField
          label={InputLabels.Email}
          fullWidth
          variant={InputVariants.Outlined}
          error={!!errors.email}
          helperText={errors.email}
          value={formData.email}
          onChange={handleChange}
          margin={InputMargins.Normal}
          name={InputNames.Email}
          className="input-field"
          InputLabelProps={{
            style: { fontSize: '13px', fontWeight: 400, color: colors.inputLabelColor },
          }}
        />
        <TextField
          label={InputLabels.Password}
          type={showPassword ? InputTypes.Text : InputTypes.Password}
          name={InputNames.Password}
          fullWidth
          error={!!errors.password}
          helperText={errors.password}
          value={formData.password}
          variant={InputVariants.Outlined}
          onChange={handleChange}
          margin={InputMargins.Normal}
          className="input-field"
          InputLabelProps={{
            style: { fontSize: '13px', fontWeight: 400, color: colors.inputLabelColor },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <div className="hide-eye-icon" onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ?
                    <VisibilityOff
                      sx={{
                        color: colors.inputLabelColor,
                        visibility: formData.password?.length > 0 ?
                          VisibilityTypes.Visible : VisibilityTypes.Hidden
                      }} /> :
                    <Visibility sx={{
                      color: colors.inputLabelColor,
                      visibility: formData.password?.length > 0 ? VisibilityTypes.Visible : VisibilityTypes.Hidden
                    }} />}
                </div>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          label={InputLabels.ConfirmPassword}
          type={showConfirmPassword ? InputTypes.Text : InputTypes.Password}
          name={InputNames.ConfirmPassword}
          fullWidth
          error={!!errors.confirmPassword}
          helperText={errors.confirmPassword}
          value={formData.confirmPassword}
          variant={InputVariants.Outlined}
          onChange={handleChange}
          margin={InputMargins.Normal}
          className="input-field"
          InputLabelProps={{
            style: { fontSize: '13px', fontWeight: 400, color: colors.inputLabelColor },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <div className="hide-eye-icon" onClick={() => setConfirmShowPassword(!showConfirmPassword)}>
                  {showConfirmPassword ?
                    <VisibilityOff sx={{
                      color: colors.inputLabelColor,
                      visibility: formData.confirmPassword?.length > 0 ?
                        VisibilityTypes.Visible : VisibilityTypes.Hidden
                    }} /> :
                    <Visibility sx={{
                      color: colors.inputLabelColor,
                      visibility: formData.confirmPassword?.length > 0 ? VisibilityTypes.Visible : VisibilityTypes.Hidden
                    }} />}
                </div>
              </InputAdornment>
            ),
          }}
        />
        <div className="signup_form_button">
          <PrimaryButton
            fullWidth={false}
            text={LoginData.SignUp}
            isLoading={isLoading}>
            {LoginData.SignUp}
          </PrimaryButton>
        </div>
      </form>
      <div className="login-signup-text">
        <Typography
          fontWeight={400}
          fontSize={'13px'}
          variant="h6">
          {LoginData.HaveAnAccount}
        </Typography>
        <Typography
          className="signup-text"
          variant="h4"
          style={{ cursor: CursorType.Pointer }}
          onClick={() => navigate(`/login`)} >
          {LoginData.LoginLwr}
        </Typography>
      </div>
    </div>
  );
}

export default SignUpForm;
