import React, { createContext, useContext, useState, ReactNode } from 'react';

interface Metadata {
    notes: {
        notesCount: number;
    };
    documents: {
        documentsCount: number;
    };
    subjects: {
        subjectsCount: number;
    };
    events: {
        eventsCount: number;
    };
    curriculum: {
        curriculumCount: number;
    };
    timeTablePresent: boolean;
}

interface MetadataContextType {
  metadata: Metadata | null;
  setMetadata: (metadata: Metadata) => void;
  isMetaDataLoading:boolean;
  setIsMetaDataLoading:React.Dispatch<React.SetStateAction<boolean>>
  isUpdateUserLoading:boolean;
  setIsUpdateUserLoading:React.Dispatch<React.SetStateAction<boolean>>
  toogleDrawer?:boolean;
  setToogleDrawer:React.Dispatch<React.SetStateAction<boolean>>
  notificationCount:number;
  setNotificationCount:React.Dispatch<React.SetStateAction<number>>
}

// Create the context
const MetadataContext = createContext<MetadataContextType | undefined>(undefined);

// Custom hook to use the context
export const useMetadataContext = () => {
  const context = useContext(MetadataContext);
  if (!context) {
    throw new Error('useMetadataContext must be used within a MetadataProvider');
  }
  return context;
};

// Provider component
export const MetadataProvider = ({ children }: { children: ReactNode }) => {
  const [metadata, setMetadata] = useState<Metadata | null>(null);
  const [isMetaDataLoading, setIsMetaDataLoading] = useState<boolean>(false);
  const [isUpdateUserLoading, setIsUpdateUserLoading] = useState<boolean>(false);
  const [toogleDrawer,setToogleDrawer]=useState<boolean>(false)
  const [notificationCount, setNotificationCount] = useState<number>(0);

  return (
    <MetadataContext.Provider value={{ metadata, setMetadata,isMetaDataLoading,setIsMetaDataLoading,isUpdateUserLoading,setIsUpdateUserLoading,toogleDrawer,setToogleDrawer,notificationCount,setNotificationCount }}>
      {children}
    </MetadataContext.Provider>
  );
};
