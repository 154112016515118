import { Area } from 'react-easy-crop/types';

export const getCroppedImg = (imageSrc: string, crop: Area, rotation = 0,selectedImage:File|null): Promise<File> => {
  const createImage = (url: string): Promise<HTMLImageElement> =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener('load', () => resolve(image));
      image.addEventListener('error', (error) => reject(error));
      image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid CORS issues on CodeSandbox
      image.src = url;
    });

  const getRadianAngle = (degreeValue: number) => (degreeValue * Math.PI) / 180;

  return createImage(imageSrc).then((image) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const maxSize = Math.max(image.width, image.height);
    const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

    canvas.width = safeArea;
    canvas.height = safeArea;

    if (ctx) {
      ctx.translate(safeArea / 2, safeArea / 2);
      ctx.rotate(getRadianAngle(rotation));
      ctx.translate(-safeArea / 2, -safeArea / 2);

      ctx.drawImage(
        image,
        safeArea / 2 - image.width * 0.5,
        safeArea / 2 - image.height * 0.5
      );

      const data = ctx.getImageData(0, 0, safeArea, safeArea);

      canvas.width = crop.width;
      canvas.height = crop.height;

      ctx.putImageData(
        data,
        Math.round(0 - safeArea / 2 + image.width * 0.5 - crop.x),
        Math.round(0 - safeArea / 2 + image.height * 0.5 - crop.y)
      );

      return new Promise<File>((resolve,reject) => {
        canvas.toBlob((blob) => {
          if (blob) {
            const file = new File([blob], selectedImage?.name??'cropped_image.jpeg', { type: 'image/jpeg' });
            resolve(file);
          } else {
            reject(new Error('Failed to create blob from canvas'));
          }
        }, 'image/jpeg');
      });
    }

    return Promise.reject(new Error('Failed to crop image'));
  });
};
