import axios from "axios";
import { PRE_SINGED_URL } from "utils/restAPIs";

interface fileUploadType {
  filePath: string;
  fileType: string;
  file: any;
}

// Function to handle file uploads
export async function uploadFile(
  props: fileUploadType
): Promise<string | boolean> {
  const { filePath, fileType, file } = props;

  try {
    // Prepare URL parameters
    const urlParamsForUpload = new URLSearchParams({
      path: filePath,
      contentType: fileType,
    });

    const urlForUpload = `${PRE_SINGED_URL}?${urlParamsForUpload.toString()}`;

    // Request for a signed URL
    const requestUploadUrl = await axios.get(urlForUpload, {
      headers: {
        Authorization: localStorage.getItem("sessiontoken"),
      },
    });

    const { signedUrl, downloadUrl } = requestUploadUrl?.data;

    if (signedUrl) {
      // Upload the file using the signed URL
      const uploadFile = await axios.put(signedUrl, file, {
        headers: {
          "Content-Type": fileType,
        },
      });

      if (uploadFile?.status === 200) {
        return downloadUrl;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } catch (error) {
    console.error("Error uploading file:", error);
    return false;
  }
}

export function readFileAsBinary(file: File): Promise<Blob> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.result instanceof ArrayBuffer) {
        const blob = new Blob([reader.result], { type: file.type });
        resolve(blob);
      } else {
        reject(new Error("Error reading file as binary."));
      }
    };
    reader.onerror = () => {
      reject(new Error("Error reading file."));
    };
    reader.readAsArrayBuffer(file);
  });
}
