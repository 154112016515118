export const colors = {
    primary :'#00B2F8',
    secondary :'#494D5C',
    tertiary :'#ABABAB',
    normal :'#707070',
    light :'#DDDDDD',
    white :'#FFFFFF',
    error :'rgba(255, 0, 0, .65)',
    modal :"#F7F8F9",
    secondaryBackground: '#F8F8F8',
    orangebutton:'#F28200',
    inputLabelColor:'#B4B4B4',
    createButtonColor:'#575757',
    timeTableColor:'#e2e2e2',
    timeTableBackground:'#00000014'
}