// Core
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Third-Party Libraries
import { Avatar, CircularProgress } from '@mui/material';

// Styles and Constants
import './index.scss';
import { ActivityList, SingleActivity } from './styles';
import { ProfileCard } from 'assets/styles/commonStyle';

// Utilities
import { restAPIs } from 'utils/restAPIs';
import { getTime, loadActiveClassroomLocalStorage } from 'utils/commonUtils';
import { CommonTypes, NOTIFICATION_MODULES, RecentActivitiesData } from 'utils/constants';
import { useCustomSWR } from 'utils/customHooks';
import { fetchData } from 'utils/fetch';

const RecentActivities: React.FC = () => {
    const activeClassroom = loadActiveClassroomLocalStorage()
    const [activities, setActivities] = useState<any[]>([]);
    const navigate = useNavigate();
    const [page, setPage] = useState(1);
    const [isLastPage, setIsLastPage] = useState(false);
    const scrollRef = useRef<HTMLDivElement>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true)

    const loadMore = async (initialPage?: boolean) => {
        if (!isLastPage || initialPage) {
            let params = {
                page: page,
                classRoomId: activeClassroom?._id,
                limit: 10
            };
            setIsLoading(true)
            const res = await fetchData(restAPIs.getNotificationList(params), navigate)
            setIsLoading(false)
            if (res?.statusCode === 200 && res?.data) {
                const temp = res.data?.notifications??[]
                setActivities(initialPage ? temp : activities.concat(temp))
                setIsLastPage(res.data.isLastPage)
                setPage(initialPage ? 2 : page + 1)
            }
        }
    }

    useEffect(() => {
        loadMore(true);
    }, []);


    useEffect(() => {
        const handleScroll = () => {

            if (scrollRef.current) {
                const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
                if (scrollTop + clientHeight >= scrollHeight - 5 && !isLoading && !isLastPage) {
                    loadMore();
                }
            }
        };

        const currentScrollRef = scrollRef.current;
        if (currentScrollRef) {
            currentScrollRef.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (currentScrollRef) {
                currentScrollRef.removeEventListener('scroll', handleScroll);
            }
        };
    }, [isLoading, isLastPage]);

    const onClick = (type: string, id: string, item: any) => {
        let refId = item && item.refId && item.refId;
        if (type === NOTIFICATION_MODULES.document) {
            navigate(`/documents/ref/${refId}`)
        }
        if (type === NOTIFICATION_MODULES.post) {
            navigate(`/feed/ref/${refId}`);
        }
        if (type === NOTIFICATION_MODULES.note) {
            navigate(`/notes/ref/${refId}`)
        }
        if (type === NOTIFICATION_MODULES.event) {
            navigate('/events')
        }
        if (type === NOTIFICATION_MODULES.curriculum) {
            if (id === activeClassroom?._id) {
                navigate('/curriculum')
            }
        }
        if (type === NOTIFICATION_MODULES.timetable) {
            if (id === activeClassroom?._id) {
                navigate('/timetable');
            }
        }
        if (type === NOTIFICATION_MODULES.subject) {
            if (id === activeClassroom?._id) {
                navigate('/subjects')
            }
        }
    };

    return (
        <div className='home text-left __recent' ref={scrollRef}>
            <ActivityList>
                {activities?.map((item: any, index: any) => {
                    return (
                        <div className='__list' key={index} onClick={() => onClick(item.module, item.classRoomId, item)}>
                            <SingleActivity key={index}>
                                <ProfileCard style={{ alignItems: 'center' }}>
                                    <Avatar sx={{ bgcolor: '#222', width: 34, height: 34, fontSize: '14px' }} aria-label="recipe" src={item?.triggeredBy?.profilePic}>
                                        {item?.triggeredByDetails?.firstName?.slice(0, 1)}{item?.triggeredByDetails?.lastName?.slice(0, 1)}
                                    </Avatar>
                                    <div className='___label'>
                                        {item?.module === CommonTypes.POST ?
                                            <h1 style={{ fontSize: '13px', }}>{item?.title}</h1> :
                                            <h1 style={{ fontSize: '13px' }}>{item?.triggeredByDetails?.firstName} {item?.triggeredByDetails?.lastName}</h1>}
                                        {item?.module === CommonTypes.POST && <p>{getTime(item?.createdAt)}</p>}
                                    </div>
                                </ProfileCard>
                                <h3 className='__title'>{item?.title}</h3>
                                <span className='__title'>{item?.module !== CommonTypes.POST && item?.description}</span>
                                <p>{item?.module !== CommonTypes.POST && <p>{getTime(item?.createdAt)}</p>}</p>
                            </SingleActivity>
                        </div>
                    );
                })}
                <div className='seprator'/>
                {isLoading && <div style={{ textAlign: 'center' }}>
                    <CircularProgress size={'20px'} color="primary" />
                </div>}
                {!isLoading && !activities?.length && <div className='__no_notification'>{RecentActivitiesData.NoNotifications}</div>}
            </ActivityList>
        </div>
    );
};

export default RecentActivities;
