import React, { useEffect, useState } from 'react';
import ModalView from '../ModalView';
import { IoIosClose } from "react-icons/io";
import './index.scss'
import { useDispatch } from 'react-redux';
import EventDatePicker from '../EventDatePicker';
import PrimaryButton from '../CustomButtons';
import { EVENT_TITLE } from 'utils/constants';
import { Typography } from '@mui/material';
import { fetchData } from "utils/fetch";
import { restAPIs } from "utils/restAPIs"
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import moment from 'moment-timezone';
import { useFetchClassRoomItems } from 'utils/customHooks';
import { useNavigate } from 'react-router-dom';
import { DateValidationError, TimeValidationError } from '@mui/x-date-pickers/models';
import { useSnackbar } from '../SnackbarProvider';
import { useMetadataContext } from 'contextApi/MetadataContext';
import { combineDateTimeToUTC, loadActiveClassroomLocalStorage, loadUserFromLocalStorage } from 'utils/commonUtils';

interface Props {
    handleClose?: () => void;
    open: any;
    modalStyle?: any;
    handleOpen?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    createType?: string;
    editItem?: any
    eventDetailModalClose?: () => void;
}
interface SubjectChange {
    target: {
        value: string;
    };
}

interface FormErrorState {
    name: string;
    description: string;
}

const initialerrors = {
    name: '',
    description: ''
}
interface EventForm {
    startDate: moment.Moment | any; 
    endDate: moment.Moment | any;
    startTime: moment.Moment | any;
    endTime: moment.Moment | any;
}

const CreateEventModal: React.FC<Props> = ({ open, handleClose, modalStyle, createType, editItem, eventDetailModalClose }) => {

    const initialEventForm = {
        startDate: dayjs(),
        endDate: dayjs().add(1, 'day'),
        startTime: dayjs(),
        endTime: dayjs().add(1, 'day')
    }

    const navigate = useNavigate()
    const dispatch = useDispatch();
    const { setToogleDrawer } = useMetadataContext();
    const { showSnackbar } = useSnackbar();
    const { fetchEvents, fetchTodaysEvents,fetchMetaData } = useFetchClassRoomItems()
    const activeClassroom = loadActiveClassroomLocalStorage()
    const user = loadUserFromLocalStorage();
    const [name, setName] = useState('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [description, setDescription] = useState('');
    const [errors, setErrors] = useState<FormErrorState>(initialerrors);
    const [notify, setNotify] = useState(true);
    const [dateError, setDateError] = React.useState<DateValidationError | null>(null);
    const [timeError, setTimeError] = React.useState<TimeValidationError | null>(null);
    const [eventForm, setEventForm] = useState<EventForm>(initialEventForm);

    const handleSubjectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target?.value?.length < EVENT_TITLE) {
            setName(event.target.value);
            setErrors(initialerrors)
        }
        else {
            let newErrors = { ...initialerrors };
            newErrors.name = 'Maximum length exceeds';
            setErrors(newErrors)
        }
    }

    useEffect(() => {
        if (open) {
            setEventForm({
                startDate: dayjs(),
                endDate: dayjs().add(1, 'day'),
                startTime: dayjs(),
                endTime: dayjs().add(1, 'day'),
            });
        }
    }, [open]);

    useEffect(() => {
        if (editItem) {
            dayjs.extend(utc);
            setName(editItem.name)
            const temp = {
                ...initialEventForm,
                startDate: dayjs(editItem?.startTime),
                startTime: dayjs(editItem?.startTime),
                endDate: dayjs(editItem?.endTime),
                endTime: dayjs(editItem?.endTime)

            }
            setEventForm(temp)
            setDescription(editItem.description)
        }
    }, [editItem]);

    const handleDescriptionChange = (event: SubjectChange) => {
        setDescription(event.target.value)
    };

    const onSuccess = () => {
        fetchTodaysEvents(activeClassroom?._id)
        fetchEvents(activeClassroom?._id)
        fetchMetaData()
        onClose?.()
        navigate('/events')
        showSnackbar(`${createType} ${editItem ? 'updated' : 'created'}`, 'success')
        if (editItem) {
            eventDetailModalClose?.()
        }
        setToogleDrawer(false)
    }

    const onSubmit = async () => {
        if (name?.trim()?.length > 0 && timeError == null && dateError == null) {
            setIsLoading(true)
            let params = {
                ...(!editItem && { classRoomId: activeClassroom?._id }),
                name: name.trim(),
                startTime: combineDateTimeToUTC(new Date(eventForm?.startDate),new Date( eventForm?.startTime)),
                endTime: combineDateTimeToUTC(new Date(eventForm?.endDate), new Date(eventForm?.endTime)),
                description:
                    description?.length > 0 ? description.trim() : '',
            };
            const res = editItem ? await fetchData(restAPIs.getupdateEvents(editItem._id, params),navigate) : await fetchData(restAPIs.getAddEvents(params),navigate)
            setIsLoading(false)
            if (res.statusCode === 200 && res.data) {
                onSuccess()
            }
            else {
                showSnackbar('something went wrong !', 'error')
            }
        }
        else {
            if (name?.trim()?.length == 0) {
                let newErrors = { ...initialerrors };
                newErrors.name = '* Required';
                setErrors(newErrors)
            }
        }
    }

    const onClose = () => {
        setEventForm(initialEventForm)
        setName('')
        setErrors(initialerrors)
        setDescription('')
        handleClose?.()
    }

    const RenderModal = () => (
        <div className="create-events-modal">
            <div className='__header'>
                <h4>{editItem ? 'Edit' : 'Add'} {createType}</h4>
                <button onClick={onClose}><IoIosClose /></button>
            </div>
            <div className="file-upload-container">
                <div className="input-group">
                    <label>Title</label>
                    <input value={name} type="text" onChange={handleSubjectChange} placeholder={`${createType} name`} />
                    <Typography sx={{ color: 'red' }} variant='subtitle2'>{errors?.name}</Typography>
                </div>
                <EventDatePicker
                    eventForm={eventForm}
                    setEventForm={setEventForm}
                    dateError={dateError}
                    setDateError={setDateError}
                    timeError={timeError}
                    setTimeError={setTimeError} />
                <div className="input-group">
                    <label>Description (optional)</label>
                    <textarea value={description} onChange={handleDescriptionChange} placeholder={'Description'} />
                    <Typography sx={{ color: 'red' }} variant='subtitle2'>{errors?.description}</Typography>
                </div>
            </div>
            <div className='modal-footer-button'>
                <PrimaryButton
                    onClick={onSubmit}
                    fullWidth={false}
                    isLoading={isLoading}>
                        {editItem ? 'Update' : 'Add'} {createType}
                </PrimaryButton>
            </div>
        </div>
    )

    return (
        <ModalView open={open} handleClose={onClose} modalStyle={modalStyle}>
            {RenderModal()}
        </ModalView>
    )
};

export default CreateEventModal;
