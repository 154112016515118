import React, { useEffect, useState } from 'react';
import './index.scss'
import { getFileType, getSize } from 'utils/commonUtils';
import { fileType } from 'utils/constants';

interface PostImagesProps {
    item?: any;
}
const style = { minWidth: '40px' }

const PostDoc: React.FC<PostImagesProps> = ({ item }) => {


    const [doc, setDoc] = useState<any>([]);

    useEffect(() => {
        let tempDocument: any[] = [];
        if (item?.length > 0) {
            item.forEach((e: any) => {
                if (!e.type?.startsWith('image/')) {
                    tempDocument.push(e);
                }
            });
            setDoc(tempDocument)
        }
        else {
            setDoc([])
        }
    }, [item]);

    function getImageName(note: any) {
        switch (getFileType(note)) {
            case 'PDF':
                return fileType[0];
            case 'Video':
                return fileType[1];
            case 'Document':
                return fileType[2];
            case 'Image':
                return fileType[3];
            case 'File':
                return fileType[4];
        }
        return fileType[4];
    }

    if (doc?.length == 0) {
        return null
    }

    return (
        <div className='post-doc-wrapper'>
            {doc.map((item: any) => {
                let iconComponent;
                switch (getImageName(item)) {


                    case fileType[0]:
                        iconComponent = <i className="fi fi-rr-file-pdf" style={{ fontSize: '27px', lineHeight: 1, color: '#555', marginRight: '7px' }}></i>;
                        break;
                    case fileType[1]:
                        iconComponent = <i className="fi fi-rr-file-video" style={{ fontSize: '27px', lineHeight: 1, color: '#555', marginRight: '7px' }}></i>;
                        break;
                    case fileType[2]:
                        iconComponent = <i className="fi fi-rr-file-word" style={{ fontSize: '27px', lineHeight: 1, color: '#555', marginRight: '7px' }}></i>;
                        break;
                    case fileType[3]:
                        iconComponent = <i className="fi fi-rr-file-image" style={{ fontSize: '27px', lineHeight: 1, color: '#555', marginRight: '7px' }}></i>;
                        break;
                    case fileType[4]:
                        iconComponent = <i className="fi fi-rr-file-word" style={{ fontSize: '27px', lineHeight: 1, color: '#555', marginRight: '7px' }}></i>;
                        break;
                }
                return (
                    <div onClick={() => window.open(item.url, '_blank')} className='post-doc-container'>
                        {iconComponent}
                        <div className='text-container'>
                            <div className='text'>
                                {item.name}
                            </div>
                            <div className='size-text'>
                                {getSize(item.size)}
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    );
};

export default PostDoc;
