import React, { useState, useCallback, useRef, useEffect } from 'react';
import {
    DialogContent,
    DialogActions,
    Grid,
    Slider,
} from '@mui/material';
import Cropper from 'react-easy-crop';
import { Area } from 'react-easy-crop/types';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import '../PhotoPicker/index.scss'
import PrimaryButton from '../CustomButtons';
import ModalView from '../ModalView';
import { POST_PATH } from 'utils/restAPIs';
import { loadActiveClassroomLocalStorage, loadUserFromLocalStorage } from 'utils/commonUtils';
import { readFileAsBinary, uploadFile } from 'utils/filesupload';
import { CropPickerProps } from 'utils/types';
import { aspectData } from 'utils/constants';

const CropPicker: React.FC<CropPickerProps> = ({ setImageFile, imageFile, editItem,files,selected,setSelected,setImg,img }) => {


    const userObject = loadUserFromLocalStorage();
    const user = userObject?.userDetails
    const activeClassroom = loadActiveClassroomLocalStorage()
    const [imageSrc, setImageSrc] = useState<string | null>(null);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [rotation, setRotation] = useState(0);
    const [croppedArea, setCroppedArea] = useState<Area | null>(null);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [aspect, setAspect] = useState(aspectData[1]);
    const aspectOpen = Boolean(anchorEl);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [percentage, setPercentage] = useState(0);

    const handleClose = (reset?:any) => {
        setCrop({ x: 0, y: 0 })
        setRotation(0)
        setZoom(1)
        setImageSrc(null);
        setSelected(null)
        setAspect(aspectData[1])
    };

    const getCroppedImg = async (imageSrc: string, crop: Area, rotation = 0): Promise<Blob> => {

        const image = await createImage(imageSrc);
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        if (!ctx) throw new Error('No 2d context');

        const { x, y, width, height } = crop;
        const radians = rotation * (Math.PI / 180);
        const sin = Math.sin(radians);
        const cos = Math.cos(radians);
        const rotatedWidth = Math.abs(width * cos) + Math.abs(height * sin);
        const rotatedHeight = Math.abs(width * sin) + Math.abs(height * cos);

        canvas.width = rotatedWidth;
        canvas.height = rotatedHeight;
        ctx.translate(rotatedWidth / 2, rotatedHeight / 2);
        ctx.rotate(radians);
        ctx.translate(-rotatedWidth / 2, -rotatedHeight / 2);

        ctx.drawImage(
            image,
            x, y, width, height,
            (rotatedWidth - width) / 2, (rotatedHeight - height) / 2, width, height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (blob) {
                    resolve(blob);
                } else {
                    reject(new Error('Canvas is empty'));
                }
            }, 'image/png'); 
        });
    };


    const createImage = (src: string): Promise<HTMLImageElement> => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => resolve(img);
            img.onerror = reject;
            img.src = src;
        });
    };

    const handleSave = async () => {
        if(selected!==null&&selected!==undefined){
        if (!imageSrc || !croppedArea) return;

        try {
            const croppedBlob = await getCroppedImg(imageSrc, croppedArea, rotation);
            const file = new File([croppedBlob], imageFile?.[selected]?.name, {
                type: "image/png",
                lastModified: new Date().getTime(),
            });
            let temp = [...imageFile]
            temp[selected] = file
            setImageFile(temp)
            const time = new Date();
            const path = `${POST_PATH}`
            .replace('{orgId}', user.organizationId)
            .replace('{classRoomId}', activeClassroom._id);
            
            const nameParts = file.name?.split('.');
            const extension = nameParts ? `.${nameParts[nameParts.length - 1]}` : '';
            const uniqueFilename = `${Math.random().toString(36).substring(7)}${time.getTime().toString()}${extension}`.replace(/[%\s]/g, '');
                        const fileType = file.type;
                        const filePath = `${path}${uniqueFilename}`;

                    const binaryBlob = await readFileAsBinary(file);
                    const downloadUrl = await uploadFile({ filePath, fileType, file: binaryBlob });
                    const postImageItem = {
                        url: downloadUrl,
                        uniqueFileName: uniqueFilename,
                        type: fileType,
                        size: file.size,
                        name: file.name,
                    };
                    
                    const tempImg=Array.from(img)
                    tempImg[selected]=postImageItem
                    setImg?.(tempImg)
            setAspect(aspectData[0])
            setRotation(0)
            setZoom(1)
            
        } catch (error) {
            console.error('Error cropping image:', error);
        }
    }
    };

    const onCropComplete = useCallback((croppedArea: Area, croppedAreaPixels: Area) => {
        setCroppedArea(croppedAreaPixels);
    }, []);

    useEffect(() => {
        if(selected!=null){
        if (imageFile?.[selected]) {
            if (!imageFile?.[selected].url) {
                const reader = new FileReader();
                reader.readAsDataURL(imageFile?.[selected]);
                reader.onload = () => {
                    setImageSrc(reader.result as string);
                };
            }
        }
        else
            setImageSrc(null)
    }
    }, [imageFile, selected]);


    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleAspectClose = () => {
        setAnchorEl(null);
    }

    const RenderModal = () => (
        <div className="create-photo-picker-modal">
            <DialogContent className='photo-picker-component'>
                {imageSrc &&
                    <div className='photo-picker-button-container'>
                        <div className='crop-button' onClick={handleClick}>{aspect.title}</div>
                    </div>}
                {imageSrc && (
                    <div style={{ position: 'relative', width: '100%', height: 250, marginTop: 20 }}>
                        {aspect.value !== 0 ?
                            <Cropper
                                image={imageSrc}
                                zoomWithScroll={false}
                                minZoom={-10}
                                crop={crop}
                                zoom={zoom}
                                aspect={aspect.value}
                                rotation={rotation}
                                onCropChange={setCrop}
                                onZoomChange={setZoom}
                                onRotationChange={setRotation}
                                onCropComplete={onCropComplete}
                            /> : <div className='photo-picker-img-container'><img className='photo-picker-orginal-img' src={imageSrc} /></div>}
                    </div>
                )}
                {imageSrc && (
                    <Grid container spacing={2} style={{ marginTop: 20 }}>
                        <Grid item xs={12}>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={aspectOpen}
                                onClose={handleAspectClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                PaperProps={{
                                    sx: {
                                        width: '15vw',
                                        minWidth: '180px',
                                        ml: 2,
                                        mt: -2,
                                        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)'
                                    },
                                }}
                            >
                                {aspectData?.map((item, index) => (
                                    <MenuItem onClick={() => {
                                        setAspect(item)
                                        handleAspectClose()
                                    }} className='menu-item'>
                                        {item.title == 'Crop' ? 'Orginal' : item.title}
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Grid>
                        {aspect.value !== 0 &&
                            <Grid item xs={6}>
                                <Slider
                                    value={zoom}
                                    min={1}
                                    max={3}
                                    step={0.1}
                                    aria-labelledby="Zoom"
                                    onChange={(e, zoom) => setZoom(zoom as number)}
                                />
                                <div>Zoom</div>
                            </Grid>}
                        {aspect.value !== 0 &&
                            <Grid item xs={6}>
                                <Slider
                                    value={rotation}
                                    min={0}
                                    max={360}
                                    step={1}
                                    aria-labelledby="Rotation"
                                    onChange={(e, rotation) => setRotation(rotation as number)}
                                />
                                <div>Rotation</div>
                            </Grid>}

                    </Grid>
                )}
            </DialogContent>
            <DialogActions>
                <div style={{ padding: '10px 20px' }}>
                    <a style={{ fontSize: '15px', cursor: 'pointer', marginRight: '10px' }} onClick={handleClose}>Cancel</a>
                    <PrimaryButton
                        fullWidth={false}
                        isLoading={isLoading}
                        progress={editItem ? percentage : null}
                        onClick={() => {
                            handleSave()
                            handleClose();
                        }}
                        color="primary"
                    >
                        <div style={{ minWidth: '60px' }}>Confirm</div>
                    </PrimaryButton>
                </div>
            </DialogActions>
        </div>
    )

    return (
        <div>
            <ModalView title='Crop' handleClose={handleClose} open={selected!=null&&selected!=undefined} modalStyle={{ padding: '0px' }}>
                {RenderModal()}
            </ModalView>
        </div>
    );
};

export default CropPicker;
