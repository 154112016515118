// Core
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Third-Party Libraries
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { InputAdornment, TextField } from '@mui/material';

// Components
import GridContainer from 'components/molecules/GridContainer';
import { useSnackbar } from 'components/atoms/SnackbarProvider';
import PrimaryButton from 'components/atoms/CustomButtons';

// Styles and Constants
import './index.scss'

// Utilities
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import { ChangePasswordFormErrorState, ChangePasswordFormState, ChangePasswordInitialError, ChangePasswordInitialForm } from 'utils/types';
import DeleteModal from 'components/atoms/DeleteModal';
import { MODAL_STYTLE } from 'utils/constants';
import CommonDeleteModal from 'components/atoms/CommonDeleteModal';

const ChangePassword = () => {

    const { showSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [formData, setFormData] = useState<ChangePasswordFormState>(ChangePasswordInitialForm);
    const [errors, setErrors] = useState<ChangePasswordFormErrorState>(ChangePasswordInitialError);
    const [isLoading, setIsLoading] = useState(false);
    const [showCurrentPassword, setShowCurrentPassword] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showConfirmPassword, setConfirmShowPassword] = useState<boolean>(false);
    const [openModal, setOpenModal] = useState(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const validateForm = (): boolean => {
        let newErrors = { ...ChangePasswordInitialError };
        if (!formData.password) {
            newErrors.currentPassword = 'Current Password is required';
        }
        if (!formData.password) {
            newErrors.password = 'Password is required';
        }
        if (formData.confirmPassword !== formData.password) {
            newErrors.confirmPassword = 'Passwords do not match';
        }

        setErrors(newErrors);
        return Object.values(newErrors).every((error) => error === '');
    };

    const handleSubmit = async () => {

        if (validateForm()) {
            const { currentPassword, password, confirmPassword } = formData || {};
            let params = {
                oldPassword: currentPassword?.trim(),
                newPassword: password.trim(),
            };
            setIsLoading(true)
            const res = await fetchData(restAPIs.getChangePassword(params), navigate);
            const response = res.data
            if (res.statusCode === 200) {
                setOpenModal(true)
                setIsLoading(false)
            }
            else {
                setIsLoading(false)
                if (!response?.fields) {
                    setErrors(prevErrors => ({
                        ...prevErrors,
                        currentPassword: response?.message
                    }));
                }
                else
                    setErrors({ message: response?.message, ...response?.fields })
            }

        }
    };

    const onClose = () => {
        setOpenModal(false)
        showSnackbar('Password changed successfully', 'success')
        setIsLoading(false)
        navigate(-1)
    }

    return (
        <GridContainer leftGridPartition={3 / 4} pageTitle={'Change Password'} backButton>
            <div style={{ marginTop: '0px' }}>
                <div style={{ padding: '0px 20px' }} className='__change_name'>
                    <TextField
                        label="Old Password"
                        type={showCurrentPassword ? 'text' : 'password'}
                        name="currentPassword"
                        fullWidth
                        error={!!errors.currentPassword}
                        helperText={errors.currentPassword}
                        value={formData.currentPassword}
                        variant="outlined"
                        onChange={handleChange}
                        margin="normal"
                        className="input-field"
                        InputLabelProps={{
                            style: { fontSize: '13px', fontWeight: 400, color: "#B4B4B4" },
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <div className="hide-eye-icon" onClick={() => setShowCurrentPassword(!showCurrentPassword)}>
                                        {showCurrentPassword ? <VisibilityOff sx={{ color: "#B4B4B4", visibility: formData.currentPassword?.length > 0 ? 'visible' : 'hidden' }} /> : <Visibility sx={{ color: "#B4B4B4", visibility: formData.currentPassword?.length > 0 ? 'visible' : 'hidden' }} />}
                                    </div>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <TextField
                        label="Password"
                        type={showPassword ? 'text' : 'password'}
                        name="password"
                        fullWidth
                        error={!!errors.password}
                        helperText={errors.password}
                        value={formData.password}
                        variant="outlined"
                        onChange={handleChange}
                        margin="normal"
                        className="input-field"
                        InputLabelProps={{
                            style: { fontSize: '13px', fontWeight: 400, color: "#B4B4B4" },
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <div className="hide-eye-icon" onClick={() => setShowPassword(!showPassword)}>
                                        {showPassword ? <VisibilityOff sx={{ color: "#B4B4B4", visibility: formData.password?.length > 0 ? 'visible' : 'hidden' }} /> : <Visibility sx={{ color: "#B4B4B4", visibility: formData.password?.length > 0 ? 'visible' : 'hidden' }} />}
                                    </div>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <TextField
                        label="Confirm Password"
                        type={showConfirmPassword ? 'text' : 'password'}
                        name="confirmPassword"
                        fullWidth
                        error={!!errors.confirmPassword}
                        helperText={errors.confirmPassword}
                        value={formData.confirmPassword}
                        variant="outlined"
                        onChange={handleChange}
                        margin="normal"
                        className="input-field"
                        InputLabelProps={{
                            style: { fontSize: '13px', fontWeight: 400, color: "#B4B4B4" },
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <div className="hide-eye-icon" onClick={() => setConfirmShowPassword(!showConfirmPassword)}>
                                        {showConfirmPassword ? <VisibilityOff sx={{ color: "#B4B4B4", visibility: formData.confirmPassword?.length > 0 ? 'visible' : 'hidden' }} /> : <Visibility sx={{ color: "#B4B4B4", visibility: formData.confirmPassword?.length > 0 ? 'visible' : 'hidden' }} />}
                                    </div>
                                </InputAdornment>
                            ),
                        }}
                    />

                    <div style={{ display: 'flex', justifyContent: 'end', marginTop: '20px' }}>
                        <PrimaryButton
                            onClick={handleSubmit}
                            fullWidth={false}
                            isLoading={isLoading}>
                            Update
                        </PrimaryButton>
                    </div>
                </div>
                <CommonDeleteModal
                    primaryButton
                    title='Password changed successfully'
                    description='Your Password changed successfully'
                    buttonLabel='Ok'
                    open={openModal}
                    modalStyle={MODAL_STYTLE}
                    handleClose={ onClose}
                />

            </div>
        </GridContainer>
    );
}

export default ChangePassword;