import React, { useEffect, useState } from 'react';
import './index.scss'
import { loadUserFromLocalStorage } from 'utils/commonUtils';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import { colors } from 'assets/styles/colors';
import Images from 'assets/image';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import { useNavigate } from 'react-router-dom';

interface Props {
    post: any;
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[200],
        ...theme.applyStyles('dark', {
            backgroundColor: theme.palette.grey[800],
        }),
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: colors.secondary,
        ...theme.applyStyles('dark', {
            backgroundColor: '#308fe8',
        }),
    },
}));

const PollCard: React.FC<Props> = ({ post }) => {

    const tempuserObject = loadUserFromLocalStorage();
    const user = tempuserObject?.userDetails
    const navigate = useNavigate()
    const { pollOptions = {}, _id: postId = '' } = post || {};
    const { options: poll_options = [], totalCount: count = 0 } = pollOptions || {};

    const [options, setOptions] = useState(poll_options);
    const [totalCount, setTotalCount] = useState(count);
    const [pollResult, setPollResult] = useState<any>({});
    const { data, isLoading = false } = pollResult || {};
    const { pollOptions: pollOptionsData = null, _id = null } = data || {};

    const isChecked = (voters: any) => {
        return voters.some((voter: any) => voter._id === user?._id);
    };

    useEffect(() => {
        const areArraysEqual =
            JSON.stringify(poll_options) === JSON.stringify(options);
        if (!areArraysEqual) {
            setOptions(poll_options);
            setTotalCount(count);
        }
    }, [poll_options]);

    useEffect(() => {
        if (pollOptionsData && _id === postId) {
            setOptions(pollOptionsData.options ?? []);
            setTotalCount(pollOptionsData.totalCount ?? 0);
        }
    }, [pollOptionsData]);

    const onPollDataFetch = async (postId: string, optionId: string) => {
        let params = {
            optionId: optionId,
        };
        const res = await fetchData(restAPIs.getPoll(postId, params), navigate)
        if (res?.data && res?.statusCode === 200) {
            setPollResult(res)
        }
    };

    const onPoll = (optionId: string) => {
        onPollDataFetch(postId, optionId);
        const userObject = { _id: user?._id, profilePic: user?.profilePic, firstName: user?.firstName, lastName: user?.lastName };
        const option = options.find((opt:any) => opt._id === optionId);
        const existingVote = options.find((opt:any) => opt.voters.some((voter:any) => voter._id === userObject._id));
    
        if (existingVote) {
            if (existingVote._id === optionId) {
                existingVote.voters = existingVote.voters.filter((voter:any) => voter._id !== userObject._id);
                existingVote.count -= 1;
                setTotalCount((prevTotal:number) => prevTotal - 1);
            } else {
                existingVote.voters = existingVote.voters.filter((voter:any) => voter._id !== userObject._id);
                existingVote.count -= 1;
                option.voters.push(userObject);
                option.count += 1;
            }
        } else {
            option.voters.push(userObject);
            option.count += 1;
            setTotalCount((prevTotal:number) => prevTotal + 1);
        }
    };
    return (
        <div className='poll_card'>
            <div className='poll_title'>{post.title}</div>
            {options?.map((option: any, index: any) => (
                <div onClick={() => onPoll(option._id)} key={index} className='poll_option_container'>
                    {isChecked(option.voters) ? (
                        <CheckCircleIcon style={{ color: colors.secondary }} />
                    ) : (
                        <PanoramaFishEyeIcon />
                    )
                    }
                    <div className='poll_option_wrapper'>
                        <div className='poll_option'>
                            <div>{option.value}</div>
                            <div className='poll_option_content'>
                                {option.voters.length > 3 && <div className='voter_extra_count'>+{option.voters.length > 12 ? 9 : option.voters.length - 3}</div>}
                                {option.voters.slice(0, 3).map((voter: any, index: number) => (
                                    <div key={voter._id} className='voter_img_container'>
                                        <img key={voter._id} src={voter.profilePic?.length > 0 ? voter.profilePic : Images.IconDefaultUser} className='class_logo' />
                                    </div>
                                ))}
                                <div className='voter_count'>{option.voters.length}</div>
                            </div>
                        </div>
                        <BorderLinearProgress variant="determinate" value={totalCount ? option.count / totalCount * 100 : 0} />
                    </div>
                </div>
            ))}
        </div>
    )
};

export default PollCard;