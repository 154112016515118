
import styled from "styled-components";
import { colors } from "assets/styles/colors";

export const SingleFeed = styled.div`
    width:auto;
    text-align:left;
    padding: 20px;
    opacity: 1;
    font-size:14px;
    border-bottom:1px solid #70707040;
`

export const WriteOnBoard = styled.div<any>`
    width:auto;
    height:80px;
    padding:20px;
    border:1px solid #70707040;
    text-align:left;
    margin:0 20px;
    margin-bottom: ${({ marginBottom }) => marginBottom || '10px'};
    padding-bottom: 10px;
    padding-right: 10px;
    font-size:14px;
    display:flex;
    flex-direction:column;
    justify-content:space-between;
    cursor:pointer;
    border-radius:10px;
    .__buttons{
        display:flex;
        align-items:center;
        justify-content:end;
        gap:10px;
        font-size:18px;
        padding-right:10px;
        button{
            background: #00b2f887;
            padding: 6px 15px;
            color: #fff;
            border: none;
            border-radius: 40px;
            font-size: 12px;
            font-weight: 600;
        }
    }

`

export const LikeCommentSec = styled.div`
    display:flex;
    align-items:center;
    gap:20px;
    line-height: 1;
    .__like{
        display:flex;
        align-items:center;
        gap:6px;
    }

`

export const WriteOnBoard2 = styled.div<any>`
    width:auto;
    height:80px;
    text-align:left;
     margin-bottom: ${({ marginBottom }) => marginBottom || '20px'};
    padding-bottom: 10px;
    padding-right: 10px;
    font-size:14px;
    display:flex;
    flex-direction:column;
    justify-content:space-between;

    .__buttons{
        display:flex;
        align-items:center;
        justify-content:end;
        gap:10px;
        font-size:18px;
        padding-right:10px;
        button{
            background: #00b2f887;
            padding: 6px 15px;
            color: #fff;
            border: none;
            border-radius: 40px;
            font-size: 12px;
            font-weight: 600;
        }
    }

`

export const WriteClassRoomDesc = styled.div<any>`
    width:auto;
    height:80px;
    background:${colors.secondaryBackground};
    border-radius:10px;
    margin-top:20px;
    padding:20px;
    text-align:left;
    margin-bottom: ${({ marginBottom }) => marginBottom || '20px'};
    padding-bottom: 10px;
    padding-right: 10px;
    font-size:14px;
    display:flex;
    flex-direction:column;
    justify-content:space-between;
    border:1px solid ${colors.secondary};
`
