import React from 'react';
import { Typography } from '@mui/material';
import './index.scss';
import { FaChevronRight } from "react-icons/fa6";
import NoNotes from '../NoNotes';
import { useNavigate } from 'react-router-dom';

interface SubjectsFilesProps {
    subjects?: any;
    type?: string
    getSections: (id: number) => number;
}

const SubjectsFiles: React.FC<SubjectsFilesProps> = ({ subjects, type = 'Notes', getSections }) => {

    const data = [
        `${subjects?.notesCount} ${subjects?.notesCount == 1 ? 'Note' : 'Notes'}`,
        `${subjects?.documentsCount} ${subjects?.documentsCount == 1 ? 'Document' : 'Documents'} `,
        `${subjects?.curriculumDetails?.sections?.length??0} ${subjects?.curriculumDetails?.sections?.length == 1 ? 'Session' : 'Sessions'}`]

    const navigate = useNavigate()
    
    const onClick = (item: any) => {
        switch (item) {
            case data[0]:
                navigate(`/notes/${subjects?._id}`)
                break;
            case data[1]:
                navigate(`/documents/${subjects?._id}`)
                return;
            case data[2]:
                navigate(`/curriculum/${subjects?.curriculumDetails?.subjectId}`)
                return;
        }
    }

    return (
        <div className='subjects-files-container note-files-wrapper'>
            <Typography variant='h3'>{subjects?.name}</Typography>
            {subjects && data?.map((item: any, index: number) => {
                return (
                    <div onClick={() => onClick(item)} className='subjects-files-content' key={index}>
                        <Typography variant='h4'>
                            {item}
                        </Typography>
                        <FaChevronRight />
                    </div>
                );
            })}
            {(subjects?.length == 0 || subjects == undefined) &&
                <NoNotes type={type} />}
        </div>
    );
};

export default SubjectsFiles;
