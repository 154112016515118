import React from 'react';
import { Popover } from '@mui/material';

interface Props {
    handleClose?: () => void;
    children: React.ReactNode;
    open: boolean;
    anchorEl: HTMLElement | null;
    modalStyle?: any;
}

const style = {
    maxHeight: '90vh',
    bgcolor: 'background.paper',
    border: '',
    boxShadow: '0px 3px 6px #0001',
    borderRadius: '25px',
    marginLeft: '-15px',
    p: 2,
};

const backdropStyle = {
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0)',
    zIndex: 1,
};

const PopoverView: React.FC<Props> = ({ handleClose, children, open, anchorEl, modalStyle }) => {
    return (
        <div>

            {open && <div style={{ ...backdropStyle, position: 'fixed' }} onClick={handleClose} />}
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
                PaperProps={{
                    style: { ...style, ...modalStyle },
                }}
            >
                {children}
            </Popover>
        </div>
    );
};

export default PopoverView;
