// Core
import React from 'react';
import { useNavigate } from 'react-router-dom';

// Components
import GridContainer from 'components/molecules/GridContainer';
import ListWrapper from 'components/molecules/ListWrapper';

// Third-Party Libraries
import {
  PeopleAltOutlined,
  SchoolOutlined,
  FilterNone,
  PermIdentity,
  ErrorOutlineOutlined,
  HelpOutlineRounded
} from '@mui/icons-material';

// Utilities
import { 
  accountSettings, 
  AccountSettingsArray, 
  AccountSettingsData, 
  AccountSettingsdata, 
  classroomSettings, 
  ReportAProblemData 
} from 'utils/constants';

//Styles and Constants
import './index.scss';

const Settings: React.FC = () => {

  const navigate = useNavigate();

  const getIcon = (item: string): JSX.Element => {
    const temp_Arr = [...classroomSettings, ...accountSettings]
    switch (item) {
      case temp_Arr[0]:
        return <PeopleAltOutlined className='Drawer-icon' sx={{ fontSize: 20 }} />;
      case temp_Arr[1]:
        return <SchoolOutlined className='Drawer-icon' sx={{ fontSize: 20 }} />;
      case temp_Arr[2]:
        return <FilterNone className='Drawer-icon' sx={{ fontSize: 20 }} />;
      case temp_Arr[3]:
        return <PermIdentity className='Drawer-icon' sx={{ fontSize: 20 }} />;
      case temp_Arr[4]:
        return <ErrorOutlineOutlined className='Drawer-icon' sx={{ fontSize: 20 }} />;
      case temp_Arr[5]:
        return <HelpOutlineRounded className='Drawer-icon' sx={{ fontSize: 20 }} />;
      default:
        return <div />;
    }
  }

  const convertString = (str: string): string => {
    if (!AccountSettingsArray.includes(str)) {
      return '/settings'
    }
    const formattedString = str.replace(/[^a-z0-9]+/gi, '').toLowerCase();
    return `/${formattedString}`;
  }

  const onClick = (setting: string) => {
    if (setting === AccountSettingsData.HelpSupport) {
      if (typeof window !== 'undefined' && window.open) {
        const newWindow = window.open(AccountSettingsData.Link, AccountSettingsData._blank);
        newWindow?.focus();
      }
    }
    else if (setting === ReportAProblemData.ReportProblem) {
      navigate('/report');
    }
    else {
      if (navigate && typeof navigate === 'function') {
        navigate(convertString(setting));
      }
    }
  };

  return (
    <GridContainer backButton pageTitle={AccountSettingsData.Settings} leftGridPartition={3 / 4}>
      <div className='__settings'>
        <ListWrapper data={AccountSettingsdata} getIcon={getIcon} onClick={onClick} />
      </div>
    </GridContainer>
  );
};

export default Settings;
