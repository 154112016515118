import { TextField } from "@mui/material";
import React, { useState } from "react";
import './index.scss'
import { SecondryButton } from "../CustomButtons";
import { colors } from "assets/styles/colors";
import { MdRemoveCircleOutline } from 'react-icons/md';

interface Props {
    inputVisible?: boolean;
    urls?:any;
    setUrls: React.Dispatch<React.SetStateAction<any>>;
    setInputVisible: React.Dispatch<React.SetStateAction<boolean>>
}

const URLInput: React.FC<Props> = ({ inputVisible,urls,setUrls,setInputVisible }) => {

    const [url, setUrl] = useState("");
    const [urlLoading, setUrlLoading] = useState(false);
    const [urlInvalid, setUrlInvalid] = useState(false);
    const [error, setError] = useState('')

    const validateUrl = (url: string): boolean => {
        const urlPattern = new RegExp(
            "^(https?:\\/\\/)?" +
            "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
            "((\\d{1,3}\\.){3}\\d{1,3}))" +
            "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
            "(\\?[;&a-z\\d%_.~+=-]*)?" +
            "(\\#[-a-z\\d_]*)?$",
            "i"
        );
        return !!urlPattern.test(url);
    };

    const checkUrlAvailability = (url:string) => {
        setUrlLoading(true);
        let temp_url = url.toLocaleLowerCase();
        fetch(temp_url,{
            mode: 'no-cors'
        })
          .then(res => {
            if (res.status == 404) {
              setUrlInvalid(true);
              setUrlLoading(false);
            } else {
              setUrlInvalid(false);
              setUrlLoading(false);
              const time = new Date();
              let temp_data = {
                title: '',
                url: temp_url,
                // id: `${Math.random().toString(36).substring(7)}${time
                //   .getTime()
                //   .toString()}`,
              };
              const temp=[...urls,temp_data]
              setUrls(temp);
              setInputVisible(false)
              setUrl("");
            }
          })
          .catch(() => {
            setUrlInvalid(true);
            setUrlLoading(false);
          });
      };

    const handleAddUrl = () => {
        setError('')
        if (validateUrl(url)) {
            checkUrlAvailability(url)
        } else {
            setError('Invalid URL')
        }
    };

    const handleDeleteUrl = (index: number) => {
        setUrls(urls.filter((_:any, i:number) => i !== index));
      };

    return (
        <div className="url-input-wrapper">
            {inputVisible && (
                <div className="url-input-container">
                    <TextField
                        label="Enter URL"
                        fullWidth
                        value={url}
                        variant="outlined"
                        onChange={(e) => setUrl(e.target.value)}
                        margin="none"
                        name="Enter URL"
                        className="input-field"
                        InputLabelProps={{
                            style: { fontSize: '12px',fontWeight: 400, color: "#B4B4B4" },
                        }}
                        error={!!error}
                        helperText={error}
                    />
                    
                    <SecondryButton fullWidth={false} onClick={handleAddUrl}><div className="button-text">Add URL</div></SecondryButton>
                </div>
            )}
           {urls && urls.length > 0 && <ul className="__list_urls">
                {urls.map((validUrl:any, index:number) => (
                    <li >
                        <div className="list-container">
                        <span key={index}><a href={validUrl.url} target="_blank" rel="noopener noreferrer" className="url-text">{validUrl.url}</a></span>
                        <MdRemoveCircleOutline style={{ cursor: 'pointer' }} color={colors.secondary} onClick={() => handleDeleteUrl(index)} size={15} />
                        </div>
                    </li>
                ))}
            </ul>}
        </div>
    );
};

export default URLInput;
