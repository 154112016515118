import React, { act, useEffect, useState } from 'react';
import { MentionsInput, Mention } from 'react-mentions';
import './index.scss';
import { loadActiveClassroomLocalStorage, loadUserFromLocalStorage } from 'utils/commonUtils';
import { restAPIs } from 'utils/restAPIs';
import { fetchData } from 'utils/fetch';

interface User {
  id: string;
  display: string;
}

interface Props {
  handlePostChange: (event: any) => void;
  post: string,
  handleMentionedUsers: (users: any) => void;
  setPost?: (post: string) => void;
  mentionedUsers?: any
}

const MentionPostTextArea: React.FC<Props> = ({ handlePostChange, post, handleMentionedUsers, setPost, mentionedUsers: mentionedUsersprops }) => {

  const [mentionedUsers, setMentionedUsers] = useState<any>([]);
  const activeClassroom = loadActiveClassroomLocalStorage();
  const [users, setUsers] = useState<any>([]);
  const [isBackspace, setIsBackspace] = useState(false);

  const onKeyDown = (event:any) => {
      if (event.key === 'Backspace') {
          setIsBackspace(true);
      }
  };

  useEffect(() => {
    handleSearchUsers('');
  }, []);


  const handleSearchUsers = async (query: string) => {
    const params={ name: query,classRoomId:activeClassroom?._id,page:1,limit:10 }
    const response = await fetchData(restAPIs.getClassroomUserSearch(params));
    const filteredUsers = Array.isArray(response?.data?.users)
  ? response.data.users.filter((user: any) =>
      `${user.firstName} ${user.lastName}`.toLowerCase().includes(query.toLowerCase())
    )
  : [];
    const userList = filteredUsers?.map((user: any) => ({
      id: user._id,
      display: `${user.firstName} ${user.lastName}`,
    }));
    setUsers(userList);
  };

  const onAdd = (id: string | number, display: string) => {
    const newUser = { _id: id.toString(), firstName: display.split(' ')[0], lastName: display.split(' ')[1] || '' };

    setMentionedUsers((prev: any) => {
      if (!prev.some((user: any) => user._id === newUser._id)) {
        const updatedUsers = [...prev, newUser];
        handleMentionedUsers(updatedUsers);
        return updatedUsers;
      }
      return prev;
    });
  };

  const onChange = (event: any, newValue: any, newPlainTextValue: any, mentions: any) => {
    const lastWord = newPlainTextValue.split(' ').pop();
    const mentionRegex = /@\[[^\]]+\]\([^\)]+\)(?!\s)/;
    const isMention = mentionRegex.test(newValue);
    if (isMention && isBackspace) {
        const displayNameMatch = newValue.match(mentionRegex);
        if (displayNameMatch) {
            const displayName = displayNameMatch[1]; 
            newValue = newValue.replace(mentionRegex, ''); 
        }
        setIsBackspace(false); 
    } else if (isMention && newValue.endsWith(' ')) {
        newValue = newValue.slice(0, -1); 
    } else if (isMention && !newValue.endsWith(' ')) {
        newValue += ' ';
    }

    handlePostChange({ target: { value: newValue } });

    const transformedMentions = mentions.map((mention: any) => {
        return {
            _id: mention.id.toString(),
            firstName: mention.display.split(' ')[0],
            lastName: mention.display.split(' ')[1],
        };
    });

    handleMentionedUsers(transformedMentions);
};


  const displayTransform = (id: string, display: string) => {
    return `@${display}`;
  };

  return (
    <div className="post-text-area-wrapper">
      <MentionsInput
        value={post}
        onKeyDown={onKeyDown}
        onChange={onChange}
        allowSpaceInQuery={false}
        placeholder="Write On Board…"
        style={mentionsInputStyle}
        className='___Ment'
      >
        <Mention
          trigger="@"
          data={users}
          displayTransform={displayTransform}
          appendSpaceOnAdd
          markup="@[__display__](__id__)"
          onAdd={onAdd}
          className="mentions__mention"
        />
      </MentionsInput>
    </div>
  );
};

export default MentionPostTextArea;

const mentionsInputStyle = {
  control: {
    fontSize: 16,
    lineHeight: 1.5,
    maxHeight:'70px',
    overflow:'auto',

  },
  suggestions: {
    top: '12px',
    transition: 'opacity 0.1s linear',
    list: {
      backgroundColor: 'transparent',
      border: '1px solid #eee',
      borderRadius: 6,
      fontSize: 14,
      overflow: 'auto',
      maxHeight: '100px',
    },
    item: {
      padding: '10px',
      cursor: 'pointer',

    },
  },
  highlighter: {
    overflow: 'hidden',
  },
};

