import React from 'react';
import ModalView from '../ModalView';
import { PiNotePencil } from "react-icons/pi";
import { MdOutlineCelebration } from "react-icons/md";
import { IoDocumentTextOutline } from "react-icons/io5";
import { TbNotes } from "react-icons/tb";
import '../../molecules/SideMenu/index.scss'

interface Props {
    handleClose?: () => void;
    open: any;
    modalStyle?: any;
    handleOpen?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    setCreateType: React.Dispatch<React.SetStateAction<any>>;
}

const data = [
    { title: 'Note', desc: 'Create note and share with your classmates' },
    { title: 'Document', desc: 'Collaborate with shared documents.' },
    { title: 'Events', desc: 'Organize events for your classroom.' }
]

const CreateButtonModal: React.FC<Props> = ({ open, handleClose, modalStyle, handleOpen, setCreateType }) => {

    const getIcon = (title: string) => {
        switch (title) {
            case 'Note':
                return <PiNotePencil fontSize={20} color='#3fa8f7'/>;
            case 'Document':
                return <TbNotes fontSize={20}  color='#FD509A'/>;
            case 'Subject':
                return <IoDocumentTextOutline fontSize={20}  color='#9556E8'/>;
            case 'Events':
                return <MdOutlineCelebration fontSize={20}  color='#976A9D'/>;
        }
    }


    const ItemCard = () => (
        <div>
            {data?.map((item,index) => (
                <div key={index} onClick={() => {
                    setCreateType(item?.title)
                    handleClose?.()
                }} className='__menu_single'>
                    {getIcon(item?.title)}
                    <div className='__label_sec'>
                        <h1>{item?.title}</h1>
                        <p>{item?.desc}</p>
                    </div>
                </div>
            ))}
        </div>
    )

    const RenderModal = () => (
        <div className='__custome_modal'>
            <div className='__modal_menu_list'>
                <ItemCard />
            </div>
        </div>
    )
    return (
        <div className='__create_btn'>
            <div>
                <button
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleOpen}
                    className='__custom_btn_create'>
                    <i className="fi fi-rr-plus"></i>
                    Create
                </button>
                <ModalView title='Create' backdrop={0} open={open} handleClose={handleClose} modalStyle={modalStyle}>
                    {RenderModal()}
                </ModalView>
            </div>
        </div>
    )
};

export default CreateButtonModal;
