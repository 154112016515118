// Core
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// Third-Party Libraries
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';

// Components
import GridContainer from 'components/molecules/GridContainer';
import EventFiles from 'components/atoms/EventFiles';
import NoNotes from 'components/atoms/NoNotes';
import { CreateButton } from 'components/atoms/CustomButtons';
import CreateEventModal from 'components/atoms/CreateEventModal';
import DeleteModal from 'components/atoms/DeleteModal';

// Styles and Constants
import './index.scss'
import 'react-big-calendar/lib/css/react-big-calendar.css';

// Utilities
import { loadActiveClassroomLocalStorage } from 'utils/commonUtils';
import { EventsData, MODAL_STYTLE, restrictModalData } from 'utils/constants';
import { CalendarEvent } from 'utils/types';
import { useCheckRole } from 'utils/customHooks';
import { useLocation } from 'react-router-dom';

const localizer = momentLocalizer(moment);

const MyCalendar: React.FC = () => {
    
    const location = useLocation();
  const notificationItem = location.state?.item;
  console.log('notificationItem',notificationItem)
    const { classroomData } = useSelector((state: any) => state.classRoom) || {};
    const {isInstructor}= useCheckRole()
    const eventsList = classroomData.events;
    const [events, setEvents] = useState(eventsList);
    const [todaysEvents, setTodaysEvents] = useState(classroomData.todaysEvents);
    const [modalData, setModal] = useState<any>(notificationItem??null);
    const [createType, setCreateType] = useState<any>('');
    const [event, setEvent] = useState<any>([]);
    const [isRestricted, setRestricted] = useState(false);
    const activeClassroom = loadActiveClassroomLocalStorage()

    const logEvents = (events: CalendarEvent[]) => {
        const eventData: { title: string | undefined; start: any; end: any; }[] = [];
        if(events?.length == 0) {
            setEvent([])
            return null 
        }
        events?.map(item => {
            const tempData = { 
                title: item?.name, start: moment.utc(item?.startTime)?.toDate(), 
                end: moment.utc(item?.endTime)?.toDate(), allDay: false 
            }
            eventData.push({ ...tempData, ...item })
            setEvent(eventData)
            return null;
        });
    };

    const getDateFormat = (date:any) => {
        let temp_date = '';
        let local_date = new Date(date);
        let temp_year = local_date.getFullYear();
        let temp_month =
          (local_date.getMonth() + 1 < 10 ? '0' : '') + (local_date.getMonth() + 1);
        let temp_day =
          (local_date.getDate() < 10 ? '0' : '') + local_date.getDate();
        temp_date = temp_year + temp_month + temp_day;
        return temp_date;
      };

      const getMarkedDate = () => {
        let temp_events = eventsList?.length > 0 ? [...eventsList] : [];
        let temp_todayEvent = classroomData?.todaysEvents?.length > 0 ? [...classroomData?.todaysEvents] : []; 
        let today = new Date();
        
        temp_events.forEach((item: any) => {
            let temp_start_date = getDateFormat(item.startTime);
            let temp_end_date = getDateFormat(item.endTime);
            let temp_selected_date = getDateFormat(today);
            
            if (
                parseInt(temp_start_date, 10) <= parseInt(temp_selected_date, 10) &&
                parseInt(temp_end_date, 10) >= parseInt(temp_selected_date, 10)
            ) {
                temp_todayEvent.push(item); 
            }
        });
        let filtered = Array.from(
            new Set(temp_todayEvent.map((item: any) => item._id))
        ).map(id => temp_todayEvent.find((item: any) => item._id === id));
        setTodaysEvents(filtered); 
    };

    // useEffect(() => {
    //     getMarkedDate();
    // }, [classroomData.todaysEvents]);

    useEffect(() => {
        setEvents(classroomData.events)
        getMarkedDate();
    }, [classroomData.events,classroomData.todaysEvents]);

    useEffect(() => {
        logEvents(events);
    }, [events,todaysEvents]);

    const handleSelectEvent = (event: any) => {
        setModal(event)
    };

    const onCreateNew = () => {
        if (activeClassroom?.memberPermissions?.academic || isInstructor()) {
            setCreateType(EventsData.Event)
        }
        else setRestricted(true)
    }

    return (
        <GridContainer
            rightOverflowY='scroll'
            pageTitle={EventsData.Events}
            leftGridPartition={events?.length == 0 ? 1 : 2 / 3}
            right={<EventFiles  event={events} setModal={setModal} todaysEvent={todaysEvents} type={EventsData.Events} />}>
            <div className='create-button-wrapper'>
            {isInstructor() &&
                <CreateButton bgColor={'#575757'} onClick={onCreateNew}>
                    {EventsData.CreateEvent}
                </CreateButton>}
            </div>
            <div className='events-calender'>
                <Calendar
                    localizer={localizer}
                    events={event}
                    startAccessor="start"
                    endAccessor="end"
                    style={{ height: 500 }}
                    onSelectEvent={handleSelectEvent}
                />
            </div>
            <CreateEventModal
                open={createType == EventsData.Event}
                modalStyle={MODAL_STYTLE}
                createType={createType}
                handleClose={() => setCreateType('')} />
            <DeleteModal
                open={isRestricted}
                onSubmit={() => setRestricted(false)}
                data={restrictModalData}
                modalStyle={MODAL_STYTLE}
                handleClose={() => setRestricted(false)}
            />
            <div className={'dropdown-menu'} >
                <EventFiles modalData={modalData} setModal={setModal} event={events} todaysEvent={todaysEvents} type={EventsData.Events} />
            </div>
            {events?.length == 0 &&
                <NoNotes type={EventsData.Events} />}
        </GridContainer>
    );
};

export default MyCalendar;
