//Core
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

//Third-Party Libraries
import { CircularProgress } from '@mui/material';

//Components
import CommentlModal from 'components/molecules/CommentModal';
import GridContainer from 'components/molecules/GridContainer';
import CreatePostEditModal from 'components/atoms/CreatePostEditModal';
import CommonDeleteModal from 'components/atoms/CommonDeleteModal';
import { useSnackbar } from 'components/atoms/SnackbarProvider';
import NoNotes from 'components/atoms/NoNotes';
import ClassSwitchInfoFooter from 'components/molecules/ClassSwitchInfoFooter';

//Utils
import { useCustomSWR } from 'utils/customHooks';
import { restAPIs } from 'utils/restAPIs';
import { fetchData } from 'utils/fetch';
import { determineClassroomStatus, loadActiveClassroomLocalStorage, loadUserFromLocalStorage } from 'utils/commonUtils';
import { CLASSROOM_STATUS, CommonTypes, DocumentDetailsDeleteModal, FeedDetailsData, SnackBarMessages, StatusType } from 'utils/constants';

const FeedDetails: React.FC = () => {

    const { refId = '' } = useParams()
    const { showSnackbar } = useSnackbar();
    const userObject = loadUserFromLocalStorage();
    const user = userObject?.userDetails
    const activeClassroom = loadActiveClassroomLocalStorage()
    const navigate = useNavigate();
    const [editOpen, setEditOpen] = useState<boolean>(false);
    const [deleteModal, setDeleteModal] = useState<boolean>(false)
    const [feed, setFeed] = useState<any>([])
    const [isDeleteLoading, setLoading] = useState(false);
    const [commentCount, setCount] = useState(0);

    const { data, isLoading, isError, mutate } = useCustomSWR(restAPIs.getFeedDetails(refId),[refId]);

    const post = feed?.[0]
    const title = post ? `${post?.userId?.firstName??post?.userDetails?.firstName}'s ${post.type==CommonTypes.POLL?'Poll':'Post'}` : FeedDetailsData.Post
    const onEdit = () => {
        setEditOpen(true)
    }


    useEffect(() => {
            setFeed(data?.statusCode == 200 ? [data?.data] : []);
            setCount(data?.data?.commentCount)

    }, [data]);

    const onDeleteItem = () => {
        setDeleteModal(true)
    }

    const onDelete = async () => {
        setLoading(true)
        const res = await fetchData(restAPIs.getDeletePost(post?._id), navigate)
        if (res?.data && res.statusCode == 200) {
            let temp = [...feed]
            temp.splice(0, 1);
            setFeed([...temp])
            showSnackbar(SnackBarMessages.PostDeletedSuccessfully, StatusType.success)
            setDeleteModal(false)
            mutate()
            navigate(-1)
        }
        else {
            showSnackbar(SnackBarMessages.SomethingWentWrong, StatusType.error)
        }
        setLoading(false)
    }

    return (
        <GridContainer
            backButton={true}
            pageTitle={title}
            onEdit={onEdit}
            onDelete={onDeleteItem}
            optionsVisible={post?.userId == user?._id}
            leftGridPartition={1}>

            {activeClassroom?._id != feed?.[0]?.classRoomId && !isLoading&&post&&
                <ClassSwitchInfoFooter data={feed?.[0]} status={CLASSROOM_STATUS.INACTIVE} />}
            {!isLoading ?
                <CommentlModal fromPostDetails commentCount={commentCount} setCount={setCount} item={post} /> :
                <div style={{ marginTop: '20px' }}><CircularProgress size={'20px'} color="primary" /></div>}
            <CreatePostEditModal
                open={editOpen}
                selectedIndex={0}
                feed={feed}
                setFeed={setFeed}
                editItem={post}
                handleClose={() => setEditOpen(false)}
                handleOpen={() => setEditOpen(true)} />
            <CommonDeleteModal
                open={deleteModal}
                isLoading={isDeleteLoading}
                description={DocumentDetailsDeleteModal.Desc}
                title={DocumentDetailsDeleteModal.DeletePost}
                handleClose={() => setDeleteModal(false)}
                handleSubmit={() => onDelete()}
            />
            {feed?.length == 0 && !isLoading &&
                <NoNotes type={FeedDetailsData.PostUnavailable} />}
        </GridContainer>
    );
};

export default FeedDetails;
