import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { API_VERSION, HOST } from './restAPIs';
import { useSelector } from 'react-redux';
interface Request {
  method: string;
  endpoint: string;
  body?: Record<string, any>;
}

type NavigateFunction = (path: string) => void;
export const fetchData = async (request: Request, navigate?: NavigateFunction): Promise<any> => {
  const { method, endpoint, body } = request;
  let sessiontoken = localStorage.getItem('sessiontoken');
  let refreshToken = localStorage.getItem('refreshToken');
  const requestModel: AxiosRequestConfig = {
    method,
    url: endpoint,
    data: body
  };
  if (sessiontoken)
    requestModel.headers = {
      Authorization: sessiontoken
    };
  try {
    const response: AxiosResponse<any> = await axios.request(requestModel);
    return response.data;
  } catch (e: any) {
    if (e?.response?.status === 401) {
      const response2: AxiosResponse<any> = await axios.get(`${HOST}user/${API_VERSION}/refresh-token?refreshToken=${refreshToken}`);

      if (response2.status === 200) {
        localStorage.setItem('sessiontoken', response2.data.token);
      }
      // Perform Logout
      else if (response2.status === 401) {
        localStorage.clear();
        navigate?.('/login/reset'); // Navigate to the login page
      } else {
        console.log('Error in refreshing token');
      }
    }
    else if(e?.response?.status === 403){
      navigate?.('/home');
    }

    return {
      status: e.response?.status,
      data: e.response?.data
    }
  }
};