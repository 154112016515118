import React, { useEffect, useRef, useState } from 'react';
import './index.scss'
import ModalView from '../ModalView';
import PrimaryButton from '../CustomButtons';
import { IoIosClose } from "react-icons/io";
import { fetchData } from "utils/fetch";
import { HOST, POST_PATH, restAPIs } from "utils/restAPIs"
import { useSnackbar } from '../SnackbarProvider';
import { WriteOnBoard2 } from '../../../pages/Feed/styles';
import PhotoPicker from '../PhotoPicker';
import URLInput from '../URLInput';
import AttachFiles from '../AttachFiles';
import { Badge } from '@mui/material';
import axios from 'axios';
import CommonDeleteModal from '../CommonDeleteModal';
import PostPhotoScroller from '../PostPhotoScroller';
import { useNavigate } from 'react-router-dom';
import { loadActiveClassroomLocalStorage, loadUserFromLocalStorage } from 'utils/commonUtils';
import MentionPostTextArea from '../MentionPostTextArea';
import _ from 'lodash';
import { readFileAsBinary, uploadFile } from 'utils/filesupload';
import { CommonTypes } from 'utils/constants';

interface Props {
  handleClose?: () => void;
  open: any;
  modalStyle?: any;
  handleOpen?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  createType?: string;
  editItem?: any;
  setFeed?: React.Dispatch<React.SetStateAction<any>>
  feed?: any;
  selectedIndex: number | null
}

interface FileItem {
  file: File;
  type: string;
}

interface SubjectChange {
  target: {
    value: string;
  };
}

const CreatePostEditModal: React.FC<Props> = ({ open, handleClose, editItem, setFeed, feed, selectedIndex }) => {

  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [post, setPost] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const activeClassroom = loadActiveClassroomLocalStorage()
  const user = loadUserFromLocalStorage();
  const [imageFile, setImageFile] = useState<any>([]);
  const [percentage, setPercentage] = useState(0);
  const [inputVisible, setInputVisible] = useState<boolean>(false);
  const [files, setFiles] = useState<FileItem[]>([])
  const [urls, setUrls] = useState<string[]>([]);
  const [deleteModal, setDeleteModal] = useState<boolean>(false)
  const fileAttachmentRef = useRef<HTMLInputElement>(null);
  const [mentionedUsers, setMentionedUsers] = useState<any>([]);

  const path = `${POST_PATH}`
    .replace('{orgId}', user.organizationId)
    .replace('{classRoomId}', activeClassroom._id);

  const handlePostChange = (event: SubjectChange) => {
    setPost(event.target.value);
    setError('')
  }

  const replaceMentions = (content: string, tagged: { _id: string; firstName: string; lastName: string }[]) => {
    let modifiedContent = content;

    tagged.forEach(user => {
      const fullName = `${user.firstName} ${user.lastName}`;
      const mentionString = `@[${fullName}](${user._id})`; // Format for replacement
      const regex = new RegExp(fullName, 'g'); // Create a regex to match the full name globally
      modifiedContent = modifiedContent.replace(regex, mentionString); // Replace occurrences
    });

    return modifiedContent;
  };

  useEffect(() => {
    if (editItem) {
      setUrls(editItem.externalInfo)

      if (editItem.tagged) {
        setMentionedUsers(editItem.tagged)
        const updatedContent = replaceMentions(editItem.content, editItem.tagged);
        setPost(updatedContent)
      }
      else
        setPost(editItem.content)
      let tempImage: any[] = [];
      let tempDoc: any[] = [];
      if (editItem?.documents?.length > 0) {
        editItem?.documents?.forEach(async (e: any) => {
          if (e.type?.startsWith('image/')) {
            tempImage.push(e);
          }
          else {
            tempDoc.push(e)
          }
        });
        setImageFile(tempImage)
        setFiles(tempDoc)
      }
      else{
        setImageFile([])
        setFiles([])
      }
    }
  }, [editItem]);

  const onSubmit = async () => {
    const filesArray = files.map(obj => obj.file ?? obj);
    const uploadedData = [...imageFile, ...filesArray]
    setIsLoading(true);

    let tempFiles = [];
    if (uploadedData?.length > 0) {
      const time = new Date();
      const filesArray = Array.from(uploadedData);
      for (let index = 0; index < filesArray.length; index++) {
        const f = filesArray[index];
        setPercentage(index / filesArray.length * 100)
        if (tempFiles.length + files?.length < 10) {
          if (f.url) {
            const postImageItem = {
              url: f.url,
              uniqueFileName: f.uniqueFileName,
              type: f.type,
              size: f.size,
              name: f.name,
            };
            tempFiles.push(postImageItem);
          }
          else {
            const nameParts = f.name?.split('.');
            const extension = nameParts ? `.${nameParts[nameParts.length - 1]}` : '';
            const uniqueFilename = `${Math.random().toString(36).substring(7)}${time.getTime().toString()}${extension}`.replace(/[%\s]/g, '');
            const fileType = f.type;
            const filePath = `${path}${uniqueFilename}`;
            const binaryBlob = await readFileAsBinary(f);
            const downloadUrl = await uploadFile({ filePath, fileType, file: binaryBlob });
            const postImageItem = {
              url: downloadUrl,
              uniqueFileName: uniqueFilename,
              type: fileType,
              size: f.size,
              name: f.name,
            };
            tempFiles.push(postImageItem);
          }
        } else {
          showSnackbar('Max limit of 10 files exceeded.', 'warning')
        }
      };
    }
    let params: any = {};
    tempFiles.forEach(file => {
      params.documents = {
        url: file.url,
        type: file.type,
        name: file.name,
        size: file.size,
        uniqueFileName: file.uniqueFileName,
      };
    });
    params.content = post.replace(/@\[(.*?)\]\(.*?\)/g, '$1');
    params.tagged = mentionedUsers?.map((user: any) => user._id);
    params.documents = tempFiles || [];
    params.externalInfo = urls || [];
    const res = await fetchData(restAPIs.getEditPost(editItem?._id, params), navigate)
    setIsLoading(false);
    onClose()
    if (res.statusCode == 200 && res.data && selectedIndex != null) {
      let temp_res = res.data?.post ?? []
      let temp = feed
      temp[selectedIndex] = temp_res
      setFeed?.(temp)
      showSnackbar('Post Updated', 'success')
    }
    else {
      showSnackbar('Something went wrong', 'error')
    }
  };

  const onClose = () => {
    handleClose?.()
    setPost('')
    setError('')
    setFiles([])
    setImageFile([])
    setDeleteModal(false)
  }

  async function onUpload() {
    const filesArray = files.map(obj => obj.file ?? obj);
    const uploadedData = [...imageFile, ...filesArray]
    setPercentage(0)
    if (uploadedData?.length > 0) {
      setIsLoading(true);
      let uploadedData = imageFile;
      for (var i = 0; i < imageFile.length; i++) {
        if (!imageFile[i]?.url) {
          let id = activeClassroom?._id;
          let formData = new FormData();
          formData.append('file', imageFile[i]);
          const config = {
            headers: {
              Authorization: localStorage.getItem('sessiontoken'),
            },
            onUploadProgress: (progressEvent: any) => {
              if (progressEvent.loaded > 100) {
                setPercentage(
                  (i / imageFile.length +
                    progressEvent.loaded /
                    progressEvent.total /
                    imageFile.length) *
                  99,
                );
              }
            },
          };
          await axios
            .post(HOST + 'misc/s3-upload?path=' + `classroom/${id}/post/${imageFile[i].name}`, formData, config)
            .then((response: any) => {
              if (response?.data?.status == 200 && response?.data?.data != null) {
                let temp = [...imageFile]
                temp[i].temp_url = `classroom/${id}/post/${imageFile[i].name}`;
                setImageFile(temp)
              } else {
                showSnackbar(response?.message ?? 'Something went wrong. Please try later', 'error')
              }
            })
            .catch((err: any) => {
              showSnackbar(err?.response?.data?.message
                ? err?.response?.data?.message
                : 'Something went wrong. Please try later', 'error')
            });
        }
      }
      if (i == imageFile.length) {
        onSubmit()
      }
    }
    else {
      onSubmit()
    }
  }

  const handleMentionedUsers = (users: any) => {
    setMentionedUsers((prev: any) => {
      const uniqueArray1 = _.uniqBy(prev, '_id');
      const uniqueArray2 = _.uniqBy(users, 'id');
      const updatedArray = _.map(uniqueArray2, (item) => _.mapKeys(item, (value, key) => key === 'id' ? '_id' : key));
      // @ts-ignore
      const updatedUsers = _.unionBy(uniqueArray1, updatedArray, '_id');
      return updatedUsers;
    });
  };


  const RenderModal = () => (
    <div className="create-post-modal">
      <div className='__header'>
        <h4>{editItem ? 'Edit' : 'Create'} Post</h4>
        <button onClick={() => setDeleteModal(true)}><IoIosClose /></button>
      </div>
      <div className='__oute_body_wrap'>
        <div className='body'>
          <WriteOnBoard2>
            <MentionPostTextArea
              mentionedUsers={mentionedUsers}
              handlePostChange={handlePostChange}
              setPost={setPost}
              post={`${post}`}
              handleMentionedUsers={handleMentionedUsers} />
          </WriteOnBoard2>

          {imageFile?.length > 0 &&
            <PostPhotoScroller editItem={editItem} setImageFile={setImageFile} imageFile={imageFile} />
          }
        </div>
        <URLInput
          urls={urls}
          setUrls={setUrls}
          inputVisible={inputVisible}
          setInputVisible={setInputVisible} />
        <AttachFiles
          fileInputRef={fileAttachmentRef}
          editItem={editItem}
          setFiles={setFiles}
          imageFile={imageFile}
          files={files} />
      </div>
      <div className='__buttons2'>
        <PhotoPicker
          files={files}
          imageFile={imageFile}
          setImageFile={setImageFile} />
        <i onClick={() => setInputVisible(!inputVisible)} className="fi fi-rr-link"></i>
        <Badge
          sx={{
            '& .MuiBadge-badge': {
              color: '#fff',
              fontWeight: 400
            },
          }}
          onClick={() => fileAttachmentRef.current?.click()}
          badgeContent={files?.length ? files?.length > 9 ? '9+' : files?.length : 0}
          color="primary">
          <i className="fi fi-rr-clip"></i>
        </Badge>
        <PrimaryButton
          fullWidth={false}
          disabled={post?.length === 0 &&
            imageFile?.length == 0 &&
            files?.length === 0 &&
            urls?.length === 0}
          progress={percentage}
          isLoading={isLoading} onClick={onUpload}>{CommonTypes.POST}</PrimaryButton>
      </div>
    </div>
  )
  return (
    <ModalView open={open} modalStyle={{ padding: 0 }}>
      {RenderModal()}
      <CommonDeleteModal
        open={deleteModal}
        buttonLabel={'Yes, Discard & close'}
        description={`This will discard all your changes. Confirm discard?`}
        title={'Discard Changes'}
        handleClose={() => setDeleteModal(false)}
        handleSubmit={() => onClose()}
      />
    </ModalView>
  );
};

export default CreatePostEditModal;