// Core
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// Third-Party Libraries
import CircularProgress from '@mui/material/CircularProgress';
import _ from 'lodash';
import Tooltip from '@mui/material/Tooltip';
import { Typography } from '@mui/material';

// Styles and Constants
import { colors } from 'assets/styles/colors';
import './index.scss'

// Utilities
import { determineClassroomStatus, loadActiveClassroomLocalStorage, loadUserFromLocalStorage } from 'utils/commonUtils';
import { fetchData } from "utils/fetch";
import { restAPIs } from "utils/restAPIs"
import { useFetchClassRoomItems } from 'utils/customHooks';

// Components
import GridContainer from 'components/molecules/GridContainer';
import NoteFiles from 'components/atoms/NoteFiles';
import NoNotes from 'components/atoms/NoNotes';
import CreateNoteModal from 'components/atoms/CreateNoteModal';
import { useSnackbar } from 'components/atoms/SnackbarProvider';
import CommonDeleteModal from 'components/atoms/CommonDeleteModal';
import ClassSwitchInfoFooter from 'components/molecules/ClassSwitchInfoFooter';
import UserCard from 'components/atoms/UserCard';
import { CLASSROOM_STATUS, DocumentDetailsData, DocumentDetailsDeleteModal, MODAL_STYTLE, SnackBarMessages, StatusType } from 'utils/constants';

const Documents: React.FC = () => {

    const { showSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const user = loadUserFromLocalStorage();
    const [selected, setSelected] = useState<number>(0)
    const [documentList, setDocumentList] = useState<any>([]);
    const { subject, refId = '' } = useParams()
    const activeClassroom = loadActiveClassroomLocalStorage()
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [createType, setCreateType] = useState<any>('');
    const [editItem, setEditItem] = useState<any>(null);
    const [deleteModal, setDeleteModal] = useState<boolean>(false)
    const { fetchDocuments, fetchMetaData } = useFetchClassRoomItems()

    const isOwned = user?._id == documentList?.[0]?.userId?._id || user?._id == documentList?.[0]?.createdBy?._id

    const loadMore = async (initialPage?: boolean) => {
        setIsLoading(true)
        const res = await fetchData(restAPIs.getDocumentItem(refId), navigate)
        setIsLoading(false)
        if (res?.statusCode === 200 && res?.data) {
            if (refId) {
                setDocumentList([res.data])
            }
        }
    }

    useEffect(() => {
        loadMore()
    }, [])

    const onEdit = () => {
        setCreateType(DocumentDetailsData.Document)
    }

    useEffect(() => {
        setEditItem(documentList[selected])
    }, [documentList])

    const onDeleteItem = () => {
        setDeleteModal(true)
    }

    const onDelete = async () => {
        setIsLoading(true)
        setDeleteModal(false)
        const res = await fetchData(restAPIs.getDocumentsDelete(documentList?.[selected]?._id), navigate)
        setIsLoading(false)
        if (res.status == 200) {
            showSnackbar(SnackBarMessages.DocumentDeletedSuccessfully, StatusType.success)
            let temp = [...documentList]
            temp.splice(selected, 1)
            setDocumentList(temp)
            fetchDocuments()
            fetchMetaData()
            navigate(-1)
        }
        else {
            showSnackbar(SnackBarMessages.SomethingWentWrong, StatusType.error)
        }
    }

    return (
        <GridContainer
            pageTitle={_.capitalize(documentList?.[0]?.title)}
            data={documentList}
            backButton={true}
            onEdit={onEdit}
            onDelete={onDeleteItem}
            optionsVisible={isOwned}
            leftGridPartition={1}>
            {activeClassroom?._id != documentList?.[0]?.classRoomId &&!isLoading&&
                <ClassSwitchInfoFooter data={documentList?.[0]} status={CLASSROOM_STATUS.INACTIVE} />}
            {isLoading ?
                <div className='loader-container document_detail_loader'>
                    <CircularProgress size={15} sx={{ color: colors?.primary }} />
                </div> :
                <div className='document_detail_wrapper'>
                    {documentList?.map((item: any, index: number) => {
                        const fileCount = item?.files?.length
                        return (
                            <div onClick={() => setSelected(index)} className={`notes-container fullscreen`}>
                                <Typography variant='h6' fontWeight={400} style={{ wordWrap: 'break-word' }} fontSize={'12px'} key={index}>
                                    {item.description}
                                </Typography>
                                {item?.subjectId?.name?.length > 0 && <div>{DocumentDetailsData.Subject}</div>}
                                <div className='notes-sub-container'>
                                    {item?.subjectId?.name?.length > 0 &&
                                        <Tooltip title={item?.subjectId?.name}>
                                            <div className='notes-sub-name'>
                                                {item?.subjectId?.name}
                                            </div></Tooltip>}
                                </div>
                                <div>{DocumentDetailsData.CreatedBy}</div>
                                <div className="notes-user-card-wrapper">
                                    <UserCard height={32} width={32} item={item} h1FontSize={'11px'} pFontSize={'10px'} />
                                </div>
                                <div className='notes-count-name-text'>
                                    {fileCount} {fileCount == 1 ? DocumentDetailsData.File : DocumentDetailsData.Files}
                                </div>
                                <div  >
                                    <NoteFiles fullScreen={true} detailRefresh={loadMore} notes={documentList[selected]} setNotes={setDocumentList} selected={selected} notesArray={documentList} type='Documents' />
                                </div>
                            </div>
                        );
                    })}
                    <CreateNoteModal
                        open={createType == DocumentDetailsData.Document}
                        editItem={editItem}
                        createType={createType}
                        modalStyle={MODAL_STYTLE}
                        detailRefresh={loadMore}
                        handleClose={() => setCreateType('')} />
                    <CommonDeleteModal
                        open={deleteModal}
                        buttonLabel={DocumentDetailsDeleteModal.YesDelete}
                        description={DocumentDetailsDeleteModal.Desc}
                        title={DocumentDetailsDeleteModal.Title}
                        handleClose={() => setDeleteModal(false)}
                        handleSubmit={() => onDelete()}
                    />
                    {documentList?.length == 0 && !isLoading &&
                        <NoNotes type={DocumentDetailsData.DocumentsUnavailable} />}
                </div>}
        </GridContainer>
    )
};

export default Documents;
