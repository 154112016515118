// Core
import React, { useState, useMemo, memo } from 'react';

// Third-Party Libraries
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Box } from '@mui/material';

// Utilities
import { loadActiveClassroomLocalStorage } from 'utils/commonUtils';
import { TabPanelProps } from 'utils/types';
import { NotificationsData } from 'utils/constants';

// Styles and Constants
import { colors } from 'assets/styles/colors';
import './index.scss';

// Components
import RecentActivities from "../RecentActivities";
import AllNotifications from "../AllNotifications";

const CustomTabPanel = memo(({ children, value, index }: TabPanelProps) => {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
        >
            {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
        </div>
    );
});

function Notifications() {
    const activeClassroom = useMemo(() => loadActiveClassroomLocalStorage(), []);
    const [value, setValue] = useState(0);

    const data = useMemo(() => [
        { name: NotificationsData.All },
        { name: `${activeClassroom?.name}` }
    ], [activeClassroom]);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const a11yProps = (index: number) => ({
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    });

    return (
        <div className='notifications_wrapper'>
            <h4 className='__common_head notifications_title'>{NotificationsData.Notifications}</h4>
            <Tabs
                sx={{
                    '& .MuiTabs-indicator': { backgroundColor: colors?.primary }
                }}
                value={value}
                variant="scrollable"
                scrollButtons="auto"
                onChange={handleChange}
                aria-label="basic tabs example"
                className='__tabs'
            >
                {data?.map((label: any, index: number) => (
                    <Tab wrapped label={label?.name} {...a11yProps(index)} key={index} />
                ))}
            </Tabs>
            <CustomTabPanel value={value} index={0}>
                <AllNotifications />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <RecentActivities />
            </CustomTabPanel>
        </div>
    );
}

export default memo(Notifications);
