import React, { useEffect, useState } from 'react';
import Drawer from '@mui/material/Drawer';
import { useMetadataContext } from 'contextApi/MetadataContext';


interface Props {
    onClose: React.Dispatch<React.SetStateAction<boolean>>;
    children?: React.ReactNode;
}

const DrawerContainer: React.FC<Props> = ({ onClose, children }) => {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const { toogleDrawer } = useMetadataContext();

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        windowWidth < 900 ?
            <Drawer onClose={() => onClose(false)} open={toogleDrawer}>
                {children}
            </Drawer> :
            <>{children}</>
    );
};


export default DrawerContainer


