// src/components/CustomImagePicker.tsx

import React, { useState, useCallback, useEffect } from 'react';
import { Modal, Button, Slider } from '@mui/material';
import Cropper from 'react-easy-crop';
import { Area } from 'react-easy-crop/types';
import { getCroppedImg } from 'utils/cropImage';
import './index.scss'
import ModalView from '../ModalView';
import { MdOutlineDeleteOutline } from "react-icons/md";


interface CustomImagePickerProps {
    setSelectedImage: (image: File | null) => void;
    children: React.ReactNode;
    id?: any;
    onS3Upload?: any
    imageType?: string;
    photoDesc?: any
}

const CustomImagePicker: React.FC<CustomImagePickerProps> = ({ setSelectedImage, children, id, onS3Upload, imageType, photoDesc }) => {
    const [selectedImage, setInternalSelectedImage] = useState<File | null>(null);
    const [preview, setPreview] = useState<string | null>(null);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(0);
    const [rotation, setRotation] = useState(0);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);
    const [open, setOpen] = useState(false);
    const [croppedImage, setCroppedImage] = useState<string | null>(null);

    const onCropComplete = useCallback((croppedArea: Area, croppedAreaPixels: Area) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    // const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     const file = event.target.files?.[0];
    //     if (file) {
    //         setInternalSelectedImage(file);
    //         const reader = new FileReader();
    //         reader.onloadend = () => {
    //             const image = new Image();
    //             image.src = reader.result as string;
    //             image.onload = () => {
    //                 const { naturalWidth, naturalHeight } = image;
    //                 const aspectRatio = imageType === 'logo' ? 1 : 5 / 3;
    
    //                 // Determine the initial zoom level
    //                 const zoomLevel = Math.min(
    //                     image.width / naturalWidth, 
    //                     image.height / (naturalHeight * aspectRatio)
    //                 );

    //                 console.log('zoomLevel', zoomLevel)
    
    //                 setZoom(zoomLevel); // Set the initial zoom level
    //                 setPreview(reader.result as string);
    //                 setOpen(true);
    //             };
    //         };
    //         reader.readAsDataURL(file);
    //         event.target.value = '';
    //     }
    // };
    

    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setInternalSelectedImage(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview(reader.result as string);
                setOpen(true);
            };
            reader.readAsDataURL(file);
            event.target.value = '';
        }
    };

    useEffect(() => {
        if (open) {
            setZoom(1);
            setRotation(0);
        }
    }, [open]);

    const handleModalClose = () => {
        setOpen(false);
    };

    const handleCropSave = async () => {
        if (!croppedAreaPixels || !preview) return;
        const croppedFile = await getCroppedImg(preview, croppedAreaPixels, rotation, selectedImage);
        setCroppedImage(URL.createObjectURL(croppedFile));
        setSelectedImage(croppedFile);
        setOpen(false);
        onS3Upload?.(croppedFile, imageType)
    };

    const renderImageCard = () => {
        if (croppedImage && !onS3Upload && photoDesc) {
            return (
                <div className='__image_picker_box'>
                    <div className='__image_picker_content'>
                        <div className='title'>{photoDesc?.title}</div>
                        <div className='__sub__body'>{photoDesc?.desc}</div>
                    </div>
                    <img src={croppedImage} alt="Cropped Preview" style={{ height:'50px',width:'50px' }} />
                </div>
            )
        }
        else if (croppedImage && !onS3Upload) {
            return (
                <img src={croppedImage} alt="Cropped Preview" style={{ maxWidth: '300px' }} />
            )
        }
        else {
            return (
                <div className="__icon_pic" onClick={() => document.getElementById('image-input')?.click()}>
                    {children}
                </div>
            )
        }
    }

    return (
        <div className='__custom__image_picker'>
            <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                style={{ display: 'none' }}
                id={`image-input-${id}`}
            />

            <div className='label_container'>
                <label style={{ cursor: 'pointer' }} htmlFor={`image-input-${id}`}>
                    {renderImageCard()}
                </label>
                {croppedImage && !onS3Upload &&
                    <MdOutlineDeleteOutline onClick={() => {
                        setSelectedImage(null)
                        setCroppedImage(null)
                    }} size={'20px'} className='icon_delete' />}
            </div>

            <ModalView modalStyle={{ padding: 0 }} title="Crop Image" open={open} handleClose={handleModalClose}>
                <div style={{ padding: '15px' }}>
                    <div className='__custom__image_picker__modal'>
                        <Cropper
                            image={preview as string}
                            crop={crop}
                            zoom={zoom}
                            rotation={rotation}
                            aspect={imageType == 'logo' ? 1 : 5 / 3}
                            onCropChange={setCrop}
                            onCropComplete={onCropComplete}
                            onZoomChange={setZoom}
                            onRotationChange={setRotation}
                            objectFit="horizontal-cover"
                        />
                    </div>
                    <div className='__custom__image_picker__modal__footer'>
                        <div className='_slider_label'>zoom</div>
                        <Slider
                            value={zoom}
                            min={1}
                            max={3}
                            step={0.1}
                            aria-labelledby="Zoom"
                            onChange={(e, zoom) => setZoom(zoom as number)}
                        />
                        <div className='_slider_label'>Rotation</div>
                        <Slider
                            value={rotation}
                            min={0}
                            max={360}
                            step={1}
                            aria-labelledby="Rotation"
                            onChange={(e, rotation) => setRotation(rotation as number)}
                        />
                    </div>
                    <div className='__custom__image_picker__footer'>
                        <Button onClick={handleCropSave}>Save</Button>
                        <Button onClick={handleModalClose}>Cancel</Button>
                    </div>
                </div>
            </ModalView>
        </div>
    );
};

export default CustomImagePicker;
