import React, { useState } from 'react';
import ModalView from '../ModalView';
import { IoIosClose } from "react-icons/io";
import './index.scss'
import PrimaryButton from '../CustomButtons';

interface Props {
    handleClose?: () => void;
    open: any;
    modalStyle?: any;
    handleOpen?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    primaryButton?: boolean
}

const LogOutModal: React.FC<Props> = ({ open, handleClose, modalStyle, handleOpen,primaryButton=true }) => {

    const onClose = () => {
        handleClose?.()
    }

    const RenderModal = () => (
        <div className="create-logout-modal">
            <div className='__header'>
            <h4>Log Out</h4>
                <button onClick={handleClose}><IoIosClose /></button>
            </div>
            <div className="__logout-content">
                <p>You are about to logout. Are you sure?</p>
            </div>
            <div className={`modal-footer-button ${primaryButton ? '__primary' : '___btn'}`}>
                <PrimaryButton
                    fullWidth={false}
                    onClick={handleOpen}
                    isLoading={false}>
                    Yes, I'am Sure!
                </PrimaryButton>
            </div>
        </div>
    )
    return (
        <ModalView open={open} handleClose={onClose} modalStyle={modalStyle}>
            {RenderModal()}
        </ModalView>
    )
};

export default LogOutModal;
