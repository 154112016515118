// Core
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Third-Party Libraries
import { Avatar, Checkbox, CircularProgress } from '@mui/material';
import QrCodeIcon from '@mui/icons-material/QrCode';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import _ from 'lodash';

// Components
import SearchInput from 'components/atoms/SearchInput';
import GridContainer from 'components/molecules/GridContainer';
import PrimaryButton from 'components/atoms/CustomButtons';

// Utilities
import { fetchData } from 'utils/fetch';
import { CLASS_JOIN, restAPIs } from 'utils/restAPIs';
import { useFetchClassRoomItems } from 'utils/customHooks';
import { loadActiveClassroomLocalStorage } from 'utils/commonUtils';

// Styles and Constants
import './index.scss'
import { colors } from 'assets/styles/colors';

// Assets
import userSingle from '../../assets/images/userSingle.png'



const AddClassmates: React.FC = () => {


    const navigate = useNavigate();
    const {
        fetchClassroom,
    } = useFetchClassRoomItems()
    const activeClassroom = loadActiveClassroomLocalStorage()
    const [isLoading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState([]);
    const [error, setError] = useState('');
    const [selectedItems, setSelectedItems] = useState<any[]>([]);
    const [copySuccess, setCopySuccess] = useState(false);
    const link = CLASS_JOIN + activeClassroom?._id;

    const handleSelect = (item: any) => {
        const isSelected = selectedItems.some((selectedItem) => selectedItem._id === item._id);
        if (isSelected) {
            setSelectedItems((prev) => prev.filter((selectedItem) => selectedItem._id !== item._id));
        } else {
            setSelectedItems((prev) => [...prev, item]);
        }
    };

    const debouncedSearch = useCallback(
        _.debounce((query: string) => {
            onSearch(query);
        }, 500),
        []
    );

    const onSearch = async (value: any) => {
        setLoading(true)
        let params = {
            term: value,
            offset: 0,
            count: 100,
        };
        const res = await fetchData(restAPIs.getUserSuggest(params), navigate);
        if (res.status === 200 && res.data) {
            setData(res?.data?.users)
        }
        else {
            setError(res?.message?.length > 0 ? res?.message : 'Something went wrong')
        }
        setLoading(false)
    };

    const handleChange = (text: string) => {
        debouncedSearch(text);
    };
    const isAlreadyAdded = (id: string) => {
        let isAdded = false;
        activeClassroom?.members?.forEach((item: any) => {
            if (item.userId === id) isAdded = true;
        });
        return isAdded;
    };

    const handleCopy = async (item: any) => {
        try {
            await navigator.clipboard.writeText(item?.value);
            setCopySuccess(true);
            setTimeout(() => setCopySuccess(false), 2000);
        } catch (error) {
            console.error('Failed to copy text: ', error);
        }
    };

    const deSelect = (id: string) => {
        setSelectedItems(selectedItems.filter(item => item._id !== id));
    };

    const RenderTooltip = (item: any) => (
        <div onMouseLeave={() => setCopySuccess(false)}
            onClick={() => handleCopy(item)}
            style={{ position: 'relative', display: 'inline-block', cursor: 'pointer' }}>
            <div className='option'>
                <ContentCopyIcon />
                <div>{item?.key}</div>
            </div>
            {copySuccess && (
                <span
                className='copy_success_span'
                >
                    Link copied!
                </span>
            )}
        </div>
    )

    const _onDone = async () => {
        let temp_array: { userId: any; role: string; }[] = [];
        if (selectedItems?.length > 0) {
            selectedItems.forEach(item => {
                let temp_data = {
                    userId: item._id,
                    role: 'member',
                };
                temp_array.push(temp_data);
            });
            const res = await fetchData(restAPIs.getAddStudent(activeClassroom?._id, temp_array), navigate);
            if (res.status === 200) {
                fetchClassroom(activeClassroom?._id)
                navigate(-1)
            }
        }
    };

    return (
        <GridContainer
            pageTitle={'Add Classmates'}
            backButton
            leftGridPartition={3 / 4}
        >
            <div className='add-classmates'>
                <div>
                    <SearchInput placeholder={'Name, Email or Phone Number'} onChangeText={text => {
                        handleChange(text);
                    }} />
                </div>
                <div className='add_classmates__header'>
                    <div className='options_container'>
                        {RenderTooltip({ key: 'Copy joining link', value: link })}
                        <div onClick={() => navigate('/qr-code')} className='option'>
                            <QrCodeIcon />
                            <div>QR code</div>
                        </div>
                    </div>
                    <PrimaryButton
                        disabled={selectedItems?.length == 0}
                        onClick={_onDone} >
                        <div>
                            Add
                        </div>
                    </PrimaryButton>
                </div>
                {selectedItems?.length > 0 &&
                    <div className='add_selected_list'>
                        {selectedItems?.map((item: any, index: number) => (
                            <div className='add_selected_item'>
                                {isAlreadyAdded(item?._id) ? <div></div> :
                                    <Avatar
                                        src={item.profilePic?.length > 0 ? item.profilePic : userSingle}
                                        sx={{ bgcolor: colors.white, width: 35, height: 35, fontSize: '14px' }}
                                        aria-label="recipe" />}
                                <i onClick={() => deSelect(item?._id)} style={{ fontSize: '18px' }} className="fi fi-sr-circle-xmark"></i>
                            </div>
                        ))}
                    </div>}
                {isLoading ? <CircularProgress size={15} sx={{ color: colors?.primary, marginTop: '15px' }} /> :
                    data?.length > 0 &&
                    <div className='class-count'>Members</div>
                }
                {data?.length > 0 &&
                    <div className='add_classmates_list'>
                        {data?.map((item: any, index: number) => (
                            <div
                                style={{ borderBottom: index == data?.length - 1 ? 'none' : '', opacity: isAlreadyAdded(item?._id) ? 0.5 : 1 }}
                                className='add_classmates_item'>
                                {isAlreadyAdded(item?._id) ? <div></div> :
                                    <Avatar
                                        src={item.profilePic?.length > 0 ? item.profilePic : userSingle}
                                        sx={{ bgcolor: colors.white, width: 30, height: 30, fontSize: '14px' }}
                                        aria-label="recipe" />}
                                <div>
                                    <div className='name'>{item?.firstName} {item?.lastName}</div>
                                    {isAlreadyAdded(item?._id) &&
                                        <div className='desc'>Already added to this classroom</div>}
                                </div>
                                {!isAlreadyAdded(item?._id) &&
                                    <Checkbox
                                        checked={selectedItems.some((selectedItem) => selectedItem._id === item._id)}
                                        onChange={() => handleSelect(item)}
                                        color="primary"
                                    />}
                            </div>
                        ))}
                    </div>}
            </div>
        </GridContainer>
    )
};

export default AddClassmates;