import React, { FC, useRef, useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import ScrollToTopButton from './ScrollToTopButton';
import { useLocation } from 'react-router-dom';
import useOnlineStatus from 'utils/customHooks';
import { checkLocationMatch } from 'utils/commonUtils';


interface ChildrenProps {
  children?: React.ReactNode;
  overflowY?: 'auto' | 'hidden' | 'scroll' | 'visible';
  leftGridPartition?: any
  loadMore?: () => void;
  page?: number;
  loading?: boolean;
  data?: any
}
const style = {
  borderBottom: '1px solid #70707024',
  borderLeft: '1px solid #70707024',
  // height: 'calc(100vh - 61px)',
  padding: '20px 0',
  }

const LeftGrid: FC<ChildrenProps> = ({ children, overflowY = 'auto', leftGridPartition, loadMore, page, loading, data }) => {

  const { classroomData } = useSelector((state: any) => state.classRoom) || {};
  const [isVisible, setIsVisible] = useState(false);

  const location = useLocation();
  const isOnline = useOnlineStatus();

  const scrollRef = useRef<HTMLDivElement>(null);


  useEffect(() => {
    const handleScroll = () => {
      if (scrollRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
        const threshold = 1; 
        const bottom = scrollHeight - scrollTop <= clientHeight + threshold;
        if (scrollTop > 2500) {
          setIsVisible(true)
        }
        else {
          setIsVisible(false)
        }
        if (bottom) {
          if (!loading) {
            loadMore?.();
          }
        }
      }
    };

    const scrollElement = scrollRef.current;
    if (scrollElement) {
      scrollElement.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (scrollElement) {
        scrollElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, [classroomData?.isLastNote, page, loading, data]);

  return (
    <Grid ref={scrollRef} style={{ ...style, height : checkLocationMatch(location.pathname) ? 'calc(100vh)': 'calc(100vh - 61px)', overflowY: overflowY,position:'relative' }}
      xs={12}
      sm={leftGridPartition ? leftGridPartition * 12 : 8}
      md={leftGridPartition ? leftGridPartition * 12 : 8}
      lg={leftGridPartition ? leftGridPartition * 12 : 8}
      item>
      {children}
      <ScrollToTopButton scrollRef={scrollRef} isVisible={isVisible} setIsVisible={setIsVisible} />
    </Grid>
  );
};

export default LeftGrid;

