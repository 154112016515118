import React, { useEffect, useState } from 'react';
import './index.scss'
import class_logo from '../../../assets/images/class_logo.png'
import {
    KeyboardArrowRightOutlined,
    PeopleAltOutlined
} from '@mui/icons-material';
import { SecondryButton } from '../CustomButtons';
import { useDispatch } from 'react-redux';
import { getStatus, loadActiveClassroomLocalStorage, loadUserFromLocalStorage } from 'utils/commonUtils';
import { useClassMetadata, useJoinRoom, useSwichClass } from 'utils/customHooks';
import ClassSwitchModal from '../ClassSwitchModal';
import ClassRoomDetailModal from '../ClassRoomDetailModal';
import { setSelectedClassRoom } from '../../../redux/slices/setClassroom/classRoomSlice';
import { useSnackbar } from '../SnackbarProvider';
import { useNavigate } from 'react-router-dom';

const ClassRoomCard: React.FC<{ data: any }> = ({ data }) => {

    const activeClassroom = loadActiveClassroomLocalStorage()
    const user = loadUserFromLocalStorage();
    const classRooms = user?.classRooms
    const getClassMetadata = useClassMetadata();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { showSnackbar } = useSnackbar();
    const swichClass = useSwichClass()
    const [open, setOpen] = useState<boolean>(false);
    const [classRoom, setClassRoom] = useState<any>(null);
    const [classRoomDetail, setdetail] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handleOpen = (e: React.MouseEvent<HTMLElement>, item: any) => {
        // if (getStatus(item, classRooms) === 'Pending') {
        //     showSnackbar('Waiting for Approval', 'warning')
        //     setdetail(item)
        //     getClassMetadata(item?._id)
        // }
        // else {
        if (activeClassroom?._id != item?._id)
            swichClass(item?._id, true)
        else {
            navigate('/feed', { replace: true })
            showSnackbar('Already in this class room !', 'warning')
        }
        // }
    };

    const handleClose = () => {
        setIsOpen(false);
        setAnchorEl(null);
    };

    const openDetailModal = () => {
        setdetail(classRoom)
        getClassMetadata(classRoom?._id)
    }

    const handleDetailClose = () => {
        setdetail(null)
    }


    return (
        <div className='class-card'>
            <span className='class-count'>{data?.length} Classrooms</span>
            {data?.map((item: any) => {
                return (
                    <div onClick={(e) => handleOpen(e, item)} className='class-room-card' key={item.id}>
                        <img src={item?.logos?.small?.length > 0 ? item?.logos?.small : class_logo} className='class_logo' alt={item.name} />
                        <div className='class-room-content'>
                            <span className='name'>{item.name}</span>
                            <span className='class-room-student'>
                                <PeopleAltOutlined className='Drawer-icon' sx={{ fontSize: 18, marginRight: '10px' }} />
                                <span className='student'>{item?.totalStudents === 1
                                    ? '1 Student'
                                    : `${item?.totalStudents} Students`}</span>
                            </span>
                            <span className='class-room-student'>
                                <PeopleAltOutlined className='Drawer-icon' sx={{ fontSize: 18, marginRight: '10px' }} />
                                <span className='student'>{item?.totalInstructors === 1
                                    ? '1 Instructor'
                                    : `${item?.totalInstructors} Instructors`}</span>
                            </span>
                        </div>
                        <KeyboardArrowRightOutlined className='Drawer-icon' sx={{ fontSize: 30 }} />
                    </div>
                )
            })}
            <ClassSwitchModal item={classRoom} isOpen={isOpen} openDetailModal={openDetailModal} onClose={handleClose} anchorEl={anchorEl} />
            <ClassRoomDetailModal item={classRoomDetail} onClose={handleDetailClose} />
        </div>
    );
};

export default ClassRoomCard

const PreferenceCard: React.FC<{ data: any }> = ({ data }) => {
    const navigate = useNavigate();

    return (
        <div className='class-card'>
            {data?.map((item: any) => (
                <div className='class-room-card preference-card' key={item.id}>
                    <div onClick={() => navigate(item.route)} className='class-room-content'>
                        <span className='name'>{item.title}</span>
                        <span className='class-roomId'>{item.desc}</span>
                    </div>
                    <KeyboardArrowRightOutlined className='Drawer-icon' sx={{ fontSize: 30 }} />
                </div>
            ))}
        </div>
    );
};

interface Props {
    data: any,
    onClick: (clickedItem: any) => void,
    from?: string
}

const ClassRoomListCard: React.FC<Props> = ({ data, onClick, from }) => {

    const joinRoom = useJoinRoom();
    const user = loadUserFromLocalStorage();
    const activeClassroom = loadActiveClassroomLocalStorage()
    const classRooms = user?.classRooms
    const dispatch = useDispatch()
    const swichClass = useSwichClass()
    const { showSnackbar } = useSnackbar();
    const [selected, setSelected] = useState<string | null>(null)

    const onSubmitClick = (item: any) => {
        setSelected(item?._id)
        if (getStatus(item, classRooms) !== 'Pending') {
            dispatch(setSelectedClassRoom(item))
            if (getStatus(item, classRooms) === 'Joined') {
                swichClass(item?._id, true)
            }
            else {
                joinRoom(item?._id, item?.public)
            }
        }
        else {
            showSnackbar('Waiting for Classroom Admin Approval', 'info')
        }
    }

    useEffect(() => {
        setSelected(null)
    }, [data]);

    return (
        <div className='class-card'>
            <span
                className='class-count'>
                {data?.length} {from ? 'Common' : ''} {data?.length === 1 ? 'Classroom' : 'Classrooms'}
            </span>
            {data?.map((item: any) => {
                if (item?.active) {
                    return (
                        <div
                            className='class-room-card' key={item.id}>
                            <img src={item?.logos?.small?.length > 0 ? item?.logos?.small : class_logo} className='class_logo' onClick={(event) => {
                                if (event.target === event.currentTarget) {
                                    onClick(item);
                                }
                            }} />
                            <div className='class-room-content' onClick={(event) => {
                                if (event.target === event.currentTarget) {
                                    onClick(item);
                                }
                            }}>
                                <span className='name list'>{item.name}{activeClassroom?._id == item?._id ? <span className='active-class'> Active </span> : ''}</span>
                            </div>
                            <SecondryButton
                                fullWidth={false}
                                className={from ? 'class-room-list-card view-class-room-button' : `class-room-list-card join-class-room-button ${getStatus(item, classRooms)?.toLowerCase()}`}
                                onMouseDown={() => from ? onClick(item) : onSubmitClick(item)}
                                isLoading={false}
                                disabled={selected == item?._id}
                                text={from ? 'View Class' : getStatus(item, classRooms) == 'Joined' ? 'Go to Classroom' : getStatus(item, classRooms)} />
                        </div>
                    )
                }
                else return null;
            }
            )}
        </div>
    );
};

export { PreferenceCard, ClassRoomListCard }
