// Core
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// Third-Party Libraries
import { Typography } from '@mui/material';
import { FaChevronLeft } from "react-icons/fa6";
import PhoneInput from 'react-phone-input-2'

// Components
import PrimaryButton from "components/atoms/CustomButtons";

// Utilities
import { fetchData } from "utils/fetch";
import { restAPIs } from "utils/restAPIs";
import { isNumberValid } from 'utils/commonUtils';
import { KeyEventType, LoginData, LoginFormKeys } from 'utils/constants';
import { LoginProps } from 'utils/types';

// Redux Actions and State
import { setLoginForm } from '../../../redux/slices/setUser/userSlice'

// Styles and Constant
import 'react-phone-input-2/lib/style.css'
import '../index.scss';

const PhoneNumberLogin: React.FC<LoginProps> = ({ setType }) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [value, setValue] = useState('')
  const [dialCode, setDialCode] = useState('')
  const [countryCode, setCountryCode] = useState('in')
  const [number, setNumber] = useState('')
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (phone: string, country: any, e: any) => {
    const cleanedNumber = phone?.slice(country?.dialCode?.length)
    setNumber(cleanedNumber)
    setValue(phone)
    setDialCode(`+${country?.dialCode}`)
    setCountryCode(country.countryCode)
    setError('')
  }

  const validatePhoneNumber = () => {
    if (!number?.trim()?.length) {
      setError(LoginData.Required);
      return false;
    }
    if (!isNumberValid(number, countryCode?.toUpperCase())) {
      setError(LoginData.PleaseEnterValidNumber);
      return false;
    }

    return true;
  };

  const onSubmit = async () => {
    if (validatePhoneNumber()) {
      setIsLoading(true)
      const formData = {
        countryCode: dialCode,
        phone: number,
        hashKey: ''
      }
      const res = await fetchData(restAPIs.getPhoneOtp(formData), navigate);
      setIsLoading(false)
      if (res?.status === 200) {
        if (res?.data?.newUser) {
          setType(LoginFormKeys.CreateUser)
        } else {
          setType(LoginFormKeys.OtpScreen)
        }
        dispatch(setLoginForm(formData))
      }
      else {
        setError(res?.data?.fields?.phone)
      }
    }
  }

  const goBack = () => {
    setType(LoginFormKeys.SignInForm)
  }

  const handleKeyDown = (event: any) => {
    if (event.key === KeyEventType.Enter && !isLoading) {
      onSubmit();
    }
  };

  return (
    <div className='phone-number-form-container'>
      <FaChevronLeft onClick={goBack} className='phone-form-back-button' />
      <div className='phone-number-form'>
        <Typography
          align='left'
          fontWeight={400}
          variant="h6">
          {LoginData.EnterYour}
          <Typography
            variant='h1'>
            {LoginData.PhoneNumber}
          </Typography>
        </Typography>
        <PhoneInput
          country={'in'}
          value={value}
          enableSearch={false}
          disableSearchIcon
          onKeyDown={handleKeyDown}
          containerStyle={{
            padding: '0px',
            borderRadius: '10px',
            height: '53px',
            border: '1.5px solid #B4B4B4',
            marginTop: '25px'
          }}
          buttonStyle={{
            border: 0,
            borderRadius: '10px',
            marginRight: '10px',
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            borderRight: '1.5px solid #B4B4B4'
          }}
          inputStyle={{
            borderRadius: '15px',
            height: '53px',
            border: 0,
            fontSize: '15px !important',
            fontWeight: 600

          }}
          dropdownStyle={{
            textAlign: 'left'
          }}
          inputClass='__phon_ip'
          onChange={handleChange}
        />
        <div className='phone-error-msg'>{error}</div>
        <div className='otp_input_button'>
          <PrimaryButton
            isLoading={isLoading}
            fullWidth={false}
            onClick={onSubmit} >
            {LoginData.Continue}
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default PhoneNumberLogin;
