// Core
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Components
import GridContainer from 'components/molecules/GridContainer';
import ClassRoomCard, { PreferenceCard } from 'components/atoms/ClassRoomCard'
import PrimaryButton from 'components/atoms/CustomButtons';

// Styles and Constants
import './index.scss';

// Utilities
import { useUpdateUser } from 'utils/customHooks';
import { loadUserFromLocalStorage } from 'utils/commonUtils';

// Third-Party Libraries
import { CircularProgress, Typography } from '@mui/material';
import { HomeData, preferenceData } from 'utils/constants';
import { useMetadataContext } from 'contextApi/MetadataContext';
import { colors } from 'assets/styles/colors';

const Home: React.FC = () => {

    const temp_user = loadUserFromLocalStorage();
    const { user } = useSelector((state: any) => state.user)
    const data = temp_user?.classRooms
    const navigate = useNavigate();
    const getUpdateUser = useUpdateUser()
    const { isUpdateUserLoading } = useMetadataContext();

    useEffect(() => {
        if (user)
            getUpdateUser()
    }, []);

    return (
        <GridContainer pageTitle={'\t'} leftGridPartition={3 / 4}>
            <div className='home'>
            {data?.length> 0 &&
                <div className="header-container">
                    <Typography fontWeight={700} fontSize={'27px'} variant="h1" className='home-form-title'>
                        {HomeData.SelectYour}
                        <Typography
                            fontWeight={700}
                            fontSize={'27px'}
                            variant="h1">
                            { HomeData.Classroom }
                        </Typography>
                    </Typography>
                </div>}
                {data?.length == 0 &&
                    <div className='no_data'>{HomeData.NoClassRoom}</div>}
                    {data?.length> 0 &&
                <ClassRoomCard data={data} />}
                {isUpdateUserLoading&&<div className='loader-container document_detail_loader'>
                    <CircularProgress size={15} sx={{ color: colors?.primary }} />
                </div>}
            </div>
        </GridContainer>
    );
};

export default Home;
