import React from 'react';

export interface ColorMode {
  toggleColorMode: () => void;
  mode?:string
}

const ColorModeContext = React.createContext<ColorMode | null>(null);

export default ColorModeContext;
