import React, { useState } from 'react';
import './index.scss';
import UserCard from '../UserCard';
import { CircularProgress } from '@mui/material';
import { colors } from 'assets/styles/colors';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { loadUserFromLocalStorage } from 'utils/commonUtils';
import UrlContentTextWrapper from '../UrlContentTextWrapper';
interface CommentInputProps {
    commentCount?: number;
    isLoding: boolean;
    comments?: any;
    isLastPage?: boolean
    fetchComments: () => void
    onEdit?:(item: any,index:number)=>void
    onDelete?:(item: any,index:number)=>void
    selectedIndex?:any
    setSelectedIndex: React.Dispatch<React.SetStateAction<any>>;
}

const CommentList: React.FC<CommentInputProps> = ({ commentCount,selectedIndex,setSelectedIndex, isLoding, isLastPage, comments, fetchComments,onEdit,onDelete }) => {

    const userObject = loadUserFromLocalStorage();
    const user = userObject?.userDetails
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selected, setSelected] = useState<any>(null);

    const handleClose = () => {
        setAnchorEl(null);
        setSelected(null)
    };

    const handleClick = (event: React.MouseEvent<HTMLElement>,item:any,index:any) => {
        setAnchorEl(event.currentTarget);
        setSelected(item)
        setSelectedIndex(index)
    };

    const onEditComment=()=>{
        onEdit?.(selected,selectedIndex)
        handleClose()
    }

    const onDeleteComment=(item:any,index:number)=>{
        onDelete?.(selected,selectedIndex)
        handleClose()
    }

    return (comments?.length == 0 && !isLoding) ? <div className='no-comment-text'>Be the first to leave a comment!</div> : (
        <div className="comment-list-wrapper">
            {comments?.length > 0 &&
                <div className='count'>comments({commentCount}) </div>}
            {comments?.map((item: any,index:number) => (
                <div key={index} className='comment-list'>
                    <UserCard handleClick={handleClick} selectedItem={item} selectedIndex={index} item={item} actionEnabled={item?.userId === user?._id} >
                        <Menu
                            id="long-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}

                            className='___menu'
                                            PaperProps={{
                                                sx: {
                                                    borderRadius: '10px',
                                                    boxShadow: 'none',
                                                    border:'1px solid #eee'
                                                },
                                            }}
                        >
                            <MenuItem className='item-container' onClick={onEditComment}>
                                 Edit
                            </MenuItem>
                            <MenuItem className='item-container' onClick={()=>onDeleteComment(selected,index)}>
                                Delete
                            </MenuItem>
                        </Menu>
                    </UserCard>
                    <div className='comment-container'>
                    {isLoding&&selectedIndex===index?
                    <div style={{height:'30px',width:'30px'}}><CircularProgress size={20} sx={{ color: colors?.primary }} /></div>:
                        <span className='comment'>
                            <UrlContentTextWrapper content={item?.comment} />
                        </span>}
                    </div>
                </div>
            ))}
            {isLoding &&
                <div className='progress' style={{marginTop:'20px'}}>
                    <CircularProgress size={20} sx={{ color: colors?.primary }} />
                </div>}
            {!isLastPage &&!isLoding &&
                <div onClick={() => fetchComments()} className='footer'>See More</div>}
        </div>
    );
};

export default CommentList;


const IconStyle = {
    color: colors.tertiary,
    fontSize: '16px',
    marginRight: '10px'
}
