// Core
import React, { useState } from "react";
import { useSelector, useDispatch } from 'react-redux';

// Third-Party Libraries
import { TextField, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import { FaChevronLeft } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import {
  isMobile,
  isTablet,
  browserName,
  engineName,
  osName,
  osVersion,
} from 'react-device-detect';

// Components
import PrimaryButton from "components/atoms/CustomButtons";

// Utilities
import { fetchData } from "utils/fetch";
import { restAPIs } from "utils/restAPIs";
import {
  ChangeNameFormErrorState,
  ChangeNameFormState,
  changeNameInitialError,
  changeNameInitialForm,
  CreateUserProps
} from "utils/types";
import { CursorType, DeviceType, InputLabels, InputMargins, InputNames, InputVariants, LoginData, LoginFormKeys } from "utils/constants";

// Redux Actions and State
import { setLoginForm, updateUserDetails } from '../../../redux/slices/setUser/userSlice'

// Styles and Constants
import '../index.scss';
import { colors } from "assets/styles/colors";

const style = { height: '90vh' }

const CreateUser: React.FC<CreateUserProps> = ({ setType, fromProfile, closeModal }) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState<ChangeNameFormState>(changeNameInitialForm);
  const [errors, setErrors] = useState<ChangeNameFormErrorState>(changeNameInitialError);
  const [isLoading, setIsLoading] = useState(false);
  const { loginForm } = useSelector((state: any) => state.user) || {};
  const { countryCode = '', phone = '' } = loginForm || {}
  const [open, setOpen] = React.useState(false);

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === CursorType.Clickaway) {
      return;
    }

    setOpen(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const validateForm = (): boolean => {
    let newErrors = { ...changeNameInitialError };
    if (!formData.firstName) {
      newErrors.firstName = LoginData.FirstNameRequired;
    }
    if (!formData.lastName) {
      newErrors.lastName = LoginData.LastNameRequired;
    }

    setErrors(newErrors);
    return Object.values(newErrors).every((error) => error === '');
  };

  const handleSignup = async () => {
    const deviceInfo = {
      platform: osName,
      brand: browserName,
      deviceType: isMobile ? DeviceType.Mobile : isTablet ? DeviceType.Tablet : DeviceType.Desktop,
      systemVersion: osVersion,
      manufacturer: engineName,
    };
    const userForm = fromProfile ? {
      firstName: formData?.firstName.trim(),
      lastName: formData?.lastName.trim()
    } : {
      phone: phone,
      countryCode: countryCode,
      userDetails: {
        firstName: formData?.firstName.trim(),
        lastName: formData?.lastName.trim()
      },
      deviceInfo: JSON.stringify(deviceInfo)
    }
    setIsLoading(true)
    const res = fromProfile ?
      await fetchData(restAPIs.putUserDetails(userForm), navigate) :
      await fetchData(restAPIs.getSignUp(userForm), navigate);
    setIsLoading(false)
    const response = res.data
    if (res.status === 200) {
      if (fromProfile) {
        closeModal?.()
        dispatch(updateUserDetails(response))
      }
      else {
        const phoneData = {
          countryCode: countryCode,
          phone: phone,
          hashKey: ''
        }
        const res = await fetchData(restAPIs.getPhoneOtp(phoneData), navigate);
        if (res?.status === 200) {
          setType?.(LoginFormKeys.OtpScreen)
          dispatch(setLoginForm(userForm))
        }
        else {
          setErrors({ message: response?.message, ...response?.fields })
        }
      }
    }
    else {
      if (fromProfile) {
        setOpen(true);
      }
      setErrors({ message: response?.message, ...response?.fields })
    }
  };
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (validateForm()) {
      handleSignup()
    }
  };

  const goBack = () => {
    setType?.(LoginFormKeys.PhoneNumberLogin)
  }

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div style={!fromProfile ? style : {}} className={'signup-form-wrapper'}>
      <FaChevronLeft onClick={goBack} className='phone-form-back-button' />
      {!fromProfile && errors?.message &&
        <Alert className="login-alert bottom" severity="error">{errors?.message}</Alert>}
      <Snackbar
        open={open}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={6000}
        onClose={handleClose}
        message={errors?.message}
        action={action}
      />
      <form onSubmit={handleSubmit} className='login-form'>
        {!fromProfile &&
          <Typography className="create_account_title" variant='h1'>{LoginData.CreateAccount}</Typography>}
        <TextField
          label={InputLabels.FirstName}
          fullWidth
          variant={InputVariants.Outlined}
          error={!!errors.firstName}
          helperText={errors.firstName}
          onChange={handleChange}
          margin={InputMargins.Normal}
          name={InputNames.FirstName}
          InputLabelProps={{
            style: { fontSize: '13px', fontWeight: 400, color: colors.inputLabelColor },
          }}
          className="input-field"
        />
        <TextField
          label={InputLabels.LastName}
          name={InputNames.LastName}
          fullWidth
          error={!!errors.lastName}
          helperText={errors.lastName}
          variant={InputVariants.Outlined}
          onChange={handleChange}
          margin={InputMargins.Normal}
          InputLabelProps={{
            style: { fontSize: '13px', fontWeight: 400, color: colors.inputLabelColor },
          }}
          className="input-field"
        />

        <div className="create_account_title">
          <PrimaryButton
            fullWidth={false}
            isLoading={isLoading}>
            {fromProfile ? LoginData.ChangeName : LoginData.Signup}
          </PrimaryButton>
        </div>
      </form>
    </div>
  );
}

export default CreateUser;
