import React from 'react';
import { TimePicker, DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import moment from 'moment';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import './index.scss'
import { DateValidationError, TimeValidationError } from '@mui/x-date-pickers/models';
import { combineDateTimeToUTC } from 'utils/commonUtils';



interface EventForm {
  startDate: moment.Moment | any;
  endDate: moment.Moment | any;
  startTime: moment.Moment | any;
  endTime: moment.Moment | any;
}

interface Props {
  eventForm: EventForm;
  setEventForm: React.Dispatch<React.SetStateAction<EventForm>>;
  dateError: any;
  setDateError: React.Dispatch<React.SetStateAction<DateValidationError>>;
  timeError: any;
  setTimeError: React.Dispatch<React.SetStateAction<TimeValidationError>>;
}

const EventCreationForm: React.FC<Props> = ({ eventForm, setEventForm, dateError, setDateError, timeError, setTimeError }) => {

  const dateErrorMessage = React.useMemo(() => {
    switch (dateError) {
      case 'maxDate':
      case 'minDate': {
        return 'The end date must be greater than start date';
      }

      case 'invalidDate': {
        return 'Your date is not valid';
      }

      default: {
        return '';
      }
    }
  }, [dateError]);

  const timeErrorMessage = React.useMemo(() => {

    console.log('timeError',timeError)
    switch (timeError) {
      case 'maxTime':
      case 'minTime': {
        return 'The end date must be greater than start date';
      }

      case 'invalidDate': {
        return 'Your date is not valid';
      }

      default: {
        return '';
      }
    }
  }, [timeError]);

  const formatDate = (value: any) => {
    const year = value?.year();
    const month = value?.month() + 1;
    const day = value?.date();
    return `${year}-${month?.toString()?.padStart(2, '0')}-${day?.toString()?.padStart(2, '0')}`;
  }

  const handleStartDateChange = (value: any) => {
    setEventForm({ ...eventForm, startDate: value, endDate: value.add(1, 'day') });
  }

  const handleEndDateChange = (value: any) => {
    if (eventForm) {
      const startTimeUTC = combineDateTimeToUTC(new Date(eventForm?.startDate), new Date(eventForm?.startTime));
      const endTimeUTC = combineDateTimeToUTC(new Date(value), new Date(eventForm?.endTime));
      if (startTimeUTC === endTimeUTC) {
        setTimeError('minTime')
      }
      else{
        setTimeError(null)
      }
      setEventForm({ ...eventForm, endDate: value });
    }
  }

  const handleStartTimeChange = (value: any) => {
    setEventForm({ ...eventForm, startTime: value });
  }

  const handleEndTimeChange = (value: any) => {
    setEventForm({ ...eventForm, endTime: value });
    setTimeError(null)
  }

  return (
    <div>
      <label className='event-picker__label'>Start Date</label>
      <div className='date-picker-container'>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div className='date-picker'>
            <DatePicker
              sx={{ width: '100%' }}
              disablePast
              onChange={handleStartDateChange}
              format="MMM DD YYYY"
              value={eventForm?.startDate}
              defaultValue={dayjs()}
              label="Start date" />
            <TimePicker
              sx={{ width: '100%' }}
              timeSteps={{ hours: 1, minutes: 1 }}
              value={eventForm?.startTime}
              minTime={formatDate(eventForm?.startDate) == formatDate(dayjs()) ? dayjs().subtract(5, 'minute') : ''}
              onChange={handleStartTimeChange}
              defaultValue={dayjs()}
              onError={(newError) => setTimeError(newError)}
              slotProps={{
                textField: {
                  helperText: timeErrorMessage?'start time should be greater than current time': '',
                },
              }}
              label="Start time" />
          </div>
        </LocalizationProvider>

      </div>
      <label className='event-picker__label'>End Date</label>
      <div className='date-picker-container'>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div className='date-picker'>
            <DatePicker
              sx={{ width: '100%' }}
              disablePast
              minDate={eventForm?.startDate}
              format="MMM DD YYYY"
              value={eventForm?.endDate}
              onChange={handleEndDateChange}
              defaultValue={dayjs()}
              onError={(newError) => setDateError(newError)}
              slotProps={{
                textField: {
                  helperText: dateErrorMessage,
                },
              }}
              label="End date" />
            <TimePicker
              onChange={handleEndTimeChange}
              onError={(newError) => setTimeError(newError)}
              timeSteps={{ hours: 1, minutes: 1 }}
              minTime={formatDate(eventForm?.startDate) == formatDate(eventForm?.endDate) ? eventForm?.startTime : ''}
              sx={{ width: '100%' }}
              value={eventForm?.endTime}
              slotProps={{
                textField: {
                  helperText: timeErrorMessage,
                  error: Boolean(timeError)
                },
              }}
              defaultValue={dayjs()}
              label="End time" />
          </div>
        </LocalizationProvider>
      </div>
    </div>
  );
};

export default EventCreationForm;
